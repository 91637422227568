import { GET_ITEM_TO_DELETE } from '../aliases/getters.type'
import { SET_ITEM_TO_DELETE } from '../aliases/mutations.type'

const namespaced = true

export default {
    namespaced,
    state: {
        itemToDelete: {}
    },

    getters: {
        [GET_ITEM_TO_DELETE]: (state) => {
            return state.itemToDelete
        }
    },

    mutations: {
        [SET_ITEM_TO_DELETE](state, { itemToDelete }: { itemToDelete: any }) {
            state.itemToDelete = itemToDelete
        }
    }
}