// tslint:disable
/**
 * SkyT
 * SkyT contract
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: email@email.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiError } from '../model';
// @ts-ignore
import { AreaOfInterest } from '../model';
// @ts-ignore
import { CreateOrUpdateAreaOfInterest } from '../model';
// @ts-ignore
import { CreateOrUpdateMission } from '../model';
// @ts-ignore
import { Mission } from '../model';
// @ts-ignore
import { MissionDetails } from '../model';
/**
 * MissionApi - axios parameter creator
 * @export
 */
export const MissionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a new area of interest
         * @summary Delete a new area of interest
         * @param {number} projectId 
         * @param {number} missionId 
         * @param {number} areaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAreaOfInterestForMission: async (projectId: number, missionId: number, areaId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling deleteAreaOfInterestForMission.');
            }
            // verify required parameter 'missionId' is not null or undefined
            if (missionId === null || missionId === undefined) {
                throw new RequiredError('missionId','Required parameter missionId was null or undefined when calling deleteAreaOfInterestForMission.');
            }
            // verify required parameter 'areaId' is not null or undefined
            if (areaId === null || areaId === undefined) {
                throw new RequiredError('areaId','Required parameter areaId was null or undefined when calling deleteAreaOfInterestForMission.');
            }
            const localVarPath = `/api/v1/projects/{projectId}/missions/{missionId}/area-of-interests/{areaId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"missionId"}}`, encodeURIComponent(String(missionId)))
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a mission
         * @summary Delete a mission
         * @param {number} projectId 
         * @param {number} missionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMissionById: async (projectId: number, missionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling deleteMissionById.');
            }
            // verify required parameter 'missionId' is not null or undefined
            if (missionId === null || missionId === undefined) {
                throw new RequiredError('missionId','Required parameter missionId was null or undefined when calling deleteMissionById.');
            }
            const localVarPath = `/api/v1/projects/{projectId}/missions/{missionId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"missionId"}}`, encodeURIComponent(String(missionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get list of missions of a project
         * @summary Get list of missions of a project
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMissionsOfProject: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAllMissionsOfProject.');
            }
            const localVarPath = `/api/v1/projects/{id}/missions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get mission details by Id
         * @summary Get mission details by Id
         * @param {number} projectId 
         * @param {number} missionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDetails: async (projectId: number, missionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getMissionDetails.');
            }
            // verify required parameter 'missionId' is not null or undefined
            if (missionId === null || missionId === undefined) {
                throw new RequiredError('missionId','Required parameter missionId was null or undefined when calling getMissionDetails.');
            }
            const localVarPath = `/api/v1/projects/{projectId}/missions/{missionId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"missionId"}}`, encodeURIComponent(String(missionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new area of interest
         * @summary Create a new area of interest
         * @param {number} projectId 
         * @param {number} missionId 
         * @param {CreateOrUpdateAreaOfInterest} [createOrUpdateAreaOfInterest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newAreaOfInterestForMission: async (projectId: number, missionId: number, createOrUpdateAreaOfInterest?: CreateOrUpdateAreaOfInterest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling newAreaOfInterestForMission.');
            }
            // verify required parameter 'missionId' is not null or undefined
            if (missionId === null || missionId === undefined) {
                throw new RequiredError('missionId','Required parameter missionId was null or undefined when calling newAreaOfInterestForMission.');
            }
            const localVarPath = `/api/v1/projects/{projectId}/missions/{missionId}/area-of-interests`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"missionId"}}`, encodeURIComponent(String(missionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createOrUpdateAreaOfInterest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createOrUpdateAreaOfInterest !== undefined ? createOrUpdateAreaOfInterest : {}) : (createOrUpdateAreaOfInterest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new mission for a project
         * @summary Create a new mission
         * @param {number} id 
         * @param {CreateOrUpdateMission} [createOrUpdateMission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newMissionForProject: async (id: number, createOrUpdateMission?: CreateOrUpdateMission, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling newMissionForProject.');
            }
            const localVarPath = `/api/v1/projects/{id}/missions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createOrUpdateMission !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createOrUpdateMission !== undefined ? createOrUpdateMission : {}) : (createOrUpdateMission || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a new area of interest
         * @summary Update a new area of interest
         * @param {number} projectId 
         * @param {number} missionId 
         * @param {number} areaId 
         * @param {CreateOrUpdateAreaOfInterest} [createOrUpdateAreaOfInterest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAreaOfInterestForMission: async (projectId: number, missionId: number, areaId: number, createOrUpdateAreaOfInterest?: CreateOrUpdateAreaOfInterest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling updateAreaOfInterestForMission.');
            }
            // verify required parameter 'missionId' is not null or undefined
            if (missionId === null || missionId === undefined) {
                throw new RequiredError('missionId','Required parameter missionId was null or undefined when calling updateAreaOfInterestForMission.');
            }
            // verify required parameter 'areaId' is not null or undefined
            if (areaId === null || areaId === undefined) {
                throw new RequiredError('areaId','Required parameter areaId was null or undefined when calling updateAreaOfInterestForMission.');
            }
            const localVarPath = `/api/v1/projects/{projectId}/missions/{missionId}/area-of-interests/{areaId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"missionId"}}`, encodeURIComponent(String(missionId)))
                .replace(`{${"areaId"}}`, encodeURIComponent(String(areaId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createOrUpdateAreaOfInterest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createOrUpdateAreaOfInterest !== undefined ? createOrUpdateAreaOfInterest : {}) : (createOrUpdateAreaOfInterest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a mission
         * @summary Update a mission
         * @param {number} projectId 
         * @param {number} missionId 
         * @param {CreateOrUpdateMission} [createOrUpdateMission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMissionById: async (projectId: number, missionId: number, createOrUpdateMission?: CreateOrUpdateMission, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling updateMissionById.');
            }
            // verify required parameter 'missionId' is not null or undefined
            if (missionId === null || missionId === undefined) {
                throw new RequiredError('missionId','Required parameter missionId was null or undefined when calling updateMissionById.');
            }
            const localVarPath = `/api/v1/projects/{projectId}/missions/{missionId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"missionId"}}`, encodeURIComponent(String(missionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createOrUpdateMission !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createOrUpdateMission !== undefined ? createOrUpdateMission : {}) : (createOrUpdateMission || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MissionApi - functional programming interface
 * @export
 */
export const MissionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Delete a new area of interest
         * @summary Delete a new area of interest
         * @param {number} projectId 
         * @param {number} missionId 
         * @param {number} areaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAreaOfInterestForMission(projectId: number, missionId: number, areaId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MissionApiAxiosParamCreator(configuration).deleteAreaOfInterestForMission(projectId, missionId, areaId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete a mission
         * @summary Delete a mission
         * @param {number} projectId 
         * @param {number} missionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMissionById(projectId: number, missionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MissionApiAxiosParamCreator(configuration).deleteMissionById(projectId, missionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get list of missions of a project
         * @summary Get list of missions of a project
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMissionsOfProject(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mission>> {
            const localVarAxiosArgs = await MissionApiAxiosParamCreator(configuration).getAllMissionsOfProject(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get mission details by Id
         * @summary Get mission details by Id
         * @param {number} projectId 
         * @param {number} missionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMissionDetails(projectId: number, missionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MissionDetails>>> {
            const localVarAxiosArgs = await MissionApiAxiosParamCreator(configuration).getMissionDetails(projectId, missionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create a new area of interest
         * @summary Create a new area of interest
         * @param {number} projectId 
         * @param {number} missionId 
         * @param {CreateOrUpdateAreaOfInterest} [createOrUpdateAreaOfInterest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newAreaOfInterestForMission(projectId: number, missionId: number, createOrUpdateAreaOfInterest?: CreateOrUpdateAreaOfInterest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaOfInterest>> {
            const localVarAxiosArgs = await MissionApiAxiosParamCreator(configuration).newAreaOfInterestForMission(projectId, missionId, createOrUpdateAreaOfInterest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create a new mission for a project
         * @summary Create a new mission
         * @param {number} id 
         * @param {CreateOrUpdateMission} [createOrUpdateMission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newMissionForProject(id: number, createOrUpdateMission?: CreateOrUpdateMission, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mission>> {
            const localVarAxiosArgs = await MissionApiAxiosParamCreator(configuration).newMissionForProject(id, createOrUpdateMission, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update a new area of interest
         * @summary Update a new area of interest
         * @param {number} projectId 
         * @param {number} missionId 
         * @param {number} areaId 
         * @param {CreateOrUpdateAreaOfInterest} [createOrUpdateAreaOfInterest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAreaOfInterestForMission(projectId: number, missionId: number, areaId: number, createOrUpdateAreaOfInterest?: CreateOrUpdateAreaOfInterest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaOfInterest>> {
            const localVarAxiosArgs = await MissionApiAxiosParamCreator(configuration).updateAreaOfInterestForMission(projectId, missionId, areaId, createOrUpdateAreaOfInterest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update a mission
         * @summary Update a mission
         * @param {number} projectId 
         * @param {number} missionId 
         * @param {CreateOrUpdateMission} [createOrUpdateMission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMissionById(projectId: number, missionId: number, createOrUpdateMission?: CreateOrUpdateMission, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mission>> {
            const localVarAxiosArgs = await MissionApiAxiosParamCreator(configuration).updateMissionById(projectId, missionId, createOrUpdateMission, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MissionApi - factory interface
 * @export
 */
export const MissionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Delete a new area of interest
         * @summary Delete a new area of interest
         * @param {number} projectId 
         * @param {number} missionId 
         * @param {number} areaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAreaOfInterestForMission(projectId: number, missionId: number, areaId: number, options?: any): AxiosPromise<void> {
            return MissionApiFp(configuration).deleteAreaOfInterestForMission(projectId, missionId, areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a mission
         * @summary Delete a mission
         * @param {number} projectId 
         * @param {number} missionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMissionById(projectId: number, missionId: number, options?: any): AxiosPromise<void> {
            return MissionApiFp(configuration).deleteMissionById(projectId, missionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get list of missions of a project
         * @summary Get list of missions of a project
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMissionsOfProject(id: number, options?: any): AxiosPromise<Mission> {
            return MissionApiFp(configuration).getAllMissionsOfProject(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get mission details by Id
         * @summary Get mission details by Id
         * @param {number} projectId 
         * @param {number} missionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDetails(projectId: number, missionId: number, options?: any): AxiosPromise<Array<MissionDetails>> {
            return MissionApiFp(configuration).getMissionDetails(projectId, missionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new area of interest
         * @summary Create a new area of interest
         * @param {number} projectId 
         * @param {number} missionId 
         * @param {CreateOrUpdateAreaOfInterest} [createOrUpdateAreaOfInterest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newAreaOfInterestForMission(projectId: number, missionId: number, createOrUpdateAreaOfInterest?: CreateOrUpdateAreaOfInterest, options?: any): AxiosPromise<AreaOfInterest> {
            return MissionApiFp(configuration).newAreaOfInterestForMission(projectId, missionId, createOrUpdateAreaOfInterest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new mission for a project
         * @summary Create a new mission
         * @param {number} id 
         * @param {CreateOrUpdateMission} [createOrUpdateMission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newMissionForProject(id: number, createOrUpdateMission?: CreateOrUpdateMission, options?: any): AxiosPromise<Mission> {
            return MissionApiFp(configuration).newMissionForProject(id, createOrUpdateMission, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a new area of interest
         * @summary Update a new area of interest
         * @param {number} projectId 
         * @param {number} missionId 
         * @param {number} areaId 
         * @param {CreateOrUpdateAreaOfInterest} [createOrUpdateAreaOfInterest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAreaOfInterestForMission(projectId: number, missionId: number, areaId: number, createOrUpdateAreaOfInterest?: CreateOrUpdateAreaOfInterest, options?: any): AxiosPromise<AreaOfInterest> {
            return MissionApiFp(configuration).updateAreaOfInterestForMission(projectId, missionId, areaId, createOrUpdateAreaOfInterest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a mission
         * @summary Update a mission
         * @param {number} projectId 
         * @param {number} missionId 
         * @param {CreateOrUpdateMission} [createOrUpdateMission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMissionById(projectId: number, missionId: number, createOrUpdateMission?: CreateOrUpdateMission, options?: any): AxiosPromise<Mission> {
            return MissionApiFp(configuration).updateMissionById(projectId, missionId, createOrUpdateMission, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MissionApi - object-oriented interface
 * @export
 * @class MissionApi
 * @extends {BaseAPI}
 */
export class MissionApi extends BaseAPI {
    /**
     * Delete a new area of interest
     * @summary Delete a new area of interest
     * @param {number} projectId 
     * @param {number} missionId 
     * @param {number} areaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public deleteAreaOfInterestForMission(projectId: number, missionId: number, areaId: number, options?: any) {
        return MissionApiFp(this.configuration).deleteAreaOfInterestForMission(projectId, missionId, areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a mission
     * @summary Delete a mission
     * @param {number} projectId 
     * @param {number} missionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public deleteMissionById(projectId: number, missionId: number, options?: any) {
        return MissionApiFp(this.configuration).deleteMissionById(projectId, missionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get list of missions of a project
     * @summary Get list of missions of a project
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public getAllMissionsOfProject(id: number, options?: any) {
        return MissionApiFp(this.configuration).getAllMissionsOfProject(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get mission details by Id
     * @summary Get mission details by Id
     * @param {number} projectId 
     * @param {number} missionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public getMissionDetails(projectId: number, missionId: number, options?: any) {
        return MissionApiFp(this.configuration).getMissionDetails(projectId, missionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new area of interest
     * @summary Create a new area of interest
     * @param {number} projectId 
     * @param {number} missionId 
     * @param {CreateOrUpdateAreaOfInterest} [createOrUpdateAreaOfInterest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public newAreaOfInterestForMission(projectId: number, missionId: number, createOrUpdateAreaOfInterest?: CreateOrUpdateAreaOfInterest, options?: any) {
        return MissionApiFp(this.configuration).newAreaOfInterestForMission(projectId, missionId, createOrUpdateAreaOfInterest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new mission for a project
     * @summary Create a new mission
     * @param {number} id 
     * @param {CreateOrUpdateMission} [createOrUpdateMission] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public newMissionForProject(id: number, createOrUpdateMission?: CreateOrUpdateMission, options?: any) {
        return MissionApiFp(this.configuration).newMissionForProject(id, createOrUpdateMission, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a new area of interest
     * @summary Update a new area of interest
     * @param {number} projectId 
     * @param {number} missionId 
     * @param {number} areaId 
     * @param {CreateOrUpdateAreaOfInterest} [createOrUpdateAreaOfInterest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public updateAreaOfInterestForMission(projectId: number, missionId: number, areaId: number, createOrUpdateAreaOfInterest?: CreateOrUpdateAreaOfInterest, options?: any) {
        return MissionApiFp(this.configuration).updateAreaOfInterestForMission(projectId, missionId, areaId, createOrUpdateAreaOfInterest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a mission
     * @summary Update a mission
     * @param {number} projectId 
     * @param {number} missionId 
     * @param {CreateOrUpdateMission} [createOrUpdateMission] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public updateMissionById(projectId: number, missionId: number, createOrUpdateMission?: CreateOrUpdateMission, options?: any) {
        return MissionApiFp(this.configuration).updateMissionById(projectId, missionId, createOrUpdateMission, options).then((request) => request(this.axios, this.basePath));
    }

}
