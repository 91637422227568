import Vue from "vue";

import DeleteModal from '@/components/DeleteModal.vue'
import SelectableLine from '@/components/SelectableLine.vue'
import { displayMessage, ERROR_TYPES } from '@/core/middlewares/error';
import {required} from 'vuelidate/lib/validators'

import { comparator } from '@/utils/comparator';

import {
  mapGetters,
  mapActions
} from 'vuex'

import {
  GET_CUSTOMERS, GET_PROJECTS
} from '../../store/aliases/getters.type'

import {
  CUSTOMERS_FETCH, CUSTOMER_UPLOAD_LOGO, PROJECTS_FETCH, PROJECT_DELETE
} from '../../store/aliases/actions.type'

import {
  CUSTOMER_TYPE, PROJECT_TYPE
} from '@/store/types'

export default Vue.extend({

  components: {
    DeleteModal,
    SelectableLine
  },
  props: {
    selectedCustomerIdProp: Number,
    customerLogoUrl: String
  },
  validations: {
    customerLogo: {
      required
    }
  },
  data() {
    return {
      selectedCustomerId: this.selectedCustomerIdProp,
      uploadResponseType: null,
      customerLogo: null,
      uploadFieldText: null,
    }
  },
  mounted() {
    this.fetchCustomers()
    if (this.selectedCustomerId) {
      this.fetchProjects({ customerId: this.selectedCustomerId })
    }
  },
  computed: {
    ...mapGetters({
      getCustomers: `${CUSTOMER_TYPE}/${GET_CUSTOMERS}`,
      getProjects: `${PROJECT_TYPE}/${GET_PROJECTS}`,
    }),
    sortedCustomers() {
      return this.getCustomers.slice().sort((a, b) => comparator.compareObjectsOnStrings(a, b, 'name'))
    },
    sortedProjects() {
      return this.getProjects.slice().sort((a, b) => comparator.compareObjectsOnStrings(a, b, 'name'))
    },
  },
  methods: {
    ...mapActions({
      uploadLogoForCustomer: `${CUSTOMER_TYPE}/${CUSTOMER_UPLOAD_LOGO}`,
      fetchCustomers: `${CUSTOMER_TYPE}/${CUSTOMERS_FETCH}`,
      fetchProjects: `${PROJECT_TYPE}/${PROJECTS_FETCH}`,
      deleteProject: `${PROJECT_TYPE}/${PROJECT_DELETE}`
    }),
    selectCustomer(customer) {
      this.resetLogoUploader()
      this.selectedCustomerId = customer.id
      if (customer.iconUrl == undefined) {
        this.uploadFieldText = "Choose file..."
      } else {
        this.uploadFieldText = "Logo existing"
      }
      this.customerLogo = customer.iconUrl
      this.fetchProjects({ 
        customerId: customer.id,
      })
    },
    handleFile(e) {
      let file = e.target.files[0]

      if (file) {
        this.$v.customerLogo.$touch()

        this.customerLogo = file
        this.uploadFieldText = file.name
      }
    },
    async uploadLogo() {
      this.$v.$touch()
      if (isNoLogoUploaded(this.customerLogo.name)) {
          displayMessage({ message: 'Please select at least one file.', type: ERROR_TYPES.INFO })
          return
      } else {
        let response = await this.uploadLogoForCustomer({ customerId: this.selectedCustomerId, customerLogo: this.customerLogo })
        this.uploadResponseType = handleUploadResponse(response)
      }
    },
    selectProject(project) {
      if (this.isForbidden(project)) {
        displayMessage({ message: 'This project is confidential. Request access to it if you want to see its details.', type: ERROR_TYPES.ERROR })
        return
      }
      this.$router.push({ path: `/project/${project.id}` })
    },
    resetLogoUploader() {
      if (this.$refs.logoUploaderForm) {
        this.$refs.logoUploaderForm.reset()
      }
    },
    isForbidden(project): boolean {
      return project.rights === 'FORBIDDEN'
    },
    isWriteAccess(project): boolean {
      return project.rights === 'WRITE'
    },
    deleteComponent(project) {
      this.deleteProject({ projectId: project.id })
    }
  }
})

function isNoLogoUploaded(logoUploadInput): boolean {
    let noFileUploaded = true
    if (logoUploadInput != undefined) {
        noFileUploaded = false
    }
    return noFileUploaded
}

function handleUploadResponse(response) : string {
  if (Array.isArray(response)) {
      return 'SUCCESS'
  }
  else if (response.code === 'API-INTERNAL-SERVER-ERROR') {
      return "ERROR_CSV"
  }
  else if (response.code === 'API-BAD-PARAMETER-NUMBER') {
      return "ERROR_FORMAT"
  }
}