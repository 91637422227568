import {
    GET_OFFERS,
    GET_OFFER
} from "../aliases/getters.type"

import {
    OFFERS_FETCH,
    OFFER_FETCH,
    OFFER_CREATE,
    OFFER_DELETE,
    OFFER_UPDATE
} from "../aliases/actions.type"

import {
    SET_OFFERS,
    SET_OFFER,
    ADD_OFFER,
    EDIT_OFFER,
    REMOVE_OFFER, SET_GATEWAYS
} from "../aliases/mutations.type"

import {
    CatalogGatewayApi,
    OfferApi
} from '@/core'

import { 
    OfferExtended, 
    Offer 
} from '@/core/models'

import {
    getConfiguration
} from '@/core/utils/config.util'

import {
    log,
    LOG_TYPES
} from '@/core/middlewares/Logger'

import {
    displayMessage,
    ERROR_TYPES
} from '@/core/middlewares/error'

import _ from 'lodash'

import Vue from 'vue'

const namespaced = true

const replaceOffer = ({ state, offer }) => {
    const index = _.findIndex(state.offers, offer => offer.id === state.offer.id)
    if (index >= 0) {
        state.offers[index] = offer || state.offer
    }
}

export default {
    namespaced,
    state: {
        offers: [],
        offer: {}
    },

    getters: {
        [GET_OFFERS]: (state) => {
            return state.offers
        },
        [GET_OFFER]: (state) => {
            return state.offer
        }
    },

    actions: {
        [OFFERS_FETCH]({ commit }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Fetching available offers.` })
            return getConfiguration().then(config => {
                return new OfferApi(config).fetchAllOffers()
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Offers: ${data.length} returned.`
                })
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Offers: ${JSON.stringify(data)} returned successfully.`
                })
                commit(SET_OFFERS, { offers: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while fetching offers.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
        [OFFER_FETCH]({ commit }, { id }: { id: number }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Fetching offer by id: ${id}.` })
            return getConfiguration().then(config => {
                return new OfferApi(config).fetchOffer(id)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `OfferFetch: ${JSON.stringify(data)} returned successfully.`
                })
                commit(SET_OFFER, { offer: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Cannot fetch offer.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
        async [OFFER_CREATE]({ commit }, { offer }: { offer: OfferExtended }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Adding next offer:  ${JSON.stringify(offer)}.` })
            return getConfiguration().then(config => {
                return new OfferApi(config).createOffer(offer)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `OfferAdd: ${JSON.stringify(offer)} created successfully.`
                })
                displayMessage({ message: 'Offer added successfully.', type: ERROR_TYPES.SUCCESS })
                commit(ADD_OFFER, { offer })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while adding offer.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
        async [OFFER_UPDATE]({ commit }, { offer }: { offer: OfferExtended }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Adding next offer:  ${JSON.stringify(offer)}.` })
            return getConfiguration().then(config => {
                return new OfferApi(config).updateOffer(offer.id, offer)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `OfferUpdate: ${JSON.stringify(offer)} updated successfully.`
                })
                displayMessage({ message: 'Offer updated successfully.', type: ERROR_TYPES.SUCCESS })
                commit(EDIT_OFFER, { offer })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while updating offer.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
        [OFFER_DELETE]({ commit }, { id }: { id: number }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Deleting offer by id: ${id}.` })
            return getConfiguration().then(config => {
                return new OfferApi(config).deleteOffer(id)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `OfferDelete: ${id} deleted successfully.`
                })
                displayMessage({ message: 'Offer deleted successfully.', type: ERROR_TYPES.SUCCESS })
                commit(REMOVE_OFFER, { id })
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while deleting offer.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
    },
    mutations: {
        [SET_OFFER](state, { offer }: { offer: OfferExtended }) {
            state.offer = offer
        },
        [SET_OFFERS](state, { offers }: { offers: Array<Offer> }) {
            state.offers = offers
        },
        [ADD_OFFER](state, { offer }: { offer: OfferExtended }) {
            state.offers.push(offer)
            state.offer = offer
        },
        [EDIT_OFFER](state, { offer }: { offer: OfferExtended }) {
            replaceOffer({ state, offer })
            state.offer = offer
        },
        [REMOVE_OFFER](state, { id }: { id: number }) {
            _.remove(state.offers, offer => offer.id == id)
            replaceOffer({ state, offer: null })
        }
    }
}
