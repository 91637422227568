import Vue from "vue";
import DeleteModal from '@/components/DeleteModal.vue'
import SelectableLine from '@/components/SelectableLine.vue'
import draggable from 'vuedraggable'
import { GET_GATEWAYS } from '@/store/aliases/getters.type';
import { CATALOG_TYPE } from '@/store/types';
import { GATEWAYS_FETCH, GATEWAY_DELETE, GATEWAY_UPDATE_INDEX } from '@/store/aliases/actions.type';
import { mapGetters, mapActions } from 'vuex';
import { comparator } from '@/utils/comparator';

export default Vue.extend({
    components: {
        DeleteModal,
        SelectableLine,
        draggable
    },
    mounted() {
        this.fetchGateways()
    },
    computed: {
        ...mapGetters({
            getGateways: `${CATALOG_TYPE}/${GET_GATEWAYS}`
        }),
        activeGateways() {
            return this.getGateways.filter(gateway => gateway.isActive)
        },
        inactiveGateways() {
            return this.getGateways.filter(gateway => !gateway.isActive)
                                .slice().sort((a, b) => comparator.compareObjectsOnStrings(a, b, 'name'))
        }
    },
    methods: {
        ...mapActions({
            fetchGateways: `${CATALOG_TYPE}/${GATEWAYS_FETCH}`,
            deleteGateway: `${CATALOG_TYPE}/${GATEWAY_DELETE}`,
            updateGatewayIndex: `${CATALOG_TYPE}/${GATEWAY_UPDATE_INDEX}`
        }),
        selectGateway(gateway) {
            this.$router.push({ path: `/gateway/${gateway.id}` })
        },
        addGateway() {
            this.$router.push({ path: '/gateway' })
        },
        deleteComponent(gateway) {
            this.deleteGateway({ gatewayId: gateway.id, isActive: gateway.isActive })
        },
        updateIndex(event) {
            this.updateGatewayIndex({ gatewayId: event.moved.element.id, index: event.moved.newIndex})
        }
    }
})
