// tslint:disable
/**
 * SkyT
 * SkyT contract
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: email@email.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiError } from '../model';
// @ts-ignore
import { CoverageType } from '../model';
// @ts-ignore
import { SatelliteModel } from '../model';
/**
 * CatalogSatelliteApi - axios parameter creator
 * @export
 */
export const CatalogSatelliteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new satelliteModel item
         * @summary Create a new satelliteModel item
         * @param {string} name 
         * @param {string} details 
         * @param {number} power 
         * @param {Array<CoverageType>} supportedCoverage 
         * @param {any} [picture] 
         * @param {any} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSatelliteModel: async (name: string, details: string, power: number, supportedCoverage: Array<CoverageType>, isActive: boolean, isSpaceInspire: boolean, configurationType: number, maxGatewayCount: number, useReflector: boolean,  picture?: any, logo?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling createSatelliteModel.');
            }
            // verify required parameter 'details' is not null or undefined
            if (details === null || details === undefined) {
                throw new RequiredError('details','Required parameter details was null or undefined when calling createSatelliteModel.');
            }
            // verify required parameter 'power' is not null or undefined
            if (power === null || power === undefined) {
                throw new RequiredError('power','Required parameter power was null or undefined when calling createSatelliteModel.');
            }
            // verify required parameter 'supportedCoverage' is not null or undefined
            if (supportedCoverage === null || supportedCoverage === undefined) {
                throw new RequiredError('supportedCoverage','Required parameter supportedCoverage was null or undefined when calling createSatelliteModel.');
            }
            const localVarPath = `/api/v1/catalogs/satellite-models`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (details !== undefined) { 
                localVarFormParams.append('details', details as any);
            }
    
            if (picture !== undefined) { 
                localVarFormParams.append('picture', picture as any);
            }
    
            if (logo !== undefined) { 
                localVarFormParams.append('logo', logo as any);
            }
    
            if (power !== undefined) { 
                localVarFormParams.append('power', power as any);
            }
            
            if (supportedCoverage) {
            
                localVarFormParams.append('supportedCoverage', JSON.stringify(supportedCoverage));
            }
    
            if (isActive !== undefined) { 
                localVarFormParams.append('isActive', isActive as any);
            }
    
            if (isSpaceInspire !== undefined) { 
                localVarFormParams.append('isSpaceInspire', isSpaceInspire as any);
            }
    
            if (configurationType !== undefined) { 
                localVarFormParams.append('configurationType', configurationType as any);
            }
    
            if (maxGatewayCount !== undefined) { 
                localVarFormParams.append('maxGatewayCount', maxGatewayCount as any);
            }
    
            if (useReflector !== undefined) { 
                localVarFormParams.append('useReflector', useReflector as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a new satelliteModel item
         * @summary Delete a new satelliteModel item
         * @param {number} id 
         * @param {boolean} [isLogical] default value is true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSatelliteModel: async (id: number, isLogical?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteSatelliteModel.');
            }
            const localVarPath = `/api/v1/catalogs/satellite-models/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isLogical !== undefined) {
                localVarQueryParameter['isLogical'] = isLogical;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all satelliteModels organized by index
         * @summary Get all satelliteModels organized by index
         * @param {boolean} [onlyActive] The default value is false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSatelliteModels: async (onlyActive?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/catalogs/satellite-models`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (onlyActive !== undefined) {
                localVarQueryParameter['onlyActive'] = onlyActive;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get SatelliteModel by ID
         * @summary Get SatelliteModel by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSatelliteModelById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getSatelliteModelById.');
            }
            const localVarPath = `/api/v1/catalogs/satellite-models/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a new satelliteModel item
         * @summary Update a new satelliteModel item
         * @param {number} id 
         * @param {string} name 
         * @param {string} details 
         * @param {number} power 
         * @param {Array<CoverageType>} supportedCoverage 
         * @param {any} [picture] 
         * @param {any} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
         updateSatelliteModel: async (id: number, name: string, details: string, power: number, supportedCoverage: Array<CoverageType>, isActive: boolean, isSpaceInspire: boolean, configurationType: number, maxGatewayCount: number, useReflector: boolean, picture?: any, logo?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateSatelliteModel.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling updateSatelliteModel.');
            }
            // verify required parameter 'details' is not null or undefined
            if (details === null || details === undefined) {
                throw new RequiredError('details','Required parameter details was null or undefined when calling updateSatelliteModel.');
            }
            // verify required parameter 'power' is not null or undefined
            if (power === null || power === undefined) {
                throw new RequiredError('power','Required parameter power was null or undefined when calling updateSatelliteModel.');
            }
            // verify required parameter 'supportedCoverage' is not null or undefined
            if (supportedCoverage === null || supportedCoverage === undefined) {
                throw new RequiredError('supportedCoverage','Required parameter supportedCoverage was null or undefined when calling updateSatelliteModel.');
            }
            const localVarPath = `/api/v1/catalogs/satellite-models/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (details !== undefined) { 
                localVarFormParams.append('details', details as any);
            }
    
            if (picture !== undefined) { 
                localVarFormParams.append('picture', picture as any);
            }
    
            if (logo !== undefined) { 
                localVarFormParams.append('logo', logo as any);
            }
    
            if (power !== undefined) { 
                localVarFormParams.append('power', power as any);
            }
            
            if (supportedCoverage) {
            
                localVarFormParams.append('supportedCoverage', JSON.stringify(supportedCoverage));
            }
    
            if (isActive !== undefined) {
                localVarFormParams.append('isActive', isActive as any);
            }
    
            if (isSpaceInspire !== undefined) { 
                localVarFormParams.append('isSpaceInspire', isSpaceInspire as any);
            }
    
            if (configurationType !== undefined) { 
                localVarFormParams.append('configurationType', (configurationType ? 1 : 0) as any);
            }
    
            if (maxGatewayCount !== undefined) { 
                localVarFormParams.append('maxGatewayCount', maxGatewayCount as any);
            }
    
            if (useReflector !== undefined) { 
                localVarFormParams.append('useReflector', useReflector as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update index of a satelliteItem
         * @summary Update index of a satelliteItem
         * @param {number} id 
         * @param {number} index 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSatelliteModelIndex: async (id: number, index: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateSatelliteModelIndex.');
            }
            // verify required parameter 'index' is not null or undefined
            if (index === null || index === undefined) {
                throw new RequiredError('index','Required parameter index was null or undefined when calling updateSatelliteModelIndex.');
            }
            const localVarPath = `/api/v1/catalogs/satellite-models/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (index !== undefined) {
                localVarQueryParameter['index'] = index;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CatalogSatelliteApi - functional programming interface
 * @export
 */
export const CatalogSatelliteApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Create a new satelliteModel item
         * @summary Create a new satelliteModel item
         * @param {string} name 
         * @param {string} details 
         * @param {number} power 
         * @param {Array<CoverageType>} supportedCoverage 
         * @param {any} [picture] 
         * @param {any} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSatelliteModel(name: string, details: string, power: number, supportedCoverage: Array<CoverageType>, isActive: boolean, isSpaceInspire: boolean, configurationType: number, maxGatewayCount: number, useReflector: boolean, picture?: any, logo?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SatelliteModel>> {
            const localVarAxiosArgs = await CatalogSatelliteApiAxiosParamCreator(configuration).createSatelliteModel(name, details, power, supportedCoverage, isActive, isSpaceInspire, configurationType, maxGatewayCount, useReflector, picture, logo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete a new satelliteModel item
         * @summary Delete a new satelliteModel item
         * @param {number} id 
         * @param {boolean} [isLogical] default value is true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSatelliteModel(id: number, isLogical?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SatelliteModel>>> {
            const localVarAxiosArgs = await CatalogSatelliteApiAxiosParamCreator(configuration).deleteSatelliteModel(id, isLogical, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get all satelliteModels organized by index
         * @summary Get all satelliteModels organized by index
         * @param {boolean} [onlyActive] The default value is false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSatelliteModels(onlyActive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SatelliteModel>>> {
            const localVarAxiosArgs = await CatalogSatelliteApiAxiosParamCreator(configuration).getAllSatelliteModels(onlyActive, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get SatelliteModel by ID
         * @summary Get SatelliteModel by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSatelliteModelById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SatelliteModel>> {
            const localVarAxiosArgs = await CatalogSatelliteApiAxiosParamCreator(configuration).getSatelliteModelById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update a new satelliteModel item
         * @summary Update a new satelliteModel item
         * @param {number} id 
         * @param {string} name 
         * @param {string} details 
         * @param {number} power 
         * @param {Array<CoverageType>} supportedCoverage 
         * @param {any} [picture] 
         * @param {any} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSatelliteModel(id: number, name: string, details: string, power: number, supportedCoverage: Array<CoverageType>, isActive: boolean, isSpaceInspire: boolean, configurationType: number, maxGatewayCount: number, useReflector: boolean, picture?: any, logo?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SatelliteModel>> {
            const localVarAxiosArgs = await CatalogSatelliteApiAxiosParamCreator(configuration).updateSatelliteModel(id, name, details, power, supportedCoverage, isActive, isSpaceInspire, configurationType, maxGatewayCount, useReflector, picture, logo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update index of a satelliteItem
         * @summary Update index of a satelliteItem
         * @param {number} id 
         * @param {number} index 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSatelliteModelIndex(id: number, index: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SatelliteModel>>> {
            const localVarAxiosArgs = await CatalogSatelliteApiAxiosParamCreator(configuration).updateSatelliteModelIndex(id, index, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CatalogSatelliteApi - factory interface
 * @export
 */
export const CatalogSatelliteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Create a new satelliteModel item
         * @summary Create a new satelliteModel item
         * @param {string} name 
         * @param {string} details 
         * @param {number} power 
         * @param {Array<CoverageType>} supportedCoverage 
         * @param {any} [picture] 
         * @param {any} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSatelliteModel(name: string, details: string, power: number, supportedCoverage: Array<CoverageType>, isActive: boolean, isSpaceInspire: boolean, configurationType: number, maxGatewayCount: number, useReflector: boolean, picture?: any, logo?: any, options?: any): AxiosPromise<SatelliteModel> {
            return CatalogSatelliteApiFp(configuration).createSatelliteModel(name, details, power, supportedCoverage, isActive, isSpaceInspire, configurationType, maxGatewayCount, useReflector, picture, logo, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a new satelliteModel item
         * @summary Delete a new satelliteModel item
         * @param {number} id 
         * @param {boolean} [isLogical] default value is true
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSatelliteModel(id: number, isLogical?: boolean, options?: any): AxiosPromise<Array<SatelliteModel>> {
            return CatalogSatelliteApiFp(configuration).deleteSatelliteModel(id, isLogical, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all satelliteModels organized by index
         * @summary Get all satelliteModels organized by index
         * @param {boolean} [onlyActive] The default value is false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSatelliteModels(onlyActive?: boolean, options?: any): AxiosPromise<Array<SatelliteModel>> {
            return CatalogSatelliteApiFp(configuration).getAllSatelliteModels(onlyActive, options).then((request) => request(axios, basePath));
        },
        /**
         * Get SatelliteModel by ID
         * @summary Get SatelliteModel by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSatelliteModelById(id: number, options?: any): AxiosPromise<SatelliteModel> {
            return CatalogSatelliteApiFp(configuration).getSatelliteModelById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a new satelliteModel item
         * @summary Update a new satelliteModel item
         * @param {number} id 
         * @param {string} name 
         * @param {string} details 
         * @param {number} power 
         * @param {Array<CoverageType>} supportedCoverage 
         * @param {any} [picture] 
         * @param {any} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSatelliteModel(id: number, name: string, details: string, power: number, supportedCoverage: Array<CoverageType>, isActive: boolean, isSpaceInspire: boolean, configurationType: number, maxGatewayCount: number, useReflector: boolean, picture?: any, logo?: any, options?: any): AxiosPromise<SatelliteModel> {
            return CatalogSatelliteApiFp(configuration).updateSatelliteModel(id, name, details, power, supportedCoverage, isActive, isSpaceInspire, configurationType, maxGatewayCount, useReflector, picture, logo, options).then((request) => request(axios, basePath));
        },
        /**
         * Update index of a satelliteItem
         * @summary Update index of a satelliteItem
         * @param {number} id 
         * @param {number} index 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSatelliteModelIndex(id: number, index: number, options?: any): AxiosPromise<Array<SatelliteModel>> {
            return CatalogSatelliteApiFp(configuration).updateSatelliteModelIndex(id, index, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CatalogSatelliteApi - object-oriented interface
 * @export
 * @class CatalogSatelliteApi
 * @extends {BaseAPI}
 */
export class CatalogSatelliteApi extends BaseAPI {
    /**
     * Create a new satelliteModel item
     * @summary Create a new satelliteModel item
     * @param {string} name 
     * @param {string} details 
     * @param {number} power 
     * @param {Array<CoverageType>} supportedCoverage 
     * @param {any} [picture] 
     * @param {any} [logo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogSatelliteApi
     */
    public createSatelliteModel(name: string, details: string, power: number, supportedCoverage: Array<CoverageType>, isActive: boolean, isSpaceInspire: boolean, configurationType: number, maxGatewayCount: number, useReflector: boolean, picture?: any, logo?: any, options?: any) {
        return CatalogSatelliteApiFp(this.configuration).createSatelliteModel(name, details, power, supportedCoverage, isActive, isSpaceInspire, configurationType, maxGatewayCount, useReflector, picture, logo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a new satelliteModel item
     * @summary Delete a new satelliteModel item
     * @param {number} id 
     * @param {boolean} [isLogical] default value is true
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogSatelliteApi
     */
    public deleteSatelliteModel(id: number, isLogical?: boolean, options?: any) {
        return CatalogSatelliteApiFp(this.configuration).deleteSatelliteModel(id, isLogical, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all satelliteModels organized by index
     * @summary Get all satelliteModels organized by index
     * @param {boolean} [onlyActive] The default value is false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogSatelliteApi
     */
    public getAllSatelliteModels(onlyActive?: boolean, options?: any) {
        return CatalogSatelliteApiFp(this.configuration).getAllSatelliteModels(onlyActive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get SatelliteModel by ID
     * @summary Get SatelliteModel by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogSatelliteApi
     */
    public getSatelliteModelById(id: number, options?: any) {
        return CatalogSatelliteApiFp(this.configuration).getSatelliteModelById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a new satelliteModel item
     * @summary Update a new satelliteModel item
     * @param {number} id 
     * @param {string} name 
     * @param {string} details 
     * @param {number} power 
     * @param {Array<CoverageType>} supportedCoverage 
     * @param {any} [picture] 
     * @param {any} [logo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogSatelliteApi
     */
    public updateSatelliteModel(id: number, name: string, details: string, power: number, supportedCoverage: Array<CoverageType>, isActive: boolean, isSpaceInspire: boolean, configurationType: number, maxGatewayCount: number, useReflector: boolean, picture?: any, logo?: any, options?: any) {
        return CatalogSatelliteApiFp(this.configuration).updateSatelliteModel(id, name, details, power, supportedCoverage, isActive, isSpaceInspire, configurationType, maxGatewayCount, useReflector, picture, logo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update index of a satelliteItem
     * @summary Update index of a satelliteItem
     * @param {number} id 
     * @param {number} index 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogSatelliteApi
     */
    public updateSatelliteModelIndex(id: number, index: number, options?: any) {
        return CatalogSatelliteApiFp(this.configuration).updateSatelliteModelIndex(id, index, options).then((request) => request(this.axios, this.basePath));
    }

}
