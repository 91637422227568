// tslint:disable
/**
 * SkyT
 * SkyT contract
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: email@email.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiError } from '../model';
// @ts-ignore
import { Notification } from '../model';
/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all my notifications
         * @summary Get all my notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserNotifications: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/notifications`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The notification has been read
         * @summary read a notification
         * @param {number} timestamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserNotification: async (timestamp: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'timestamp' is not null or undefined
            if (timestamp === null || timestamp === undefined) {
                throw new RequiredError('timestamp','Required parameter timestamp was null or undefined when calling readUserNotification.');
            }
            const localVarPath = `/api/v1/users/notifications/read`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get all my notifications
         * @summary Get all my notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUserNotifications(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Notification>>> {
            const localVarAxiosArgs = await NotificationApiAxiosParamCreator(configuration).getAllUserNotifications(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * The notification has been read
         * @summary read a notification
         * @param {number} timestamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUserNotification(timestamp: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await NotificationApiAxiosParamCreator(configuration).readUserNotification(timestamp, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get all my notifications
         * @summary Get all my notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUserNotifications(options?: any): AxiosPromise<Array<Notification>> {
            return NotificationApiFp(configuration).getAllUserNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * The notification has been read
         * @summary read a notification
         * @param {number} timestamp 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserNotification(timestamp: number, options?: any): AxiosPromise<void> {
            return NotificationApiFp(configuration).readUserNotification(timestamp, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * Get all my notifications
     * @summary Get all my notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getAllUserNotifications(options?: any) {
        return NotificationApiFp(this.configuration).getAllUserNotifications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The notification has been read
     * @summary read a notification
     * @param {number} timestamp 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public readUserNotification(timestamp: number, options?: any) {
        return NotificationApiFp(this.configuration).readUserNotification(timestamp, options).then((request) => request(this.axios, this.basePath));
    }

}
