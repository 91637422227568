import VueLogger from 'vuejs-logger'

export enum LOG_TYPES { 
    d = 'debug', 
    i = 'info', 
    w = 'warn', 
    e = 'error', 
    f = 'fatal'
}

export function logger({ VueInstance }) {
    const isProduction = process.env.NODE_ENV === 'production'

    const options = {
        isEnabled: true,
        logLevel : isProduction ? 'error' : 'info',
        stringifyArguments : false,
        showLogLevel : true,
        showMethodName : true,
        separator: '|',
        showConsoleColors: true
    }

    VueInstance.use(VueLogger, options)
}

export function log({ VueInstance, type = 'info', response = null, message = null }) {
    if (response) {
        VueInstance.prototype.$log[type](`CODE: ${response.data.code}, MESSAGE: ${response.data.message}, DETAILS: ${response.data.details}`)
    }
    if (message) {
        VueInstance.prototype.$log[type](message)
    }
}
