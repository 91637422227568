import Vue from "vue";
import { media } from '@/utils/media';

export default Vue.extend({
    props: ["projectExport"],
    methods: {
        downloadProjectExport() {
            const fileName = this.projectExport.exportedDataUrl.substring(this.projectExport.exportedDataUrl.lastIndexOf('/') + 1)
            media.downloadFile(this.projectExport.exportedDataUrl, fileName)
        },
        handleXmlUpload(e) {
            alert("Not implemented yet")
        },
        handleLkbUpload(e) {
            alert("Not implemented yet")
        },
        handleConsoUpload(e) {
            alert("Not implemented yet")
        }
    }
})