import * as msal from "@azure/msal-browser"
import JWT from "jsonwebtoken"
import {PromisePublisher} from "@/core/plugins/PromisePublisher"
import {EnvConfiguration} from "@/config/config"

export class AmarisVueMsalPlugin {

    private static instance: AmarisVueMsalPlugin
    private request: any = {scopes: ["user.read"]}

    static getInstance(config: msal.Configuration): AmarisVueMsalPlugin {
        if (!AmarisVueMsalPlugin.instance) {
            AmarisVueMsalPlugin.instance = new AmarisVueMsalPlugin(config)
        }
        return AmarisVueMsalPlugin.instance
    }

    private constructor(config: msal.Configuration) {
        this._msalInstance = new msal.PublicClientApplication(config)
        this._msalInstance.handleRedirectPromise().then((resp: msal.AuthenticationResult | null) => {
            this.handleRedirectPromise(resp)
        }).catch(console.error)
    }

    async getUserInfo(): Promise<null | { [key: string]: any } | string> {
        // Get the user idToken
        const accessToken = await this.getAccessToken()
        if(accessToken != null) {
            return JWT.decode(accessToken)
        } else {
            return null
        }
    }

    async hasRole(skytRole: String): Promise<boolean> {
        const token = await this.getUserInfo()
        return token ? token["roles"].some(role => role === skytRole) : false
    }

    async hasAtLeastOneSkytRole(): Promise<boolean> {
        let userRoles = (await this.getUserInfo())["roles"]
        let confRoles = EnvConfiguration.ROLES
        return userRoles ? userRoles.some(role => confRoles.includes(role)) : false
    }


    async getRoles(): Promise<string[]> {
        const token = await this.getUserInfo()
        return token !== null && token["roles"] ? token["roles"] : [];
    }

    logout() {
        const logoutRequest = {
            account: this._msalInstance.getAccountByHomeId(this.getAccount().homeAccountId)
        }

        this._msalInstance.logout(logoutRequest)
    }

    private async login() {
        try {
            await this._msalInstance.loginRedirect({scopes: []})
        } catch (e) {
            console.error("Error : ", e)
        }
    }


    private handleRedirectPromise(response: msal.AuthenticationResult | null) {
        if (response !== null) {
            this.account = response.account
        } else {
            this.account = this.getAccount()
        }

        if (this.account) {
            this.getToken(this.account)
        } else {
            // This will happen when the user is not connected or it's impossible to retrieve the current account
            this.login()
        }
    }

    private getToken(account: msal.AccountInfo) {
        const request = {...this.request, account: account}
        this._msalInstance.acquireTokenSilent(request).then(tokenResponse => {
            this.token.resolveValue(tokenResponse, null)
        }).catch(async (error) => {
            if (error instanceof msal.InteractionRequiredAuthError) {
                return this._msalInstance.acquireTokenRedirect(request)
            }
        }).catch(error => {
            this.token.resolveValue(null, error)
            console.error("Error not handled in msal", error)
        })
    }

    private getAccount(): msal.AccountInfo | null {
        // need to call getAccount here?
        const currentAccounts = this._msalInstance.getAllAccounts()
        if (currentAccounts === null) {
            console.log("No accounts detected")
            return null
        }

        if (currentAccounts.length > 1) {
            // Add choose account code here
            console.log("Multiple accounts detected, need to add choose account code.")
            return currentAccounts[0]
        } else if (currentAccounts.length === 1) {
            return currentAccounts[0]
        }

        return null
    }

    async getAccessToken() : Promise<string | null> {
        const resolvedToken = await this.token.subscribe()
        if (resolvedToken != null) {
            return resolvedToken.idToken
        } else {
            return null
        }
    }

    private token: PromisePublisher<msal.AuthenticationResult> = new PromisePublisher()
    private account: msal.AccountInfo | null
    _msalInstance: msal.PublicClientApplication
}

export default {
    install(Vue, options) {
        Vue.prototype.$amarisVueMsalPlugin = AmarisVueMsalPlugin.getInstance(options)
    }
}
