var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c(
      "div",
      { staticClass: "bloc_header_fixed" },
      [
        _c(
          "router-link",
          {
            staticClass: "cta_back",
            attrs: {
              to: { name: "ManageCatalog", params: { selectedIndexProp: 1 } },
            },
          },
          [
            _c("span", { staticClass: "icon" }, [
              _c("span", { staticClass: "icon_back" }),
            ]),
            _c("span", { staticClass: "libelle" }, [_vm._v("Catalog")]),
          ]
        ),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("span", { staticClass: "title_bloc_header" }, [
              _vm._v(_vm._s(_vm.getGateway.name)),
            ]),
          ]),
          _c("div", { staticClass: "col-6 align_right" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 align_right" }, [
                _c("ul", { staticClass: "bloc_cta_style_02" }, [
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.getGateway.isActive,
                          expression: "getGateway.isActive",
                        },
                      ],
                      staticClass: "apple-switch",
                      attrs: { type: "checkbox", name: "chkActive" },
                      domProps: {
                        checked: Array.isArray(_vm.getGateway.isActive)
                          ? _vm._i(_vm.getGateway.isActive, null) > -1
                          : _vm.getGateway.isActive,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.getGateway.isActive,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.getGateway,
                                  "isActive",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.getGateway,
                                  "isActive",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.getGateway, "isActive", $$c)
                          }
                        },
                      },
                    }),
                  ]),
                  _vm._m(0),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn_secondary click_pointer",
                          attrs: {
                            to: {
                              name: "ManageCatalog",
                              params: { selectedIndexProp: 1 },
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "icon" }, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  version: "1.1",
                                  xmlns: "http://www.w3.org/2000/svg",
                                  "xmlns:xlink": "http://www.w3.org/1999/xlink",
                                  x: "0px",
                                  y: "0px",
                                  viewBox: "0 0 16 17",
                                  "xml:space": "preserve",
                                },
                              },
                              [
                                _c("path", {
                                  staticClass: "fill_color",
                                  attrs: {
                                    id: "Tracé_7652",
                                    d: "M9.7,8.5l5.9-5.9c0.3-0.3,0.3-0.9,0-1.2L15.1,1c-0.3-0.3-0.9-0.3-1.2,0L8,6.8L2.1,1 C1.8,0.6,1.3,0.6,0.9,1L0.4,1.5c-0.3,0.3-0.3,0.9,0,1.2l5.9,5.9l-5.9,5.9c-0.3,0.3-0.3,0.9,0,1.2l0.5,0.5c0.3,0.3,0.9,0.3,1.2,0 L8,10.3l5.9,5.9c0.2,0.2,0.4,0.3,0.6,0.3l0,0c0.2,0,0.4-0.1,0.6-0.3l0.5-0.5c0.3-0.3,0.3-0.9,0-1.2L9.7,8.5z",
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _c("span", { staticClass: "libelle" }, [
                            _vm._v("Cancel "),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "btn_primary",
                        on: { click: _vm.createOrUpdateGateway },
                      },
                      [
                        _c("span", { staticClass: "icon" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                version: "1.1",
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                                x: "0px",
                                y: "0px",
                                viewBox: "0 0 24 23",
                                "xml:space": "preserve",
                              },
                            },
                            [
                              _c("path", {
                                staticClass: "fill_color",
                                attrs: {
                                  id: "Tracé_7638",
                                  d: "M20.6,22.7H3.4c-1.4,0-2.6-1.1-2.6-2.6V2.9c0-1.4,1.1-2.6,2.6-2.6h14.5 c0.9,0,1.8,0.4,2.5,1l1.9,1.9c0.7,0.7,1,1.5,1,2.5v14.5C23.2,21.5,22,22.7,20.6,22.7L20.6,22.7z M3.4,1.7c-0.6,0-1.2,0.5-1.2,1.2 v17.2c0,0.6,0.5,1.2,1.2,1.2h17.2c0.6,0,1.2-0.5,1.2-1.2V5.7c0-0.6-0.2-1.1-0.6-1.5l-1.9-1.9c-0.4-0.4-0.9-0.6-1.5-0.6H3.4z",
                                },
                              }),
                              _c("path", {
                                staticClass: "fill_color",
                                attrs: {
                                  id: "Tracé_7639",
                                  d: "M15,6.8H5.2c-0.4,0-0.7-0.3-0.7-0.7c0,0,0,0,0,0V1c0-0.4,0.3-0.7,0.7-0.7l0,0 c0.4,0,0.7,0.3,0.7,0.7c0,0,0,0,0,0v4.4h8.4V1c0-0.4,0.3-0.7,0.7-0.7l0,0c0.4,0,0.7,0.3,0.7,0.7v5.1C15.7,6.5,15.4,6.8,15,6.8 L15,6.8L15,6.8z",
                                },
                              }),
                              _c("path", {
                                staticClass: "fill_color",
                                attrs: {
                                  id: "Tracé_7640",
                                  d: "M12,18.5c-2.3,0-4.2-1.9-4.2-4.2c0-2.3,1.9-4.2,4.2-4.2c2.3,0,4.2,1.9,4.2,4.2 S14.3,18.5,12,18.5L12,18.5z M12,11.5c-1.5,0-2.8,1.3-2.8,2.8c0,1.5,1.3,2.8,2.8,2.8c1.5,0,2.8-1.3,2.8-2.8v0 C14.8,12.8,13.5,11.5,12,11.5z",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("span", { staticClass: "libelle" }, [
                          _vm._v("Save"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "bloc_template bloc_padding patch_margin_top_fixed" },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "bloc_form_style_02" }, [
              _vm._m(1),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.getGateway.name,
                    expression: "getGateway.name",
                  },
                ],
                attrs: { name: "name", type: "text" },
                domProps: { value: _vm.getGateway.name },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.getGateway, "name", $event.target.value)
                    },
                    _vm.$v.getGateway.name.$touch,
                  ],
                },
              }),
              _vm.$v.getGateway.name.$dirty && !_vm.$v.getGateway.name.required
                ? _c("div", { staticClass: "gateway_form_error" }, [
                    _vm._v("Title is required"),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm._m(2),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "bloc_form_style_02" }, [
              _vm._m(3),
              _c("label", { staticClass: "file" }, [
                _c("input", {
                  attrs: {
                    id: "file",
                    name: "image",
                    accept: "image/png, image/jpeg, image/gif",
                    type: "file",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.handleImageFile($event)
                    },
                  },
                }),
                !_vm.getGateway.image
                  ? _c("span", { ref: "image", staticClass: "file-custom" }, [
                      _vm._v("Choose file..."),
                    ])
                  : _c("span", { ref: "image", staticClass: "file-custom" }, [
                      _vm._v("Image existing"),
                    ]),
              ]),
              _vm.$v.getGateway.image.$dirty &&
              !_vm.$v.getGateway.image.required
                ? _c("div", { staticClass: "gateway_form_error" }, [
                    _vm._v("Image is required"),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm._m(4),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "bloc_form_style_02" }, [
              _vm._m(5),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.getGateway.details,
                    expression: "getGateway.details",
                  },
                ],
                domProps: { value: _vm.getGateway.details },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.getGateway, "details", $event.target.value)
                    },
                    _vm.$v.getGateway.details.$touch,
                  ],
                },
              }),
              _vm.$v.getGateway.details.$dirty &&
              !_vm.$v.getGateway.details.required
                ? _c("div", { staticClass: "gateway_form_error" }, [
                    _vm._v("Decription is required"),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm._m(6),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("table", { staticClass: "table_bo" }, [
              _vm._m(7),
              _c("tr", [
                _c("td", [
                  _c("div", { staticClass: "bloc_form_style_02" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.getConfig.antennaDiameter,
                          expression: "getConfig.antennaDiameter",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "Antenna diameter",
                        min: "0.1",
                        step: "0.1",
                      },
                      domProps: { value: _vm.getConfig.antennaDiameter },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.getConfig,
                              "antennaDiameter",
                              $event.target.value
                            )
                          },
                          function ($event) {
                            _vm.$v.getConfig.antennaDiameter.$touch(),
                              _vm.editConfig()
                          },
                        ],
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "bloc_form_style_02" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.getConfig.efficiency,
                          expression: "getConfig.efficiency",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "Efficiency",
                        min: "0",
                        max: "100",
                      },
                      domProps: { value: _vm.getConfig.efficiency },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.getConfig,
                              "efficiency",
                              $event.target.value
                            )
                          },
                          function ($event) {
                            _vm.$v.getConfig.efficiency.$touch(),
                              _vm.editConfig()
                          },
                        ],
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "bloc_form_style_02" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.getConfig.pointingErrorAngle,
                          expression: "getConfig.pointingErrorAngle",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "Pointing error angle",
                        min: "0.001",
                        step: "0.001",
                      },
                      domProps: { value: _vm.getConfig.pointingErrorAngle },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.getConfig,
                              "pointingErrorAngle",
                              $event.target.value
                            )
                          },
                          function ($event) {
                            _vm.$v.getConfig.pointingErrorAngle.$touch(),
                              _vm.editConfig()
                          },
                        ],
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "bloc_form_style_02" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.getConfig.saturatingEirp,
                          expression: "getConfig.saturatingEirp",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "Saturating EIRP",
                        min: "1",
                      },
                      domProps: { value: _vm.getConfig.saturatingEirp },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.getConfig,
                              "saturatingEirp",
                              $event.target.value
                            )
                          },
                          function ($event) {
                            _vm.$v.getConfig.saturatingEirp.$touch(),
                              _vm.editConfig()
                          },
                        ],
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "bloc_form_style_02" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.getConfig.referenceTxFrequency,
                          expression: "getConfig.referenceTxFrequency",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "Reference TX frequency",
                        min: "1",
                      },
                      domProps: { value: _vm.getConfig.referenceTxFrequency },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.getConfig,
                              "referenceTxFrequency",
                              $event.target.value
                            )
                          },
                          function ($event) {
                            _vm.$v.getConfig.referenceTxFrequency.$touch(),
                              _vm.editConfig()
                          },
                        ],
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", { staticClass: "tr_error" }, [
                _c("td", [
                  _vm.$v.getConfig.antennaDiameter.$dirty &&
                  !_vm.$v.getConfig.antennaDiameter.minValue
                    ? _c("div", { staticClass: "gateway_form_error" }, [
                        _vm._v("Min value is 0.1"),
                      ])
                    : _vm._e(),
                ]),
                _c("td", [
                  _vm.$v.getConfig.efficiency.$dirty &&
                  !_vm.$v.getConfig.efficiency.minValue
                    ? _c("div", { staticClass: "gateway_form_error" }, [
                        _vm._v("Min value is 0"),
                      ])
                    : _vm._e(),
                  _vm.$v.getConfig.efficiency.$dirty &&
                  !_vm.$v.getConfig.efficiency.maxValue
                    ? _c("div", { staticClass: "gateway_form_error" }, [
                        _vm._v("Max value is 100"),
                      ])
                    : _vm._e(),
                ]),
                _c("td", [
                  _vm.$v.getConfig.pointingErrorAngle.$dirty &&
                  !_vm.$v.getConfig.pointingErrorAngle.minValue
                    ? _c("div", { staticClass: "gateway_form_error" }, [
                        _vm._v("Min value is 0.001"),
                      ])
                    : _vm._e(),
                ]),
                _c("td", [
                  _vm.$v.getConfig.saturatingEirp.$dirty &&
                  !_vm.$v.getConfig.saturatingEirp.minValue
                    ? _c("div", { staticClass: "gateway_form_error" }, [
                        _vm._v("Min value is 1"),
                      ])
                    : _vm._e(),
                ]),
                _c("td", [
                  _vm.$v.getConfig.referenceTxFrequency.$dirty &&
                  !_vm.$v.getConfig.referenceTxFrequency.minValue
                    ? _c("div", { staticClass: "gateway_form_error" }, [
                        _vm._v("Min value is 1"),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
            _c("br"),
            _c("br"),
            _c("table", { staticClass: "table_bo" }, [
              _vm._m(8),
              _c("tr", [
                _c("td", [
                  _c("div", { staticClass: "bloc_form_style_02" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.getConfig.ulpcRange,
                          expression: "getConfig.ulpcRange",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "ULPC range",
                        min: "0.1",
                        step: "0.1",
                      },
                      domProps: { value: _vm.getConfig.ulpcRange },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.getConfig,
                              "ulpcRange",
                              $event.target.value
                            )
                          },
                          function ($event) {
                            _vm.$v.getConfig.ulpcRange.$touch(),
                              _vm.editConfig()
                          },
                        ],
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "bloc_form_style_02" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.getConfig.obo,
                          expression: "getConfig.obo",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "OBO",
                        min: "0.1",
                        step: "0.1",
                      },
                      domProps: { value: _vm.getConfig.obo },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.getConfig, "obo", $event.target.value)
                          },
                          function ($event) {
                            _vm.$v.getConfig.obo.$touch(), _vm.editConfig()
                          },
                        ],
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "bloc_form_style_02" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.getConfig.csIm,
                          expression: "getConfig.csIm",
                        },
                      ],
                      attrs: { type: "number", name: "CsIm", min: "1" },
                      domProps: { value: _vm.getConfig.csIm },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.getConfig, "csIm", $event.target.value)
                          },
                          function ($event) {
                            _vm.$v.getConfig.csIm.$touch(), _vm.editConfig()
                          },
                        ],
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "bloc_form_style_02" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.getConfig.gst,
                          expression: "getConfig.gst",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "GsT",
                        min: "0.1",
                        step: "0.1",
                      },
                      domProps: { value: _vm.getConfig.gst },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.getConfig, "gst", $event.target.value)
                          },
                          function ($event) {
                            _vm.$v.getConfig.gst.$touch(), _vm.editConfig()
                          },
                        ],
                      },
                    }),
                  ]),
                ]),
                _c("td", [
                  _c("div", { staticClass: "bloc_form_style_02" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.getConfig.referenceRxFrequency,
                          expression: "getConfig.referenceRxFrequency",
                        },
                      ],
                      attrs: {
                        type: "number",
                        name: "Reference RX frequency",
                        min: "1",
                      },
                      domProps: { value: _vm.getConfig.referenceRxFrequency },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.getConfig,
                              "referenceRxFrequency",
                              $event.target.value
                            )
                          },
                          function ($event) {
                            _vm.$v.getConfig.referenceRxFrequency.$touch(),
                              _vm.editConfig()
                          },
                        ],
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("tr", { staticClass: "tr_error" }, [
                _c("td", [
                  _vm.$v.getConfig.ulpcRange.$dirty &&
                  !_vm.$v.getConfig.ulpcRange.minValue
                    ? _c("div", { staticClass: "gateway_form_error" }, [
                        _vm._v("Min value is 0.1"),
                      ])
                    : _vm._e(),
                ]),
                _c("td", [
                  _vm.$v.getConfig.obo.$dirty && !_vm.$v.getConfig.obo.minValue
                    ? _c("div", { staticClass: "gateway_form_error" }, [
                        _vm._v("Min value is 0.1"),
                      ])
                    : _vm._e(),
                ]),
                _c("td", [
                  _vm.$v.getConfig.csIm.$dirty &&
                  !_vm.$v.getConfig.csIm.minValue
                    ? _c("div", { staticClass: "gateway_form_error" }, [
                        _vm._v("Min value is 1"),
                      ])
                    : _vm._e(),
                ]),
                _c("td", [
                  _vm.$v.getConfig.gst.$dirty && !_vm.$v.getConfig.gst.minValue
                    ? _c("div", { staticClass: "gateway_form_error" }, [
                        _vm._v("Min value is 0.1"),
                      ])
                    : _vm._e(),
                ]),
                _c("td", [
                  _vm.$v.getConfig.referenceRxFrequency.$dirty &&
                  !_vm.$v.getConfig.referenceRxFrequency.minValue
                    ? _c("div", { staticClass: "gateway_form_error" }, [
                        _vm._v("Min value is 1"),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { staticClass: "libelle-centered" }, [_vm._v(" Activated")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "name" } }, [
      _vm._v("Title"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("span", { staticClass: "separator" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_vm._v("Image"), _c("sup", [_vm._v("*")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("span", { staticClass: "separator" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_vm._v("Description"), _c("sup", [_vm._v("*")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("span", { staticClass: "separator" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Antenna diameter "), _c("i", [_vm._v("(m)")])]),
      _c("th", [_vm._v("Efficiency "), _c("i", [_vm._v("(%)")])]),
      _c("th", [_vm._v("Pointing error angle "), _c("i", [_vm._v("(°)")])]),
      _c("th", [_vm._v("Saturating EIRP "), _c("i", [_vm._v("(dBW)")])]),
      _c("th", [_vm._v("Reference TX frequency "), _c("i", [_vm._v("(MHz)")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("ULPC range "), _c("i", [_vm._v("(dB)")])]),
      _c("th", [_vm._v("OBO "), _c("i", [_vm._v("(dB)")])]),
      _c("th", [_vm._v("CsIm "), _c("i", [_vm._v("(dB)")])]),
      _c("th", [_vm._v("GsT "), _c("i", [_vm._v("(dB/K)")])]),
      _c("th", [_vm._v("Reference RX frequency "), _c("i", [_vm._v("(MHz)")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }