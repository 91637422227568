import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import {AmarisVueMsalPlugin} from "@/core/plugins/amarisVueMsalPlugin"
import {skytRoles} from '@/config/config'
import ManageCustomersProjects from "@/views/ManageCustomersProjects.vue"
import ProjectDetails from "@/views/ProjectDetails.vue"
import Administration from '@/views/Administration.vue'
import ManageCatalog from "@/views/ManageCatalog.vue"
import SatelliteDetails from "@/views/SatelliteDetails.vue"
import GatewayDetails from '@/views/GatewayDetails.vue'
import ManageTransfers from "@/views/ManageTransfers.vue"
import Forbidden from "@/views/Forbidden.vue"
import PageNotFound from "@/views/PageNotFound.vue"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'CustomersProjects',
        component: ManageCustomersProjects,
        props: true
    },
    {
        path: '/project/:id',
        name: 'ProjectDetails',
        component: ProjectDetails,
        props: route => ({projectId: route.params.id})
    },
    {
        path: '/administration',
        name: 'Administration',
        component: Administration,
        props: true,
        meta: {authorize: skytRoles.admin}
    },
    {
        path: '/catalog',
        name: 'ManageCatalog',
        component: ManageCatalog,
        props: true,
        meta: {authorize: skytRoles.admin}
    },
    {
        path: '/satellite/:id?',
        name: 'SatelliteDetails',
        component: SatelliteDetails,
        props: route => ({satelliteId: route.params.id}),
        meta: {authorize: skytRoles.admin}
    },
    {
        path: '/gateway/:id?',
        name: 'Gateway Details',
        component: GatewayDetails,
        props: route => ({gatewayId: route.params.id})
    },
    {
        path: '/transfer',
        name: 'ImportExport',
        component: ManageTransfers,
        props: true,
        meta: {authorize: skytRoles.dynamiteManager}
    },
    {
        path: '/forbidden',
        name: 'Forbidden',
        component: Forbidden,
        props: true
    },
    {
        path: '/not-found',
        alias: '*',
        name: 'PageNotFound',
        component: PageNotFound,
        props: true
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    const authorizationRequired = to.matched.some(record => record.meta.authorize)
    const amarisPluginInstance = AmarisVueMsalPlugin.getInstance(null)
    const hasRole = await amarisPluginInstance.hasRole(to.meta.authorize)
    if (to.name !== 'Forbidden' && (!await amarisPluginInstance.hasAtLeastOneSkytRole() || (authorizationRequired && !hasRole))) {
        next({name: 'Forbidden'})
    } else {
        next()
    }
})

export default router
