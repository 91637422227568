import Vue from "vue";
import { media } from "@/utils/media";

export default Vue.extend({
  props: ['src', 'pictureClass', 'alt'],
  data() {
    return {
      picture: undefined
    }
  },
  mounted() {
    this.getPicture()
  },
  methods: {
    async getPicture() {
      this.picture = await media.getPicture(this.src)
    }
  }
})