var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "not_found" },
    [
      _c("h1", [_vm._v("404 - Page not found")]),
      _c("div", [
        _vm._v(
          "Sorry, the requested resource cannot be found. Please, go back to the homepage."
        ),
      ]),
      _c(
        "router-link",
        { staticClass: "homepage btn_primary", attrs: { to: "/" } },
        [_c("span", { staticClass: "libelle" }, [_vm._v("Homepage")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }