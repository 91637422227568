import Vue from "vue";
import DeleteModal from '@/components/DeleteModal.vue'
import SelectableLine from '@/components/SelectableLine.vue'
import draggable from 'vuedraggable'
import { mapActions, mapGetters } from 'vuex';
import { GET_SATELLITES } from '@/store/aliases/getters.type';
import { CATALOG_TYPE } from '@/store/types';
import { SATELLITES_FETCH, SATELLITE_DELETE, SATELLITE_UPDATE_INDEX } from '@/store/aliases/actions.type';
import { comparator } from '@/utils/comparator';

export default Vue.extend({
    components: {
        DeleteModal,
        SelectableLine,
        draggable
    },
    mounted() {
        this.fetchSatellites()
    },
    computed: {
        ...mapGetters({
            getSatellites: `${CATALOG_TYPE}/${GET_SATELLITES}`
        }),
        activeSatellites() {
            return this.getSatellites.filter(satellite => satellite.isActive)
        },
        inactiveSatellites() {
            return this.getSatellites.filter(satellite => !satellite.isActive)
                                    .slice().sort((a, b) => comparator.compareObjectsOnStrings(a, b, 'name'))
        }
    },
    methods: {
        ...mapActions({
            fetchSatellites: `${CATALOG_TYPE}/${SATELLITES_FETCH}`,
            deleteSatellite: `${CATALOG_TYPE}/${SATELLITE_DELETE}`,
            updateSatelliteIndex: `${CATALOG_TYPE}/${SATELLITE_UPDATE_INDEX}`
        }),
        selectSatellite(satellite) {
            this.$router.push({ path: `/satellite/${satellite.id}` })
        },
        addSatellite() {
            this.$router.push({ path: '/satellite' })
        },
        deleteComponent(satellite) {
            this.deleteSatellite({ satelliteId: satellite.id, isActive: satellite.isActive })
        },
        updateIndex(event) {
            this.updateSatelliteIndex({ satelliteId: event.moved.element.id, index: event.moved.newIndex})
        }
    }
})
