import { render, staticRenderFns } from "./ProjectDetails/ProjectDetails.pug?vue&type=template&id=c8ff15ba&lang=pug&"
import script from "./ProjectDetails/ProjectDetails.ts?vue&type=script&lang=js&"
export * from "./ProjectDetails/ProjectDetails.ts?vue&type=script&lang=js&"
import style0 from "./ProjectDetails.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/replat-tas-oen-skyt/back-office/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c8ff15ba')) {
      api.createRecord('c8ff15ba', component.options)
    } else {
      api.reload('c8ff15ba', component.options)
    }
    module.hot.accept("./ProjectDetails/ProjectDetails.pug?vue&type=template&id=c8ff15ba&lang=pug&", function () {
      api.rerender('c8ff15ba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ProjectDetails.vue"
export default component.exports