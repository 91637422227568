import { render, staticRenderFns } from "./ManageSatellites/ManageSatellites.pug?vue&type=template&id=725cb797&lang=pug&"
import script from "./ManageSatellites/ManageSatellites.ts?vue&type=script&lang=js&"
export * from "./ManageSatellites/ManageSatellites.ts?vue&type=script&lang=js&"
import style0 from "./ManageSatellites.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/replat-tas-oen-skyt/back-office/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('725cb797')) {
      api.createRecord('725cb797', component.options)
    } else {
      api.reload('725cb797', component.options)
    }
    module.hot.accept("./ManageSatellites/ManageSatellites.pug?vue&type=template&id=725cb797&lang=pug&", function () {
      api.rerender('725cb797', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ManageSatellites.vue"
export default component.exports