var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "forbidden" },
    [
      _c("h1", [_vm._v("403 - Forbidden")]),
      _vm.error
        ? [
            _c("div", [
              _vm._v(
                "You are not authorized to access this resource. Please, go back to the homepage."
              ),
            ]),
            _c(
              "router-link",
              { staticClass: "logout btn_primary", attrs: { to: "/" } },
              [_c("span", { staticClass: "libelle" }, [_vm._v("Homepage")])]
            ),
          ]
        : [
            _c("div", [_vm._v("You are not authorized to access this page.")]),
            _c(
              "div",
              {
                staticClass: "logout btn_primary",
                on: {
                  click: function ($event) {
                    return _vm.logOut()
                  },
                },
              },
              [_c("span", { staticClass: "libelle" }, [_vm._v("Logout")])]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }