// tslint:disable
/**
 * SkyT
 * SkyT contract
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: email@email.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiError } from '../model';
// @ts-ignore
import { ReferentialData } from '../model';
// @ts-ignore
import { ReferentialUserData } from '../model';
// @ts-ignore
import { UploadReferentialResponse } from '../model';
/**
 * ReferentialApi - axios parameter creator
 * @export
 */
export const ReferentialApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the diff of the referential data. If no timestamp provided or it is too old return all the database
         * @summary Get the diff of referential data
         * @param {number} [timestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferentialDataDiff: async (timestamp?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/referential/sync-diff`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the referential data of the csv
         * @summary Get the referential data of the CSV
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferentialDataOfCsv: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/referential/data`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload files to replace referential datas
         * @summary Upload the new referential data
         * @param {any} [userTerminal] 
         * @param {any} [userFrequency] 
         * @param {any} [service] 
         * @param {any} [gatewayFrequency] 
         * @param {any} [zooming] 
         * @param {any} [modCods] 
         * @param {any} [waveforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadReferentialData: async (userTerminal?: any, userFrequency?: any, service?: any, gatewayFrequency?: any, zooming?: any, modCods?: any, waveforms?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/referential/upload-referential-data`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();


            if (userTerminal !== undefined) { 
                localVarFormParams.append('userTerminal', userTerminal as any);
            }
    
            if (userFrequency !== undefined) { 
                localVarFormParams.append('userFrequency', userFrequency as any);
            }
    
            if (service !== undefined) { 
                localVarFormParams.append('service', service as any);
            }
    
            if (gatewayFrequency !== undefined) { 
                localVarFormParams.append('gatewayFrequency', gatewayFrequency as any);
            }
    
            if (zooming !== undefined) { 
                localVarFormParams.append('zooming', zooming as any);
            }
    
            if (modCods !== undefined) { 
                localVarFormParams.append('modCods', modCods as any);
            }
    
            if (waveforms !== undefined) { 
                localVarFormParams.append('waveforms', waveforms as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferentialApi - functional programming interface
 * @export
 */
export const ReferentialApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get the diff of the referential data. If no timestamp provided or it is too old return all the database
         * @summary Get the diff of referential data
         * @param {number} [timestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferentialDataDiff(timestamp?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReferentialUserData>>> {
            const localVarAxiosArgs = await ReferentialApiAxiosParamCreator(configuration).getReferentialDataDiff(timestamp, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the referential data of the csv
         * @summary Get the referential data of the CSV
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferentialDataOfCsv(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferentialData>> {
            const localVarAxiosArgs = await ReferentialApiAxiosParamCreator(configuration).getReferentialDataOfCsv(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Upload files to replace referential datas
         * @summary Upload the new referential data
         * @param {any} [userTerminal] 
         * @param {any} [userFrequency] 
         * @param {any} [service] 
         * @param {any} [gatewayFrequency] 
         * @param {any} [zooming] 
         * @param {any} [modCods] 
         * @param {any} [waveforms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadReferentialData(userTerminal?: any, userFrequency?: any, service?: any, gatewayFrequency?: any, zooming?: any, modCods?: any, waveforms?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UploadReferentialResponse>>> {
            const localVarAxiosArgs = await ReferentialApiAxiosParamCreator(configuration).uploadReferentialData(userTerminal, userFrequency, service, gatewayFrequency, zooming, modCods, waveforms, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ReferentialApi - factory interface
 * @export
 */
export const ReferentialApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get the diff of the referential data. If no timestamp provided or it is too old return all the database
         * @summary Get the diff of referential data
         * @param {number} [timestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferentialDataDiff(timestamp?: number, options?: any): AxiosPromise<Array<ReferentialUserData>> {
            return ReferentialApiFp(configuration).getReferentialDataDiff(timestamp, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the referential data of the csv
         * @summary Get the referential data of the CSV
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferentialDataOfCsv(options?: any): AxiosPromise<ReferentialData> {
            return ReferentialApiFp(configuration).getReferentialDataOfCsv(options).then((request) => request(axios, basePath));
        },
        /**
         * Upload files to replace referential datas
         * @summary Upload the new referential data
         * @param {any} [userTerminal] 
         * @param {any} [userFrequency] 
         * @param {any} [service] 
         * @param {any} [gatewayFrequency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadReferentialData(userTerminal?: any, userFrequency?: any, service?: any, gatewayFrequency?: any, zooming?: any, modCods?: any, waveforms?: any, options?: any): AxiosPromise<Array<UploadReferentialResponse>> {
            return ReferentialApiFp(configuration).uploadReferentialData(userTerminal, userFrequency, service, gatewayFrequency, zooming, modCods, waveforms, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReferentialApi - object-oriented interface
 * @export
 * @class ReferentialApi
 * @extends {BaseAPI}
 */
export class ReferentialApi extends BaseAPI {
    /**
     * Get the diff of the referential data. If no timestamp provided or it is too old return all the database
     * @summary Get the diff of referential data
     * @param {number} [timestamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferentialApi
     */
    public getReferentialDataDiff(timestamp?: number, options?: any) {
        return ReferentialApiFp(this.configuration).getReferentialDataDiff(timestamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the referential data of the csv
     * @summary Get the referential data of the CSV
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferentialApi
     */
    public getReferentialDataOfCsv(options?: any) {
        return ReferentialApiFp(this.configuration).getReferentialDataOfCsv(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload files to replace referential datas
     * @summary Upload the new referential data
     * @param {any} [userTerminal] 
     * @param {any} [userFrequency] 
     * @param {any} [service] 
     * @param {any} [gatewayFrequency] 
     * @param {any} [zooming] 
     * @param {any} [modCods] 
     * @param {any} [waveforms] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferentialApi
     */
    public uploadReferentialData(userTerminal?: any, userFrequency?: any, service?: any, gatewayFrequency?: any, zooming?: any, modCods?: any, waveforms?: any, options?: any) {
        return ReferentialApiFp(this.configuration).uploadReferentialData(userTerminal, userFrequency, service, gatewayFrequency, zooming, modCods, waveforms, options).then((request) => request(this.axios, this.basePath));
    }

}
