export const SEGMENTS_FETCH = 'fetchSegments'
export const SEGMENTS_WITH_DEPENDENCIES_FETCH = 'fetchSegmentsWithDependencies'
export const SEGMENT_CATEGORIES_FETCH = 'fetchSegmentCategories'
export const SEGMENT_CATEGORY_ITEM_FETCH = 'fetchSegmentCategoryItem'
export const SEGMENT_CATEGORY_ITEMS_FETCH = 'fetchSegmentCategoryItems'
export const SEGMENT_CATEGORY_TITLES_FETCH = 'fetchSegmentCategoryTitles'
export const SEGMENT_CATEGORY_TITLES_UPDATE = 'updateSegmentCategoryTitles'

export const SEGMENT_CREATE = 'createSegment'
export const SEGMENT_UPDATE = 'updateSegment'
export const SEGMENT_DELETE = 'deleteSegment'

export const SEGMENT_CATEGORY_CREATE = 'createSegmentCategory'
export const SEGMENT_CATEGORY_UPDATE = 'updateSegmentCategory'
export const SEGMENT_CATEGORY_DELETE = 'deleteSegmentCategory'

export const SEGMENT_CATEGORY_ITEM_CREATE = 'createCategorySegmentItem'
export const SEGMENT_CATEGORY_ITEM_UPDATE = 'updateCategorySegmentItem'
export const SEGMENT_CATEGORY_ITEM_DELETE = 'deleteCategorySegmentItem'

export const PERSONA_INTERVIEW_FETCH = 'fetchPersonaInterview'
export const PERSONA_INTERVIEW_QUESTION_FETCH = 'fetchPersonaInterviewQuestion'
export const PERSONA_INTERVIEW_QUESTION_UPDATE = 'updatePersonaInterviewQuestion'
export const PERSONA_INTERVIEW_QUESTION_DELETE = 'deletePersonaInterviewQuestion'

export const OFFERS_FETCH = 'fetchOffers'
export const OFFER_FETCH = 'fetchOffer'
export const OFFER_CREATE = 'createOffer'
export const OFFER_UPDATE = 'updateOffer'
export const OFFER_DELETE = 'deleteOffer'

export const PERSONAS_FETCH = 'fetchPersonas'
export const PERSONA_FETCH = 'fetchPersona'
export const PERSONA_CREATE = 'createPersona'
export const PERSONA_UPDATE = 'updatePersona'
export const PERSONA_DELETE = 'deletePersona'

export const CUSTOMERS_FETCH = 'fetchCustomers'
export const CUSTOMER_FETCH = 'fetchCustomer'
export const CUSTOMER_CREATE = 'createCustomer'
export const CUSTOMER_UPDATE = 'updateCustomer'
export const CUSTOMER_DELETE = 'deleteCustomer'
export const CUSTOMER_UPLOAD_LOGO = 'uploadLogoForCustomer'

export const LEGAL_NOTICE_FETCH = 'fetchLegalNotice'
export const LEGAL_NOTICE_UPDATE = 'updateLegalNotice'

export const COOKIES_FETCH = 'fetchCookies'
export const COOKIES_UPDATE = 'updateCookies'

export const CONDITIONS_FETCH = 'fetchConditions'
export const CONDITIONS_UPDATE = 'updateConditions'

export const ABOUT_FETCH = 'fetchAbout'
export const ABOUT_UPDATE = 'updateAbout'

export const BEAM_CUSTOMISATION_FETCH = 'fetchBeamCustomisation'
export const BEAM_CUSTOMISATION_UPDATE = 'updateBeamCustomisation'

export const PROJECTS_FETCH = 'fetchProjects'
export const PROJECT_FETCH = 'fetchProject'
export const PROJECT_CREATE = 'createProject'
export const PROJECT_UPDATE = 'updateProject'
export const PROJECT_DELETE = 'deleteProject'
export const PROJECT_UPLOAD_KML = 'uploadKmlForProject'

export const PROJECT_EXPORTS_FETCH = 'fetchProjectExports'

export const REFERENTIAL_UPLOAD = 'uploadReferential'
export const MISSION_COLORS_FETCH = 'fetchMissionColors'
export const MISSION_COLOR_CREATE = 'createMissionColor'
export const MISSION_COLOR_UPDATE = 'updateMissionColor'
export const MISSION_COLOR_UPDATE_INDEX = 'updateMissionColorIndex'
export const MISSION_COLOR_DELETE = 'deleteMissionColor'

export const SATELLITES_FETCH = 'fetchSatellites'
export const SATELLITE_FETCH = 'fetchSatellite'
export const SATELLITE_CREATE = 'createSatellite'
export const SATELLITE_UPDATE = 'updateSatellites'
export const SATELLITE_UPDATE_INDEX = 'updateSatelliteIndex'
export const SATELLITE_DELETE = 'deleteSatellite'

export const GATEWAYS_FETCH = 'fetchGateways'
export const GATEWAY_FETCH = 'fetchGateway'
export const GATEWAY_CREATE = 'createGateway'
export const GATEWAY_UPDATE = 'updateGateways'
export const GATEWAY_UPDATE_INDEX = 'updateGatewayIndex'
export const GATEWAY_DELETE = 'deleteGateway'
