import Vue from "vue"
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {CATALOG_TYPE} from '@/store/types'
import {GET_SATELLITE} from '@/store/aliases/getters.type'
import {SATELLITE_CREATE, SATELLITE_FETCH, SATELLITE_UPDATE} from '@/store/aliases/actions.type'
import {CoverageType} from '@/core/models/coverage-type'
import {SET_SATELLITE} from '@/store/aliases/mutations.type'
import {SatelliteModel} from '@/core/models'
import {minValue, required} from 'vuelidate/lib/validators'
import {displayMessage, ERROR_TYPES} from '@/core/middlewares/error'

export default Vue.extend({
    data() {
        return {
            coverageTypes: coverageTypes,
            isImageUpdated: {
                picture: false,
                logo: false
            }
        }
    },
    props: {
        satelliteId: String
    },
    validations: {
        getSatellite: {
            name: {
                required
            },
            logo: {
                required
            },
            picture: {
                required
            },
            details: {
                required
            },
            power: {
                required,
                minValue: minValue(1)
            },
            supportedCoverages: {
                required
            }
        }
    },
    mounted() {
        if (this.satelliteId) {
            this.fetchSatellite({satelliteId: this.satelliteId})
        } else {
            this.setSatellite({
                satellite: {
                    logo: "",
                    picture: "",
                    supportedCoverages: [],
                    isSpaceInspire: false,
                    isActive: true
                } as SatelliteModel
            })
        }
    },
    computed: {
        ...mapGetters({
            getSatellite: `${CATALOG_TYPE}/${GET_SATELLITE}`
        }),
        getCoverageTypes() {
            this.coverageTypes.forEach(coverageType => {
                // getSatellite contains last selected satellite, so reset selected to false
                coverageType.selected = false
                if (this.getSatellite.supportedCoverages && this.getSatellite.supportedCoverages.includes(coverageType.type)) {
                    coverageType.selected = true
                }
            })
            return this.coverageTypes
        }
    },
    methods: {
        ...mapActions({
            fetchSatellite: `${CATALOG_TYPE}/${SATELLITE_FETCH}`,
            createSatellite: `${CATALOG_TYPE}/${SATELLITE_CREATE}`,
            updateSatellite: `${CATALOG_TYPE}/${SATELLITE_UPDATE}`,
        }),
        ...mapMutations({
            setSatellite: `${CATALOG_TYPE}/${SET_SATELLITE}`
        }),
        async createOrUpdateSatellite() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                displayMessage({message: 'Please, fix the errors before saving.', type: ERROR_TYPES.ERROR})
            } else {
                let result
                if (this.getSatellite.id) {
                    result = await this.updateSatellite({
                        satellite: this.getSatellite,
                        satellitePicture: this.isImageUpdated['picture'] ? this.getSatellite.picture : undefined,
                        satelliteLogo: this.isImageUpdated['logo'] ? this.getSatellite.logo : undefined
                    })
                } else {
                    result = await this.createSatellite({satellite: this.getSatellite})
                }

                if (result) {
                    this.$router.push({name: 'ManageCatalog', params: {selectedIndexProp: 0}})
                }
            }
        },
        toggleCoverageTypeSelection(index) {
            this.$v.getSatellite.supportedCoverages.$touch()
            if (this.coverageTypes[index].selected) {
                this.coverageTypes[index].selected = false
                let indexOfCoverageType = this.getSatellite.supportedCoverages.indexOf(this.coverageTypes[index].type)
                this.getSatellite.supportedCoverages.splice(indexOfCoverageType, 1)
            } else {
                this.coverageTypes[index].selected = true
                this.getSatellite.supportedCoverages.push(this.coverageTypes[index].type)
            }
        },
        handleFile(event, property) {
            let file = event.target.files[0]
            if (file) {
                this.$v.getSatellite[property].$touch()
                this.getSatellite[property] = file
                this.isImageUpdated[property] = true
                this.$refs[property].innerText = file.name
            }
        },
    }
})

export interface CoverageTypeItem {
    name: string,
    selected: boolean,
    type: CoverageType
}

export const coverageTypes: CoverageTypeItem[] = [
    {name: 'Multi Beam', selected: false, type: CoverageType.MULTIBEAM},
    {name: 'Steerable Beam', selected: false, type: CoverageType.STEERABLEBEAM},
    {name: 'Regional Beam', selected: false, type: CoverageType.REGIONAL}
]
