var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container user_info_component" }, [
    _c("div", { staticClass: "nav" }, [
      _vm._m(0),
      _c("div", { staticClass: "bloc_user" }, [
        _c("div", { staticClass: "user_info_name" }, [
          _c("span", { staticClass: "txt" }, [_vm._v("Welcome ")]),
          _c(
            "span",
            { staticClass: "txt", staticStyle: { "font-weight": "bold" } },
            [_vm._v(_vm._s(_vm.userInfo.name))]
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "deconnexion",
          on: {
            click: function ($event) {
              return _vm.logOut()
            },
          },
        },
        [
          _c("span", { staticClass: "icon_logout" }),
          _c("span", { staticClass: "txt" }, [_vm._v("Logout ")]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/logo_skyt.svg"), border: "0" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }