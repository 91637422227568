var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.picture
    ? _c("img", {
        class: _vm.pictureClass,
        attrs: { src: _vm.picture, alt: _vm.alt },
      })
    : _c("img", {
        class: _vm.pictureClass,
        attrs: { src: require("@/assets/img/spinner.gif"), alt: _vm.alt },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }