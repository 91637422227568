import { render, staticRenderFns } from "./ManageCustomersProjects/ManageCustomersProjects.pug?vue&type=template&id=187a12c0&lang=pug&"
import script from "./ManageCustomersProjects/ManageCustomersProjects.ts?vue&type=script&lang=js&"
export * from "./ManageCustomersProjects/ManageCustomersProjects.ts?vue&type=script&lang=js&"
import style0 from "./ManageCustomersProjects.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/replat-tas-oen-skyt/back-office/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('187a12c0')) {
      api.createRecord('187a12c0', component.options)
    } else {
      api.reload('187a12c0', component.options)
    }
    module.hot.accept("./ManageCustomersProjects/ManageCustomersProjects.pug?vue&type=template&id=187a12c0&lang=pug&", function () {
      api.rerender('187a12c0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ManageCustomersProjects.vue"
export default component.exports