// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/h2_satelitte.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "h6 {\n  color: #242a75;\n  font-weight: 100;\n}\n.h2satelitte {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: 31px 31px;\n  background-position: left top;\n  font-size: 1.8rem;\n  padding-left: 40px;\n  height: 41px;\n}\n.cta-form-group-02 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: 1px dashed #242a75;\n  border-radius: 60px;\n  padding-top: 5px;\n  min-height: 65px;\n  padding-bottom: 5px;\n  padding-left: 30px;\n  padding-right: 30px;\n  margin-top: 30px;\n  cursor: pointer;\n}\n.cta-form-group-02:hover {\n  border: 1px dashed #53B8D9;\n}", ""]);
// Exports
module.exports = exports;
