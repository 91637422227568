import _ from 'lodash'

import { SATELLITES_FETCH, SATELLITE_CREATE, SATELLITE_FETCH, SATELLITE_UPDATE, GATEWAYS_FETCH, GATEWAY_FETCH, SATELLITE_DELETE, GATEWAY_CREATE, GATEWAY_DELETE, SATELLITE_UPDATE_INDEX, GATEWAY_UPDATE_INDEX, GATEWAY_UPDATE } from '../aliases/actions.type'
import { GET_SATELLITE, GET_SATELLITES, GET_GATEWAY, GET_GATEWAYS } from '../aliases/getters.type'
import { SET_SATELLITES, SET_SATELLITE, ADD_SATELLITE, EDIT_SATELLITE, REMOVE_SATELLITE, SET_GATEWAYS, SET_GATEWAY, ADD_GATEWAY, EDIT_GATEWAY, REMOVE_GATEWAY } from '../aliases/mutations.type'

import { displayMessage, ERROR_TYPES, handleApiFetchError } from '@/core/middlewares/error'
import { log, LOG_TYPES } from '@/core/middlewares/Logger'

import { getConfiguration } from '@/core/utils/config.util'
import { SatelliteModel, GatewayModel } from '@/core/models'

import {CatalogGatewayApi, CatalogSatelliteApi, ProjectApi} from '@/core'

import Vue from 'vue'

const namespaced = true

const replaceSatellite = ({ state, satellite }) => {
    const index = _.findIndex(state.satellites, satellite => satellite.id === state.satellite.id)
    if (index >= 0) {
        state.satellites[index] = satellite || state.satellite
    }
}

const replaceGateway = ({ state, gateway }) => {
    const index = _.findIndex(state.gateways, gateway => gateway.id === state.gateway.id)
    if (index >= 0) {
        state.gateways[index] = gateway || state.gateway
    }
}

export default {
    namespaced,
    state: {
		satellites: [],
        satellite: {},
		gateways: [],
        gateway: {}
    },

    getters: {
        [GET_SATELLITES]: (state) => {
            return state.satellites
        },
        [GET_SATELLITE]: (state) => {
            return state.satellite
		},
		[GET_GATEWAYS]: (state) => {
            return state.gateways
        },
        [GET_GATEWAY]: (state) => {
            return state.gateway
        }
	},

    actions: {
        [SATELLITES_FETCH]({ commit }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Fetching all satellites.` })
            return getConfiguration().then(config => {
                return new CatalogSatelliteApi(config).getAllSatelliteModels()
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Satelittes: ${data.length} returned.`
                })
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Satellites: ${JSON.stringify(data)} returned successfully.`
                })
                commit(SET_SATELLITES, { satellites: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while fetching satellites.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
        [SATELLITE_FETCH]({ commit }, { satelliteId }: { satelliteId: number }) {
            console.log("Getting satellite with id " + satelliteId )
            return getConfiguration().then(config => {
                return new CatalogSatelliteApi(config).getSatelliteModelById(satelliteId)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Satellite: ${JSON.stringify(data)} returned successfully.`
                })
                commit(SET_SATELLITE, { satellite: data })
                return data
            }).catch(({ response }) => {
                handleApiFetchError(response)
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
        async [SATELLITE_CREATE]({ commit }, { satellite }: { satellite: SatelliteModel }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Adding satellite:  ${JSON.stringify(satellite)}.` })
            return getConfiguration().then(config => {
                return new CatalogSatelliteApi(config).createSatelliteModel(satellite.name, satellite.details, satellite.power, satellite.supportedCoverages, satellite.isActive, satellite.isSpaceInspire, satellite.configurationType, satellite.maxGatewayCount, satellite.useReflector, satellite.picture, satellite.logo)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Add satellite: ${JSON.stringify(satellite)} created successfully.`
                })
                displayMessage({ message: 'Satellite created successfully.', type: ERROR_TYPES.SUCCESS })
                commit(ADD_SATELLITE, { satellite })
                return (data)
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while adding satellite.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
        async [SATELLITE_UPDATE]({ commit }, { satellite, satellitePicture, satelliteLogo }: { satellite: SatelliteModel, satellitePicture: string, satelliteLogo: string }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Update satellite:  ${JSON.stringify(satellite)}.` })
            return getConfiguration().then(config => {
                return new CatalogSatelliteApi(config).updateSatelliteModel(satellite.id, satellite.name, satellite.details, satellite.power, satellite.supportedCoverages, satellite.isActive, satellite.isSpaceInspire, satellite.configurationType, satellite.maxGatewayCount, satellite.useReflector,  satellitePicture, satelliteLogo)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Update satellite: ${JSON.stringify(satellite)} updated successfully.`
                })
                displayMessage({ message: 'Satellite updated successfully.', type: ERROR_TYPES.SUCCESS })
                commit(EDIT_SATELLITE, { satellite })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while updating satellite.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
        async [SATELLITE_UPDATE_INDEX]({ commit }, { satelliteId, index }: { satelliteId: number, index: number }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Update index (new index: ${index}) of satellite: ${satelliteId}.` })
            console.log(`New index ${index} for satellite ${satelliteId}`)
            return getConfiguration().then(config => {
                return new CatalogSatelliteApi(config).updateSatelliteModelIndex(satelliteId, index)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Update satellite index: satellite ${satelliteId} index (new index: ${index}) updated successfully.`
                })
                displayMessage({ message: 'Satellites updated successfully.', type: ERROR_TYPES.SUCCESS })
                commit(SET_SATELLITES, { satellites: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while updating satellites.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
        [SATELLITE_DELETE]({ commit }, { satelliteId, isActive }: { satelliteId: number, isActive: boolean }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Deleting satellite by id: ${satelliteId}. ${ isActive ? 'Logical deletion.' : 'Physical deletion.' }` })
            return getConfiguration().then(config => {
                return new CatalogSatelliteApi(config).deleteSatelliteModel(satelliteId, isActive)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Satellite deletion: ${satelliteId} deleted successfully. ${ isActive ? 'Logical deletion.' : 'Physical deletion.' }`
                })
                displayMessage({ message: `Satellite ${ isActive ? 'deactivated' : 'deleted' } successfully.`, type: ERROR_TYPES.SUCCESS })
                commit(SET_SATELLITES, { satellites: data })
            }).catch(({ response }) => {
                displayMessage({ message: `Error while ${ isActive ? 'deactivating' : 'deleting' } satellite.`, type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
		[GATEWAYS_FETCH]({ commit }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Fetching all gateways.` })
            return getConfiguration().then(config => {
                return new CatalogGatewayApi(config).getAllGatewayModels()
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Gateways: ${data.length} returned.`
                })
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Gateways: ${JSON.stringify(data)} returned successfully.`
                })
                commit(SET_GATEWAYS, { gateways: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while fetching gateways.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
        [GATEWAY_FETCH]({ commit }, { gatewayId }: { gatewayId: number }) {
            console.log("Getting gateway with id " + gatewayId)
            return getConfiguration().then(config => {
                return new CatalogGatewayApi(config).getGatewayModelById(gatewayId)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Gateway: ${JSON.stringify(data)} returned successfully.`
                })
                commit(SET_GATEWAY, { gateway: data })
                return data
            }).catch(({ response }) => {
                handleApiFetchError(response)
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
        async [GATEWAY_CREATE]({ commit }, { gateway }: { gateway: GatewayModel }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Adding gateway:  ${JSON.stringify(gateway)}.` })
            return getConfiguration().then(config => {
                return new CatalogGatewayApi(config).createGatewayModel(gateway.name, gateway.details, gateway.config, gateway.isActive, gateway.image)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Add gateway: ${JSON.stringify(gateway)} created successfully.`
                })
                displayMessage({ message: 'Gateway created successfully.', type: ERROR_TYPES.SUCCESS })
                commit(ADD_GATEWAY, { gateway })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while adding gateway.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
        async [GATEWAY_UPDATE]({ commit }, { gateway, gatewayImage }: { gateway: GatewayModel, gatewayImage: string }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Update gateway:  ${JSON.stringify(gateway)}.` })
            return getConfiguration().then(config => {
                return new CatalogGatewayApi(config).updateGatewayModel(gateway.id, gateway.name, gateway.details, gateway.config, gateway.isActive, gatewayImage)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Update gateway: ${JSON.stringify(gateway)} updated successfully.`
                })
                displayMessage({ message: 'Gateway updated successfully.', type: ERROR_TYPES.SUCCESS })
                commit(EDIT_GATEWAY, { gateway })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while updating gateway.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
		async [GATEWAY_UPDATE_INDEX]({ commit }, { gatewayId, index }: { gatewayId: number, index: number }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Update index (new index: ${index}) of gateway: ${gatewayId}.` })
            console.log(`New index ${index} for gateway ${gatewayId}`)
            return getConfiguration().then(config => {
                return new CatalogGatewayApi(config).updateGatewayModelIndex(gatewayId, index)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Update gateway index: gateway ${gatewayId} index (new index: ${index}) updated successfully.`
                })
                displayMessage({ message: 'Gateways updated successfully.', type: ERROR_TYPES.SUCCESS })
                commit(SET_GATEWAYS, { gateways: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while updating gateways.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
        [GATEWAY_DELETE]({ commit }, { gatewayId, isActive }: { gatewayId: number, isActive: boolean }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Deleting gateway by id: ${gatewayId}. ${ isActive ? 'Logical deletion.' : 'Physical deletion.' }` })
            return getConfiguration().then(config => {
                return new CatalogGatewayApi(config).deleteGatewayModel(gatewayId, isActive)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Gateway deletion: ${gatewayId} deleted successfully. ${ isActive ? 'Logical deletion.' : 'Physical deletion.' }`
                })
                displayMessage({ message: `Gateway ${ isActive ? 'deactivated' : 'deleted' } successfully.`, type: ERROR_TYPES.SUCCESS })
                commit(SET_GATEWAYS, { gateways: data })
            }).catch(({ response }) => {
                displayMessage({ message: `Error while ${ isActive ? 'deactivating' : 'deleting' } gateway.`, type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
    },
    mutations: {
        [SET_SATELLITE](state, { satellite }: { satellite: SatelliteModel }) {
            state.satellite = satellite
        },
        [SET_SATELLITES](state, { satellites }: { satellites: Array<SatelliteModel> }) {
            state.satellites = satellites
        },
        [ADD_SATELLITE](state, { satellite }: { satellite: SatelliteModel }) {
            state.satellites.push(satellite)
            state.satellite = satellite
        },
        [EDIT_SATELLITE](state, { satellite }: { satellite: SatelliteModel }) {
            replaceSatellite({ state, satellite })
            state.satellite = satellite
        },
        [REMOVE_SATELLITE](state, { id }: { id: number }) {
            const indexToRemove = _.findIndex(state.satellites, satellite => satellite.id == id)
            Vue.delete(state.satellites, indexToRemove)
        },
		[SET_GATEWAY](state, { gateway }: { gateway: GatewayModel }) {
            state.gateway = gateway
        },
        [SET_GATEWAYS](state, { gateways }: { gateways: Array<GatewayModel> }) {
            state.gateways = gateways
        },
        [ADD_GATEWAY](state, { gateway }: { gateway: GatewayModel }) {
            state.gateways.push(gateway)
            state.gateway = gateway
        },
        [EDIT_GATEWAY](state, { gateway }: { gateway: GatewayModel }) {
            replaceGateway({ state, gateway })
            state.gateway = gateway
        },
        [REMOVE_GATEWAY](state, { id }: { id: number }) {
            const indexToRemove = _.findIndex(state.gateways, gateway => gateway.id == id)
            Vue.delete(state.gateways, indexToRemove)
		}
    }
}
