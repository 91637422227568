// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/icon_setting.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "h6 {\n  color: #242a75;\n  font-weight: 100;\n}\n.h2settings {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: 31px 35px;\n  background-position: left center;\n  font-size: 1.8rem;\n  padding-left: 40px;\n  height: 41px;\n  margin-bottom: 25px;\n}\n.bloc_cta_style_02 {\n  margin-top: 0px !important;\n}\n.bloc_cta_style_02 .btn_secondary .libelle {\n  padding-left: 5px;\n}\n.bloc_cta_style_02 .btn_primary .libelle {\n  padding-left: 5px;\n}\n.bloc_cta_style_02 .li {\n  border-bottom: 0px;\n}\n.setting_title {\n  font-size: 1.2em;\n  color: #242a75;\n  font-weight: 100;\n}\n.htmlContent_text_area {\n  width: 100%;\n  min-height: 150px;\n  margin-top: 10px;\n  margin-bottom: 10px;\n  font-size: 0.8em;\n  resize: vertical;\n  border: 0px;\n}\n.htmlContent_text_area:focus {\n  outline: none;\n}", ""]);
// Exports
module.exports = exports;
