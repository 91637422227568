import {
    GET_PROJECTS,
    GET_PROJECT
} from "../aliases/getters.type"

import {
    PROJECTS_FETCH,
    PROJECT_FETCH,
    PROJECT_CREATE,
    PROJECT_UPDATE,
    PROJECT_UPLOAD_KML,
    PROJECT_DELETE
} from "../aliases/actions.type"

import {
    SET_PROJECTS,
    SET_PROJECT,
    ADD_PROJECT,
    EDIT_PROJECT,
    REMOVE_PROJECT
} from "../aliases/mutations.type"

import {
    CustomerApi,
    ProjectApi
} from '@/core'

import {
    // ProjectExtended, 
    Project, UploadProjectKml
} from '@/core/models'

import {
    getConfiguration
} from '@/core/utils/config.util'

import {
    log,
    LOG_TYPES
} from '@/core/middlewares/Logger'

import {
    displayMessage,
    ERROR_TYPES,
    handleApiFetchError
} from '@/core/middlewares/error'

import _ from 'lodash'

import Vue from 'vue'

const namespaced = true

const replaceProject = ({ state, project }) => {
    const index = _.findIndex(state.projects, project => project.id === state.project.id)
    if (index >= 0) {
        state.projects[index] = project || state.project
    }
}

export default {
    namespaced,
    state: {
        projects: [],
        project: {}
    },

    getters: {
        [GET_PROJECTS]: (state) => {
            return state.projects
        },
        [GET_PROJECT]: (state) => {
            return state.project
        }
    },

    actions: {
        [PROJECTS_FETCH]({ commit }, { customerId }: { customerId: number }) {
            console.log("Getting all projects for " + customerId)
            return getConfiguration().then(config => {
                return new ProjectApi(config).getProjectListByCustomerId(customerId)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Projects: ${JSON.stringify(data)} returned successfully.`
                })
                commit(SET_PROJECTS, { projects: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while fetching projects.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
        [PROJECT_FETCH]({ commit }, { projectId }: { projectId: number }) {
            console.log("Getting project with id " + projectId)
            return getConfiguration().then(config => {
                return new ProjectApi(config).getProjectById(projectId)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Project: ${JSON.stringify(data)} returned successfully.`
                })
                commit(SET_PROJECT, { project: data })
                return data
            }).catch(({ response }) => {
                handleApiFetchError(response)
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
        async [PROJECT_CREATE]({ commit }, { project }: { project: Project }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Adding next project:  ${JSON.stringify(project)}.` })
            return getConfiguration().then(config => {
                return new ProjectApi(config).newProject(project.idCustomer, project)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `ProjectAdd: ${JSON.stringify(project)} created successfully.`
                })
                displayMessage({ message: 'Project added successfully.', type: ERROR_TYPES.SUCCESS })
                commit(ADD_PROJECT, { project })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while adding project.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
        async [PROJECT_UPDATE]({ commit }, { project }: { project: Project }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Adding next project:  ${JSON.stringify(project)}.` })
            return getConfiguration().then(config => {
                return new ProjectApi(config).updateProjectById(project.id, project)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `ProjectUpdate: ${JSON.stringify(project)} updated successfully.`
                })
                displayMessage({ message: 'Project updated successfully.', type: ERROR_TYPES.SUCCESS })
                commit(EDIT_PROJECT, { project })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while updating project.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
        [PROJECT_DELETE]({ commit }, { projectId }: { projectId: number }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Deleting project by id: ${projectId}.` })
            return getConfiguration().then(config => {
                return new ProjectApi(config).deleteProjectById(projectId)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Project deletion: ${projectId} deleted successfully.`
                })
                displayMessage({ message: 'Project deleted successfully.', type: ERROR_TYPES.SUCCESS })
                commit(REMOVE_PROJECT, { id: projectId })
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while deleting project.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
        async [PROJECT_UPLOAD_KML]({ commit }, { projectId, uploadProjectKml }: { projectId: number, uploadProjectKml: UploadProjectKml }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Uploading new KML: ${JSON.stringify(uploadProjectKml)} for project with id ${projectId}.` })
            return getConfiguration().then(config => {
                return new ProjectApi(config).uploadNewKmlForProject(projectId, uploadProjectKml.customKml)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `UploadKmlForProject: ${JSON.stringify(uploadProjectKml)} uploaded successfully.`
                })
                displayMessage({ message: 'KML file uploaded successfully.', type: ERROR_TYPES.SUCCESS })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while uploading KML file.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        }
    },

    mutations: {
        [SET_PROJECTS](state, { projects }: { projects: Array<Project> }) {
            state.projects = projects
        },
        [SET_PROJECT](state, { project }: { project: Project }) {
            state.project = project
        },
        [ADD_PROJECT](state, { project }: { project: Project }) {
            state.projects.push(project)
            state.project = project
        },
        [EDIT_PROJECT](state, { project }: { project: Project }) {
            replaceProject({ state, project })
            state.project = project
        },
        [REMOVE_PROJECT](state, { id }: { id: number }) {
            const indexToRemove = _.findIndex(state.projects, project => project.id == id)
            Vue.delete(state.projects, indexToRemove)
        }
    }
}
