var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DeleteModal", {
        attrs: {
          id: "delete-component",
          source: "color",
          updating: false,
          nameProperty: "color",
        },
      }),
      _c("h2", { staticClass: "h2colors" }, [_vm._v("App colors")]),
      _c("div", { staticClass: "bloc_content_color" }, [
        _c(
          "ul",
          [
            _c(
              "draggable",
              {
                attrs: { list: _vm.getMissionsColors, draggable: ".draggable" },
                on: { change: _vm.updateIndex },
              },
              [
                _vm._l(_vm.getMissionsColors, function (missionColor, index) {
                  return _c("li", { staticClass: "draggable" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-1" }, [
                        _c("span", { staticClass: "icon_drag" }),
                      ]),
                      _c("div", { staticClass: "col-1" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: missionColor.color,
                              expression: "missionColor.color",
                            },
                          ],
                          staticClass: "color_rect",
                          style: {
                            background: missionColor.color,
                            border: "1px solid" + missionColor.color,
                          },
                          attrs: { type: "color" },
                          domProps: { value: missionColor.color },
                          on: {
                            change: function ($event) {
                              return _vm.updateColor(missionColor, index)
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                missionColor,
                                "color",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "align_left col-5" }, [
                        _c("div", { staticClass: "bloc_content_li" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: missionColor.color,
                                expression: "missionColor.color",
                              },
                            ],
                            staticClass: "input_color_mission",
                            attrs: { type: "text" },
                            domProps: { value: missionColor.color },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.updateColor(missionColor, index)
                              },
                              input: [
                                function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    missionColor,
                                    "color",
                                    $event.target.value
                                  )
                                },
                                _vm.$v.getMissionsColors.$each[index].$touch,
                              ],
                            },
                          }),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-5 align_right" }, [
                        _c("ul", { staticClass: "ul_cta flex" }, [
                          _c("li", [
                            _c("a", { staticClass: "cta_02" }, [
                              _c("span", {
                                staticClass: "icon_bin",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDeletion(missionColor)
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-2" }),
                      _vm.$v.getMissionsColors.$each[index].$dirty &&
                      !_vm.$v.getMissionsColors.$each[index].isHexColor
                        ? _c("div", { staticClass: "color_error" }, [
                            _vm._v("Please, enter a correct hexadecimal value"),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                }),
                _c("li", { attrs: { slot: "footer" }, slot: "footer" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-1" }),
                    _c("div", { staticClass: "col-1" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newColor.color,
                            expression: "newColor.color",
                          },
                        ],
                        staticClass: "color_rect",
                        style: [
                          _vm.newColor.color
                            ? {
                                background: _vm.newColor.color,
                                border: "1px solid" + _vm.newColor.color,
                              }
                            : {
                                background: "black",
                                border: "1px solid black",
                              },
                        ],
                        attrs: { type: "color" },
                        domProps: { value: _vm.newColor.color },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.newColor, "color", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "align_left col-5" }, [
                      _c("div", { staticClass: "bloc_content_li" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newColor.color,
                              expression: "newColor.color",
                            },
                          ],
                          staticClass: "input_color_mission",
                          attrs: { type: "text", placeholder: "Add new color" },
                          domProps: { value: _vm.newColor.color },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.newColor,
                                  "color",
                                  $event.target.value
                                )
                              },
                              _vm.$v.newColor.$touch,
                            ],
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.addColor(_vm.newColor)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-5 align_right" }, [
                      _c("ul", { staticClass: "ul_cta flex" }, [
                        _c("li", [
                          _c("a", { staticClass: "cta_02" }, [
                            _c(
                              "span",
                              {
                                staticClass: "iconaddcolor",
                                on: {
                                  click: function ($event) {
                                    return _vm.addColor(_vm.newColor)
                                  },
                                },
                              },
                              [_c("span", { staticClass: "icon_plus" })]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-2" }),
                    _vm.$v.newColor.$dirty && !_vm.$v.newColor.isHexColor
                      ? _c("div", { staticClass: "color_error" }, [
                          _vm._v("Please, enter a correct hexadecimal value"),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }