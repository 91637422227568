import Vue from "vue"
import SaveCancelButtons from '@/components/SaveCancelButtons.vue'
import { UTIL_TYPE } from '@/store/types';
import { GET_ITEM_TO_DELETE } from '@/store/aliases/getters.type';
import { mapGetters } from 'vuex';

export default Vue.extend({
    components: {
        SaveCancelButtons
    },
    props: ["source", "updating", "nameProperty"],
    computed: {
        ...mapGetters({
            getItemToDelete: `${UTIL_TYPE}/${GET_ITEM_TO_DELETE}`
        }),
        getNameToDisplay() {
            return this.nameProperty ? this.getItemToDelete[this.nameProperty] : this.getItemToDelete.name
        }
    },
    methods: {
        onCancel() {
            this.$bvModal.hide('delete-component')
        },
        onSubmit() {
            this.$parent.deleteComponent(this.getItemToDelete)
            this.$bvModal.hide('delete-component')
        }
    }
});