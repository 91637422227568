////////// UTIL //////////
export const SET_ITEM_TO_DELETE = 'setItemToDelete'

////////// PERSONA //////////
export const SET_PERSONAS = 'setPersonas'
export const SET_PERSONA = 'setPersona'
export const ADD_PERSONA = 'addPersona'
export const EDIT_PERSONA = 'editPersona'
export const REMOVE_PERSONA = 'removePersona'

////////// OFFER //////////
export const SET_OFFERS = 'setOffers'
export const SET_OFFER = 'setOffer'
export const ADD_OFFER = 'addOffer'
export const EDIT_OFFER = 'editOffer'
export const REMOVE_OFFER = 'removeOffer'

////////// CUSTOMER //////////
export const SET_CUSTOMERS = 'setCustomers'
export const SET_CUSTOMER = 'setCustomer'
export const ADD_CUSTOMER = 'addCustomer'
export const EDIT_CUSTOMER = 'editCustomer'
export const REMOVE_CUSTOMER = 'removeCustomer'

////////// SETTINGS //////////
export const SET_LEGAL_NOTICE = "setLegalNotice"
export const SET_COOKIES = "setCookiesAndGDPR"
export const SET_CONDITIONS = "setConditions"
export const SET_ABOUT = "setAbout"
export const SET_BEAM_CUSTOMISATION = "setBeamCustomisation"

////////// PROJECT //////////
export const SET_PROJECTS = 'setProjects'
export const SET_PROJECT = 'setProject'
export const ADD_PROJECT = 'addProject'
export const EDIT_PROJECT = 'editProject'
export const REMOVE_PROJECT = 'removeProject'

////////// PROJECT EXPORT //////////
export const SET_PROJECT_EXPORTS = 'setProjectExports'

////////// CATALOG //////////
export const SET_SATELLITES = 'setSatellites'
export const SET_SATELLITE = 'setSatellite'
export const ADD_SATELLITE = 'addSatellite'
export const EDIT_SATELLITE = 'editSatellite'
export const REMOVE_SATELLITE = 'removeSatellite'
export const SET_GATEWAYS = 'setGateways'
export const SET_GATEWAY = 'setGateway'
export const ADD_GATEWAY = 'addGateway'
export const EDIT_GATEWAY = 'editGateway'
export const REMOVE_GATEWAY = 'removeGateway'

////////// REFERENTIAL //////////
export const SET_MISSION_COLORS = 'setMissionColors'
export const ADD_MISSION_COLOR = 'addMissionColor'
export const EDIT_MISSION_COLOR = 'editMissionColor'
