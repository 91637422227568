import _ from 'lodash'

import {
    ProjectExportApi,
    ReferentialApi,
    ReferentialMissionColorApi
} from '@/core'

import {
    log,
    LOG_TYPES
} from '@/core/middlewares/Logger'

import {
    displayMessage,
    ERROR_TYPES
} from '@/core/middlewares/error'

import {
    getConfiguration
} from '@/core/utils/config.util'

import {
    MISSION_COLORS_FETCH,
    MISSION_COLOR_CREATE,
    MISSION_COLOR_DELETE,
    MISSION_COLOR_UPDATE,
    MISSION_COLOR_UPDATE_INDEX,
    REFERENTIAL_UPLOAD
} from '../aliases/actions.type'

import {
    CreateOrUpdateMissionColor,
    UploadReferentialData
} from '@/core/models'

import Vue from 'vue'
import {ADD_MISSION_COLOR, EDIT_MISSION_COLOR, SET_MISSION_COLORS, SET_PROJECT_EXPORTS} from '../aliases/mutations.type'
import { MissionColor } from '@/core/models/mission-color'
import { GET_MISSION_COLORS } from '../aliases/getters.type'


const namespaced = true

const replaceMissionColor = ({ state, missionColor }) => {
    const index = _.findIndex(state.missionColors, missionColor => missionColor.id === state.missionColor.id)
    if (index >= 0) {
        state.missionColors[index] = missionColor || state.missionColor
    }
}

export default {
    namespaced,
    state: {
        missionColors: [],
        missionColor: {}
    },

    getters: {
        [GET_MISSION_COLORS]: (state) => {
            return state.missionColors
        }
    },

    actions: {
        async [REFERENTIAL_UPLOAD]({ commit }, { referential }: { referential: UploadReferentialData }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Adding new referential(s):  ${JSON.stringify(referential)}.` })
            return getConfiguration().then(config => {
                return new ReferentialApi(config).uploadReferentialData(referential.userTerminal, referential.userFrequency, referential.service, referential.gatewayFrequency, referential.zooming, referential.modCods, referential.waveforms)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `ReferentialsUpload: ${JSON.stringify(referential)} uploaded successfully.`
                })
                displayMessage({ message: 'Referential(s) uploaded successfully.', type: ERROR_TYPES.SUCCESS })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while uploading referential(s).', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return response.data
            })
        },
        [MISSION_COLORS_FETCH]({ commit }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Fetching all missions colors.` })
            return getConfiguration().then(config => {
                return new ReferentialMissionColorApi(config).getAllMissionColors()
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Mission colors: ${data.length} returned.`
                })
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Mission colors: ${JSON.stringify(data)} returned successfully.`
                })
                commit(SET_MISSION_COLORS, { missionColors: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while fetching missions colors.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
        async [MISSION_COLOR_CREATE]({ commit }, { missionColor }: { missionColor: MissionColor }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Adding mission color:  ${JSON.stringify(missionColor)}.` })
            return getConfiguration().then(config => {
                return new ReferentialMissionColorApi(config).createMissionColor(missionColor)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Add mission color: ${JSON.stringify(missionColor)} created successfully.`
                })
                displayMessage({ message: 'Color created successfully.', type: ERROR_TYPES.SUCCESS })
                commit(ADD_MISSION_COLOR, { missionColor: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while adding mission color.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
        async [MISSION_COLOR_UPDATE]({ commit }, { missionColor }: { missionColor: MissionColor }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Update mission color:  ${JSON.stringify(missionColor)}.` })
            return getConfiguration().then(config => {
                return new ReferentialMissionColorApi(config).updateMissionColorById(missionColor.id, { color: missionColor.color })
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Update mission color: ${JSON.stringify(missionColor)} updated successfully.`
                })
                displayMessage({ message: 'Mission color updated successfully.', type: ERROR_TYPES.SUCCESS })
                commit(EDIT_MISSION_COLOR, { missionColor })
                return (data)
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while updating mission color.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
        async [MISSION_COLOR_UPDATE_INDEX]({ commit }, { missionColorId, index }: { missionColorId: number, index: number }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Update index (new index: ${index}) of mission color: ${missionColorId}.` })
            console.log(`New index ${index} for mission color ${missionColorId}`)
            return getConfiguration().then(config => {
                return new ReferentialMissionColorApi(config).updateMissionColorIndex(missionColorId, index)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Update mission color index: mission color ${missionColorId} index (new index: ${index}) updated successfully.`
                })
                displayMessage({ message: 'Missions colors updated successfully.', type: ERROR_TYPES.SUCCESS })
                commit(SET_MISSION_COLORS, { missionColors: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while updating missions colors.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
        [MISSION_COLOR_DELETE]({ commit }, { missionColorId }: { missionColorId: number }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Deleting mission color by id: ${missionColorId}.` })
            return getConfiguration().then(config => {
                return new ReferentialMissionColorApi(config).deleteMissionColorById(missionColorId)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Mission color deletion: ${missionColorId} deleted successfully.`
                })
                displayMessage({ message: `Mission color deleted successfully.`, type: ERROR_TYPES.SUCCESS })
                commit(SET_MISSION_COLORS, { missionColors: data })
            }).catch(({ response }) => {
                displayMessage({ message: `Error while deleting satellite.`, type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
    },

    mutations: {
        [SET_MISSION_COLORS](state, { missionColors }: { missionColors: Array<MissionColor> }) {
            state.missionColors = missionColors
        },
        [ADD_MISSION_COLOR](state, { missionColor }: { missionColor: MissionColor }) {
            state.missionColors.push(missionColor)
            state.missionColor = missionColor
        },
        [EDIT_MISSION_COLOR](state, { missionColor }: { missionColor: MissionColor }) {
            replaceMissionColor({ state, missionColor })
            state.missionColor = missionColor
        },
    }

}
