// tslint:disable
/**
 * SkyT
 * SkyT contract
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: email@email.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiError } from '../model';
// @ts-ignore
import { Export } from '../model';
/**
 * ProjectExportApi - axios parameter creator
 * @export
 */
export const ProjectExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get all exports for a project
         * @summary get all exports for a project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectExport: async (projectId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getAllProjectExport.');
            }
            const localVarPath = `/api/v1/projects/{projectId}/exports`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a new export for a project and a configuration
         * @summary create a new export for a project and a configuration
         * @param {number} projectId 
         * @param {number} configurationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newProjectExport: async (projectId: number, configurationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling newProjectExport.');
            }
            // verify required parameter 'configurationId' is not null or undefined
            if (configurationId === null || configurationId === undefined) {
                throw new RequiredError('configurationId','Required parameter configurationId was null or undefined when calling newProjectExport.');
            }
            const localVarPath = `/api/v1/projects/{projectId}/configurations/{configurationId}/exports`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"configurationId"}}`, encodeURIComponent(String(configurationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectExportApi - functional programming interface
 * @export
 */
export const ProjectExportApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get all exports for a project
         * @summary get all exports for a project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProjectExport(projectId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Export>>> {
            const localVarAxiosArgs = await ProjectExportApiAxiosParamCreator(configuration).getAllProjectExport(projectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * create a new export for a project and a configuration
         * @summary create a new export for a project and a configuration
         * @param {number} projectId 
         * @param {number} configurationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newProjectExport(projectId: number, configurationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Export>> {
            const localVarAxiosArgs = await ProjectExportApiAxiosParamCreator(configuration).newProjectExport(projectId, configurationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProjectExportApi - factory interface
 * @export
 */
export const ProjectExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * get all exports for a project
         * @summary get all exports for a project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProjectExport(projectId: number, options?: any): AxiosPromise<Array<Export>> {
            return ProjectExportApiFp(configuration).getAllProjectExport(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * create a new export for a project and a configuration
         * @summary create a new export for a project and a configuration
         * @param {number} projectId 
         * @param {number} configurationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newProjectExport(projectId: number, configurationId: number, options?: any): AxiosPromise<Export> {
            return ProjectExportApiFp(configuration).newProjectExport(projectId, configurationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectExportApi - object-oriented interface
 * @export
 * @class ProjectExportApi
 * @extends {BaseAPI}
 */
export class ProjectExportApi extends BaseAPI {
    /**
     * get all exports for a project
     * @summary get all exports for a project
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectExportApi
     */
    public getAllProjectExport(projectId: number, options?: any) {
        return ProjectExportApiFp(this.configuration).getAllProjectExport(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create a new export for a project and a configuration
     * @summary create a new export for a project and a configuration
     * @param {number} projectId 
     * @param {number} configurationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectExportApi
     */
    public newProjectExport(projectId: number, configurationId: number, options?: any) {
        return ProjectExportApiFp(this.configuration).newProjectExport(projectId, configurationId, options).then((request) => request(this.axios, this.basePath));
    }

}
