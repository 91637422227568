import {mapActions, mapGetters, mapMutations} from 'vuex'
import Vue from "vue"
import {GET_GATEWAY} from '@/store/aliases/getters.type'
import {CATALOG_TYPE} from '@/store/types'
import {GATEWAY_CREATE, GATEWAY_FETCH, GATEWAY_UPDATE} from '@/store/aliases/actions.type'
import {maxValue, minValue, required} from 'vuelidate/lib/validators'
import {displayMessage, ERROR_TYPES} from '@/core/middlewares/error'
import {SET_GATEWAY} from '@/store/aliases/mutations.type'
import {GatewayModel} from '@/core/models'

export default Vue.extend({
    props: {
        gatewayId: String
    },
    data() {
        return {
            isImageUpdated: false
        }
    },
    validations: {
        getGateway: {
            name: {
                required
            },
            image: {
                required
            },
            details: {
                required
            }
        },
        getConfig: {
            antennaDiameter: {
                minValue: minValue(0.1)
            },
            efficiency: {
                minValue: minValue(0),
                maxValue: maxValue(100)
            },
            pointingErrorAngle: {
                minValue: minValue(0.001)
            },
            saturatingEirp: {
                minValue: minValue(1)
            },
            referenceTxFrequency: {
                minValue: minValue(1)
            },
            ulpcRange: {
                minValue: minValue(0.1)
            },
            obo: {
                minValue: minValue(0.1)
            },
            csIm: {
                minValue: minValue(1)
            },
            gst: {
                minValue: minValue(0.1)
            },
            referenceRxFrequency: {
                minValue: minValue(1)
            }
        }
    },
    mounted() {
        if (this.gatewayId) {
            this.fetchGateway({gatewayId: this.gatewayId})
        } else {
            this.setGateway({
                gateway: {
                    image: "",
                    config: "",
                    isActive: true
                } as GatewayModel
            })
        }
    },
    computed: {
        ...mapGetters({
            getGateway: `${CATALOG_TYPE}/${GET_GATEWAY}`
        }),
        getConfig() {
            return this.getGateway.config ? JSON.parse(this.getGateway.config) : {}
        }
    },
    methods: {
        ...mapActions({
            fetchGateway: `${CATALOG_TYPE}/${GATEWAY_FETCH}`,
            createGateway: `${CATALOG_TYPE}/${GATEWAY_CREATE}`,
            updateGateway: `${CATALOG_TYPE}/${GATEWAY_UPDATE}`
        }),
        ...mapMutations({
            setGateway: `${CATALOG_TYPE}/${SET_GATEWAY}`
        }),
        editConfig() {
            this.getGateway.config = JSON.stringify(this.getConfig)
        },
        async createOrUpdateGateway() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                displayMessage({message: 'Please, fix the errors before saving.', type: ERROR_TYPES.ERROR})
            } else {
                let result
                if (this.getGateway.id) {
                    result = this.updateGateway({gateway: this.getGateway, gatewayImage: this.isImageUpdated ? this.getGateway.image : undefined})
                } else {
                    result = this.createGateway({gateway: this.getGateway})
                }

                if (result) {
                    this.$router.push({name: 'ManageCatalog', params: {selectedIndexProp: 1}})
                }
            }
        },
        handleImageFile(event) {
            console.log(this.getGateway.image)
            let file = event.target.files[0]
            if (file) {
                this.$v.getGateway.image.$touch()
                this.getGateway.image = file
                this.isImageUpdated = true
                this.$refs.image.innerText = file.name
            }
        },
    }
})
