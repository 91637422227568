export class PromisePublisher<T> {

    private hasResolved = false

    private resolvedError: any | null
    private resolvedValue: T | null

    private arrayOfPromise: Observable<T>[] = []

    async subscribe(): Promise<T | null> {
        return new Promise<T | null>((resolve, reject) => {
            if(this.hasResolved) {
                if (this.resolvedError) {
                    reject(this.resolvedError)
                } else {
                    resolve(this.resolvedValue)
                }
            } else {
                this.arrayOfPromise.push({
                    resolveFunction: resolve,
                    rejectFunction: reject
                })
            }
        })
    }

    resolveValue(value: T, error: any | null) {
        this.hasResolved = true
        if(error === null) {
            this.resolvedValue = value
            this.resolvedError = null
        } else {
            this.resolvedValue = null
            this.resolvedError = error
        }
        for(const observable of this.arrayOfPromise) {
            if(this.resolvedError) {
                observable.rejectFunction(this.resolvedError)
            } else {
                observable.resolveFunction(this.resolvedValue)
            }
        }
    }

}

export interface Observable<T> {
    resolveFunction: (value?: T | PromiseLike<T>) => void
    rejectFunction: (reject: (reason?: any) => void) => void
}
