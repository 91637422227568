var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", { staticClass: "h2settings" }, [_vm._v("App settings")]),
    _c("div", { staticClass: "row" }, [
      _vm._m(0),
      _c("div", { staticClass: "col-6 align_right" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 align_right" }, [
            _c("ul", { staticClass: "bloc_cta_style_02" }, [
              _c("li", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.isBeamCustomisation,
                      expression: "isBeamCustomisation",
                    },
                  ],
                  staticClass: "apple-switch",
                  attrs: { type: "checkbox", name: "chkActive" },
                  domProps: {
                    checked: Array.isArray(_vm.isBeamCustomisation)
                      ? _vm._i(_vm.isBeamCustomisation, null) > -1
                      : _vm.isBeamCustomisation,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.isBeamCustomisation,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.isBeamCustomisation = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.isBeamCustomisation = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.isBeamCustomisation = $$c
                        }
                      },
                      _vm.saveBeamCustomisation,
                    ],
                  },
                }),
              ]),
              _c("li", [
                _vm.isBeamCustomisation
                  ? _c("span", { staticClass: "libelle-centered" }, [
                      _vm._v("Activated"),
                    ])
                  : _c("span", { staticClass: "libelle-centered" }, [
                      _vm._v("Deactivated"),
                    ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._m(1),
    _c("div", { staticClass: "row" }, [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.legalNoticeValue,
            expression: "legalNoticeValue",
          },
        ],
        staticClass: "htmlContent_text_area",
        domProps: { value: _vm.legalNoticeValue },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.legalNoticeValue = $event.target.value
          },
        },
      }),
    ]),
    _vm._m(2),
    _c("div", { staticClass: "row" }, [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.cookiesValue,
            expression: "cookiesValue",
          },
        ],
        staticClass: "htmlContent_text_area",
        domProps: { value: _vm.cookiesValue },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.cookiesValue = $event.target.value
          },
        },
      }),
    ]),
    _vm._m(3),
    _c("div", { staticClass: "row" }, [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.conditionValue,
            expression: "conditionValue",
          },
        ],
        staticClass: "htmlContent_text_area",
        domProps: { value: _vm.conditionValue },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.conditionValue = $event.target.value
          },
        },
      }),
    ]),
    _vm._m(4),
    _c("div", { staticClass: "row" }, [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.aboutValue,
            expression: "aboutValue",
          },
        ],
        staticClass: "htmlContent_text_area",
        domProps: { value: _vm.aboutValue },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.aboutValue = $event.target.value
          },
        },
      }),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 align_right" }, [
        _c(
          "button",
          { staticClass: "btn_primary", on: { click: _vm.saveHtmlElements } },
          [_c("span", { staticClass: "libelle" }, [_vm._v("Save")])]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-6" }, [
      _c("span", { staticClass: "setting_title" }, [
        _vm._v("Beam customisation"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("span", { staticClass: "setting_title" }, [_vm._v("Legal Notice")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("span", { staticClass: "setting_title" }, [
          _vm._v("Cookies and GDPR"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("span", { staticClass: "setting_title" }, [
          _vm._v("Conditions of uses"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("span", { staticClass: "setting_title" }, [_vm._v("About")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }