import Vue from "vue"
import {mapActions, mapGetters} from 'vuex'
import {SETTINGS_TYPE} from '@/store/types'
import {
    GET_ABOUT,
    GET_BEAM_CUSTOMISATION,
    GET_CONDITIONS,
    GET_COOKIES,
    GET_LEGAL_NOTICE
} from '@/store/aliases/getters.type'
import {
    ABOUT_FETCH, ABOUT_UPDATE,
    BEAM_CUSTOMISATION_FETCH,
    BEAM_CUSTOMISATION_UPDATE, CONDITIONS_FETCH, CONDITIONS_UPDATE,
    COOKIES_FETCH, COOKIES_UPDATE,
    LEGAL_NOTICE_FETCH,
    LEGAL_NOTICE_UPDATE
} from '@/store/aliases/actions.type'

export default Vue.extend({
    data() {
      return {
          legalNoticeValue: '',
          cookiesValue: '',
          conditionValue: '',
          aboutValue: '',
          isBeamCustomisation: false
      }
    },
    components: {
    },
    validations: {
    },
    mounted() {
        this.fetchLegalNotice().then(_ => {
            this.legalNoticeValue = this.getLegalNotice.html
        })
        this.fetchCookiesAndGDPR().then(_ => {
            this.cookiesValue = this.getCookiesAndGDPR.html
        })
        this.fetchConditionsOfUses().then(_ => {
            this.conditionValue = this.getConditionsOfUses.html
        })
        this.fetchAbout().then(_ => {
            this.aboutValue = this.getAbout.html
        })
        this.fetchBeamCustomisation().then(_ => {
            this.isBeamCustomisation = this.getBeamCustomisation.isEnabled
        })
    },
    computed: {
        ...mapGetters({
            getLegalNotice: `${SETTINGS_TYPE}/${GET_LEGAL_NOTICE}`,
            getCookiesAndGDPR: `${SETTINGS_TYPE}/${GET_COOKIES}`,
            getConditionsOfUses: `${SETTINGS_TYPE}/${GET_CONDITIONS}`,
            getAbout: `${SETTINGS_TYPE}/${GET_ABOUT}`,
            getBeamCustomisation: `${SETTINGS_TYPE}/${GET_BEAM_CUSTOMISATION}`
        })
    },
    methods: {
        ...mapActions({
            fetchLegalNotice: `${SETTINGS_TYPE}/${LEGAL_NOTICE_FETCH}`,
            fetchCookiesAndGDPR: `${SETTINGS_TYPE}/${COOKIES_FETCH}`,
            fetchConditionsOfUses: `${SETTINGS_TYPE}/${CONDITIONS_FETCH}`,
            fetchAbout: `${SETTINGS_TYPE}/${ABOUT_FETCH}`,
            fetchBeamCustomisation: `${SETTINGS_TYPE}/${BEAM_CUSTOMISATION_FETCH}`,
            updateLegalNotice: `${SETTINGS_TYPE}/${LEGAL_NOTICE_UPDATE}`,
            updateCookiesAndGDPR: `${SETTINGS_TYPE}/${COOKIES_UPDATE}`,
            updateConditionsOfUses: `${SETTINGS_TYPE}/${CONDITIONS_UPDATE}`,
            updateAbout: `${SETTINGS_TYPE}/${ABOUT_UPDATE}`,
            updateBeamCustomisation: `${SETTINGS_TYPE}/${BEAM_CUSTOMISATION_UPDATE}`
        }),
        saveHtmlElements() {
            this.updateLegalNotice({html: this.legalNoticeValue})
            this.updateCookiesAndGDPR({html: this.cookiesValue})
            this.updateConditionsOfUses({html: this.conditionValue})
            this.updateAbout({html: this.aboutValue})
        },
        saveBeamCustomisation() {
            this.updateBeamCustomisation({isEnabled: this.isBeamCustomisation})
        }

    }
})
