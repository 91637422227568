import Vue from "vue";
import SelectableLine from '@/components/SelectableLine.vue'
import ManageConfigurationFiles from '@/views/ManageConfigurationFiles.vue'
import ManageAreaOfInterestColor from '@/views/ManageAreaOfInterestColor.vue'
import AppSettings from "@/views/AppSettings.vue"

export default Vue.extend({
    components: {
        SelectableLine,
        ManageConfigurationFiles,
        ManageAreaOfInterestColor,
        AppSettings
    },
    data() {
        return {
            settings: settings as Setting[],
            selectedSettingIndex: undefined
        }
    },
    methods: {
        selectSetting(index) {
            this.selectedSettingIndex = index
        }
    }
})

export interface Setting {
    name: string
}

export const settings: Setting[] = [
    { name: 'Manage droplists' },
    { name: 'App colors'},
    { name: 'App settings'}
]

