var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c(
      "div",
      { staticClass: "bloc_header_fixed" },
      [
        _c(
          "router-link",
          {
            staticClass: "cta_back",
            attrs: {
              to: { name: "ManageCatalog", params: { selectedIndexProp: 0 } },
            },
          },
          [
            _c("span", { staticClass: "icon" }, [
              _c("span", { staticClass: "icon_back" }),
            ]),
            _c("span", { staticClass: "libelle" }, [_vm._v("Catalog")]),
          ]
        ),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("span", { staticClass: "title_bloc_header" }, [
              _vm._v(_vm._s(_vm.getSatellite.name)),
            ]),
          ]),
          _c("div", { staticClass: "col-6 align_right" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 align_right" }, [
                _c("ul", { staticClass: "bloc_cta_style_02" }, [
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.getSatellite.isActive,
                          expression: "getSatellite.isActive",
                        },
                      ],
                      staticClass: "apple-switch",
                      attrs: { type: "checkbox", name: "chkActive" },
                      domProps: {
                        checked: Array.isArray(_vm.getSatellite.isActive)
                          ? _vm._i(_vm.getSatellite.isActive, null) > -1
                          : _vm.getSatellite.isActive,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.getSatellite.isActive,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.getSatellite,
                                  "isActive",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.getSatellite,
                                  "isActive",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.getSatellite, "isActive", $$c)
                          }
                        },
                      },
                    }),
                  ]),
                  _vm._m(0),
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn_secondary click_pointer",
                          attrs: {
                            to: {
                              name: "ManageCatalog",
                              params: { selectedIndexProp: 0 },
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "icon" }, [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  version: "1.1",
                                  xmlns: "http://www.w3.org/2000/svg",
                                  "xmlns:xlink": "http://www.w3.org/1999/xlink",
                                  x: "0px",
                                  y: "0px",
                                  viewBox: "0 0 16 17",
                                  "xml:space": "preserve",
                                },
                              },
                              [
                                _c("path", {
                                  staticClass: "fill_color",
                                  attrs: {
                                    id: "Tracé_7652",
                                    d: "M9.7,8.5l5.9-5.9c0.3-0.3,0.3-0.9,0-1.2L15.1,1c-0.3-0.3-0.9-0.3-1.2,0L8,6.8L2.1,1 C1.8,0.6,1.3,0.6,0.9,1L0.4,1.5c-0.3,0.3-0.3,0.9,0,1.2l5.9,5.9l-5.9,5.9c-0.3,0.3-0.3,0.9,0,1.2l0.5,0.5c0.3,0.3,0.9,0.3,1.2,0 L8,10.3l5.9,5.9c0.2,0.2,0.4,0.3,0.6,0.3l0,0c0.2,0,0.4-0.1,0.6-0.3l0.5-0.5c0.3-0.3,0.3-0.9,0-1.2L9.7,8.5z",
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _c("span", { staticClass: "libelle" }, [
                            _vm._v("Cancel "),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "btn_primary",
                        on: { click: _vm.createOrUpdateSatellite },
                      },
                      [
                        _c("span", { staticClass: "icon" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                version: "1.1",
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                                x: "0px",
                                y: "0px",
                                viewBox: "0 0 24 23",
                                "xml:space": "preserve",
                              },
                            },
                            [
                              _c("path", {
                                staticClass: "fill_color",
                                attrs: {
                                  id: "Tracé_7638",
                                  d: "M20.6,22.7H3.4c-1.4,0-2.6-1.1-2.6-2.6V2.9c0-1.4,1.1-2.6,2.6-2.6h14.5 c0.9,0,1.8,0.4,2.5,1l1.9,1.9c0.7,0.7,1,1.5,1,2.5v14.5C23.2,21.5,22,22.7,20.6,22.7L20.6,22.7z M3.4,1.7c-0.6,0-1.2,0.5-1.2,1.2 v17.2c0,0.6,0.5,1.2,1.2,1.2h17.2c0.6,0,1.2-0.5,1.2-1.2V5.7c0-0.6-0.2-1.1-0.6-1.5l-1.9-1.9c-0.4-0.4-0.9-0.6-1.5-0.6H3.4z",
                                },
                              }),
                              _c("path", {
                                staticClass: "fill_color",
                                attrs: {
                                  id: "Tracé_7639",
                                  d: "M15,6.8H5.2c-0.4,0-0.7-0.3-0.7-0.7c0,0,0,0,0,0V1c0-0.4,0.3-0.7,0.7-0.7l0,0 c0.4,0,0.7,0.3,0.7,0.7c0,0,0,0,0,0v4.4h8.4V1c0-0.4,0.3-0.7,0.7-0.7l0,0c0.4,0,0.7,0.3,0.7,0.7v5.1C15.7,6.5,15.4,6.8,15,6.8 L15,6.8L15,6.8z",
                                },
                              }),
                              _c("path", {
                                staticClass: "fill_color",
                                attrs: {
                                  id: "Tracé_7640",
                                  d: "M12,18.5c-2.3,0-4.2-1.9-4.2-4.2c0-2.3,1.9-4.2,4.2-4.2c2.3,0,4.2,1.9,4.2,4.2 S14.3,18.5,12,18.5L12,18.5z M12,11.5c-1.5,0-2.8,1.3-2.8,2.8c0,1.5,1.3,2.8,2.8,2.8c1.5,0,2.8-1.3,2.8-2.8v0 C14.8,12.8,13.5,11.5,12,11.5z",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("span", { staticClass: "libelle" }, [
                          _vm._v("Save"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "bloc_template bloc_padding patch_margin_top_fixed" },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "bloc_form_style_02" }, [
              _vm._m(1),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.getSatellite.name,
                    expression: "getSatellite.name",
                  },
                ],
                attrs: { name: "name", type: "text" },
                domProps: { value: _vm.getSatellite.name },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.getSatellite, "name", $event.target.value)
                    },
                    _vm.$v.getSatellite.name.$touch,
                  ],
                },
              }),
              _vm.$v.getSatellite.name.$dirty &&
              !_vm.$v.getSatellite.name.required
                ? _c("div", { staticClass: "satellite_form_error" }, [
                    _vm._v("Name is required"),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm._m(2),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "bloc_form_style_02" }, [
              _vm._m(3),
              _c("label", { staticClass: "file" }, [
                _c("input", {
                  attrs: {
                    id: "file",
                    name: "logo",
                    accept: "image/png, image/jpeg, image/gif",
                    type: "file",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.handleFile($event, "logo")
                    },
                  },
                }),
                !_vm.getSatellite.logo
                  ? _c("span", { ref: "logo", staticClass: "file-custom" }, [
                      _vm._v("Choose file..."),
                    ])
                  : _c("span", { ref: "logo", staticClass: "file-custom" }, [
                      _vm._v("Logo existing"),
                    ]),
              ]),
              _vm.$v.getSatellite.logo.$dirty &&
              !_vm.$v.getSatellite.logo.required
                ? _c("div", { staticClass: "satellite_form_error" }, [
                    _vm._v("Logo is required"),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm._m(4),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "bloc_form_style_02" }, [
              _vm._m(5),
              _c("label", { staticClass: "file" }, [
                _c("input", {
                  attrs: {
                    id: "file",
                    name: "picture",
                    accept: "image/png, image/jpeg, image/gif",
                    type: "file",
                    "aria-label": "File browser example",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.handleFile($event, "picture")
                    },
                  },
                }),
                !_vm.getSatellite.picture
                  ? _c("span", { ref: "picture", staticClass: "file-custom" }, [
                      _vm._v("Choose file..."),
                    ])
                  : _c("span", { ref: "picture", staticClass: "file-custom" }, [
                      _vm._v("Picture existing"),
                    ]),
              ]),
              _vm.$v.getSatellite.picture.$dirty &&
              !_vm.$v.getSatellite.picture.required
                ? _c("div", { staticClass: "satellite_form_error" }, [
                    _vm._v("Satellite image is required"),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm._m(6),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "bloc_form_style_02" }, [
              _vm._m(7),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.getSatellite.details,
                    expression: "getSatellite.details",
                  },
                ],
                domProps: { value: _vm.getSatellite.details },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.getSatellite, "details", $event.target.value)
                    },
                    _vm.$v.getSatellite.details.$touch,
                  ],
                },
              }),
              _vm.$v.getSatellite.details.$dirty &&
              !_vm.$v.getSatellite.details.required
                ? _c("div", { staticClass: "satellite_form_error" }, [
                    _vm._v("Description is required"),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm._m(8),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "bloc_form_style_02" }, [
              _c("label", [_vm._v("SpaceInspire ")]),
              _c("ul", { staticClass: "bloc_cta_style_02" }, [
                _vm._m(9),
                _c("li", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.getSatellite.isSpaceInspire,
                        expression: "getSatellite.isSpaceInspire",
                      },
                    ],
                    staticClass: "apple-switch",
                    attrs: { type: "checkbox", name: "chkSpaceInspire" },
                    domProps: {
                      checked: Array.isArray(_vm.getSatellite.isSpaceInspire)
                        ? _vm._i(_vm.getSatellite.isSpaceInspire, null) > -1
                        : _vm.getSatellite.isSpaceInspire,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.getSatellite.isSpaceInspire,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.getSatellite,
                                "isSpaceInspire",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.getSatellite,
                                "isSpaceInspire",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.getSatellite, "isSpaceInspire", $$c)
                        }
                      },
                    },
                  }),
                ]),
                _c(
                  "span",
                  {
                    style: _vm.getSatellite.isSpaceInspire
                      ? "display:block"
                      : "display:none",
                  },
                  [
                    _vm._m(10),
                    _c("li", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.getSatellite.configurationType,
                            expression: "getSatellite.configurationType",
                          },
                        ],
                        staticClass: "apple-switch",
                        attrs: {
                          type: "checkbox",
                          name: "chkSpaceInspireType",
                          value: "1",
                        },
                        domProps: {
                          checked: Array.isArray(
                            _vm.getSatellite.configurationType
                          )
                            ? _vm._i(_vm.getSatellite.configurationType, "1") >
                              -1
                            : _vm.getSatellite.configurationType,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.getSatellite.configurationType,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "1",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.getSatellite,
                                    "configurationType",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.getSatellite,
                                    "configurationType",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.getSatellite,
                                "configurationType",
                                $$c
                              )
                            }
                          },
                        },
                      }),
                    ]),
                    _vm._m(11),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._m(12),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [
            _c("div", { staticClass: "bloc_form_style_02 hack_width_form" }, [
              _vm._m(13),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.getSatellite.power,
                    expression: "getSatellite.power",
                  },
                ],
                attrs: { type: "number", name: "power", min: "1" },
                domProps: { value: _vm.getSatellite.power },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.getSatellite, "power", $event.target.value)
                    },
                    _vm.$v.getSatellite.power.$touch,
                  ],
                },
              }),
              _vm.$v.getSatellite.power.$dirty &&
              !_vm.$v.getSatellite.power.required
                ? _c("div", { staticClass: "satellite_form_error" }, [
                    _vm._v("Power is required"),
                  ])
                : _vm._e(),
              _vm.$v.getSatellite.power.$dirty &&
              !_vm.$v.getSatellite.power.minValue
                ? _c("div", { staticClass: "satellite_form_error" }, [
                    _vm._v("Power minimum value is 1"),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "col-4" }, [
            _c("div", { staticClass: "bloc_form_style_02 hack_width_form" }, [
              _c("label", [_vm._v("Maximum Gateway count")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.getSatellite.maxGatewayCount,
                    expression: "getSatellite.maxGatewayCount",
                  },
                ],
                attrs: { type: "number", name: "gatewayCount", min: "0" },
                domProps: { value: _vm.getSatellite.maxGatewayCount },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.getSatellite,
                      "maxGatewayCount",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "col-4" }, [
            _c("div", { staticClass: "bloc_form_style_02" }, [
              _c("label", [_vm._v("Use reflector")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.getSatellite.useReflector,
                    expression: "getSatellite.useReflector",
                  },
                ],
                staticClass: "apple-switch",
                attrs: { type: "checkbox", name: "chkReflector", value: "1" },
                domProps: {
                  checked: Array.isArray(_vm.getSatellite.useReflector)
                    ? _vm._i(_vm.getSatellite.useReflector, "1") > -1
                    : _vm.getSatellite.useReflector,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.getSatellite.useReflector,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "1",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.getSatellite,
                            "useReflector",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.getSatellite,
                            "useReflector",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.getSatellite, "useReflector", $$c)
                    }
                  },
                },
              }),
            ]),
          ]),
        ]),
        _vm._m(14),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "bloc_form_style_02" }, [
              _vm._m(15),
              _c(
                "div",
                { staticClass: "btn-group-toggle btn-group btn-group-lg" },
                _vm._l(_vm.getCoverageTypes, function (coverageType, index) {
                  return _c(
                    "label",
                    {
                      staticClass: "btn btn-danger btn-lg",
                      class: { coverage_type_selected: coverageType.selected },
                      on: {
                        click: function ($event) {
                          return _vm.toggleCoverageTypeSelection(index)
                        },
                      },
                    },
                    [_vm._v(_vm._s(coverageType.name))]
                  )
                }),
                0
              ),
              _vm.$v.getSatellite.supportedCoverages.$dirty &&
              !_vm.$v.getSatellite.supportedCoverages.required
                ? _c("div", { staticClass: "satellite_form_error" }, [
                    _vm._v("At least one coverage type is required"),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { staticClass: "libelle-centered" }, [_vm._v(" Activated")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "name" } }, [
      _vm._v("Title"),
      _c("sup", [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("span", { staticClass: "separator" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_vm._v("Logo"), _c("sup", [_vm._v("*")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("span", { staticClass: "separator" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_vm._v("Satellite picture"), _c("sup", [_vm._v("*")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("span", { staticClass: "separator" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_vm._v("Description"), _c("sup", [_vm._v("*")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("span", { staticClass: "separator" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { staticClass: "libelle-centered" }, [
        _vm._v(" Is SpaceInspire?"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { staticClass: "libelle-centered" }, [_vm._v(" Type A")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { staticClass: "libelle-centered" }, [_vm._v(" Type B")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("span", { staticClass: "separator" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_vm._v("Power"), _c("sup", [_vm._v("*")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("span", { staticClass: "separator" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_vm._v("Type of coverage"), _c("sup", [_vm._v("*")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }