import { displayMessage, ERROR_TYPES } from "@/core/middlewares/error";
import axios from "axios";
const base64 = require('base64-arraybuffer')

export const media = {
    downloadFile,
    getPicture
}

async function downloadFile(url: string, fileName: string) {
    await axios.get(url, { responseType: 'blob' })
        .then(response => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]))
            var fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', fileName)
            document.body.appendChild(fileLink)
            fileLink.click()
        })
}

async function getPicture(url: string) {
    return await axios.get(url, { responseType: 'arraybuffer' })
        .then(response => {
            return 'data:image/png;base64, ' + base64.encode(response.data)
        })
        .catch(() => displayMessage({ message: 'Cannot retrieve image.', type: ERROR_TYPES.ERROR }))
}

