// tslint:disable
/**
 * SkyT
 * SkyT contract
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: email@email.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { Question } from '../models';
import { QuestionExtended } from '../models';
/**
 * InterviewApi - axios parameter creator
 * @export
 */
export const InterviewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Detele a question
         * @param {number} questionId Question ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestion(questionId: number, options: any = {}): RequestArgs {
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError('questionId','Required parameter questionId was null or undefined when calling deleteQuestion.');
            }
            const localVarPath = `/persona/interview/question/{questionId}`
                .replace(`{${"questionId"}}`, encodeURIComponent(String(questionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get persona\'s questions
         * @param {number} personaId Persona ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPersonaInterview(personaId: number, options: any = {}): RequestArgs {
            // verify required parameter 'personaId' is not null or undefined
            if (personaId === null || personaId === undefined) {
                throw new RequiredError('personaId','Required parameter personaId was null or undefined when calling fetchPersonaInterview.');
            }
            const localVarPath = `/persona/{personaId}/interview`
                .replace(`{${"personaId"}}`, encodeURIComponent(String(personaId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get question
         * @param {number} questionId Persona ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQuestion(questionId: number, options: any = {}): RequestArgs {
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError('questionId','Required parameter questionId was null or undefined when calling fetchQuestion.');
            }
            const localVarPath = `/persona/interview/question/{questionId}`
                .replace(`{${"questionId"}}`, encodeURIComponent(String(questionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a question
         * @param {number} questionId Question ID
         * @param {QuestionExtended} questionExtended Question object that needs to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestion(questionId: number, questionExtended: QuestionExtended, options: any = {}): RequestArgs {
            // verify required parameter 'questionId' is not null or undefined
            if (questionId === null || questionId === undefined) {
                throw new RequiredError('questionId','Required parameter questionId was null or undefined when calling updateQuestion.');
            }
            // verify required parameter 'questionExtended' is not null or undefined
            if (questionExtended === null || questionExtended === undefined) {
                throw new RequiredError('questionExtended','Required parameter questionExtended was null or undefined when calling updateQuestion.');
            }
            const localVarPath = `/persona/interview/question/{questionId}`
                .replace(`{${"questionId"}}`, encodeURIComponent(String(questionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof questionExtended !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(questionExtended !== undefined ? questionExtended : {}) : (questionExtended || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InterviewApi - functional programming interface
 * @export
 */
export const InterviewApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Detele a question
         * @param {number} questionId Question ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestion(questionId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InterviewApiAxiosParamCreator(configuration).deleteQuestion(questionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get persona\'s questions
         * @param {number} personaId Persona ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPersonaInterview(personaId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Question>> {
            const localVarAxiosArgs = InterviewApiAxiosParamCreator(configuration).fetchPersonaInterview(personaId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get question
         * @param {number} questionId Persona ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQuestion(questionId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionExtended> {
            const localVarAxiosArgs = InterviewApiAxiosParamCreator(configuration).fetchQuestion(questionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary update a question
         * @param {number} questionId Question ID
         * @param {QuestionExtended} questionExtended Question object that needs to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestion(questionId: number, questionExtended: QuestionExtended, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = InterviewApiAxiosParamCreator(configuration).updateQuestion(questionId, questionExtended, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InterviewApi - factory interface
 * @export
 */
export const InterviewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Detele a question
         * @param {number} questionId Question ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuestion(questionId: number, options?: any): AxiosPromise<void> {
            return InterviewApiFp(configuration).deleteQuestion(questionId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get persona\'s questions
         * @param {number} personaId Persona ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPersonaInterview(personaId: number, options?: any): AxiosPromise<Array<Question>> {
            return InterviewApiFp(configuration).fetchPersonaInterview(personaId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get question
         * @param {number} questionId Persona ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchQuestion(questionId: number, options?: any): AxiosPromise<QuestionExtended> {
            return InterviewApiFp(configuration).fetchQuestion(questionId, options)(axios, basePath);
        },
        /**
         * 
         * @summary update a question
         * @param {number} questionId Question ID
         * @param {QuestionExtended} questionExtended Question object that needs to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuestion(questionId: number, questionExtended: QuestionExtended, options?: any): AxiosPromise<void> {
            return InterviewApiFp(configuration).updateQuestion(questionId, questionExtended, options)(axios, basePath);
        },
    };
};

/**
 * InterviewApi - object-oriented interface
 * @export
 * @class InterviewApi
 * @extends {BaseAPI}
 */
export class InterviewApi extends BaseAPI {
    /**
     * 
     * @summary Detele a question
     * @param {number} questionId Question ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterviewApi
     */
    public deleteQuestion(questionId: number, options?: any) {
        return InterviewApiFp(this.configuration).deleteQuestion(questionId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get persona\'s questions
     * @param {number} personaId Persona ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterviewApi
     */
    public fetchPersonaInterview(personaId: number, options?: any) {
        return InterviewApiFp(this.configuration).fetchPersonaInterview(personaId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get question
     * @param {number} questionId Persona ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterviewApi
     */
    public fetchQuestion(questionId: number, options?: any) {
        return InterviewApiFp(this.configuration).fetchQuestion(questionId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary update a question
     * @param {number} questionId Question ID
     * @param {QuestionExtended} questionExtended Question object that needs to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterviewApi
     */
    public updateQuestion(questionId: number, questionExtended: QuestionExtended, options?: any) {
        return InterviewApiFp(this.configuration).updateQuestion(questionId, questionExtended, options)(this.axios, this.basePath);
    }

}
