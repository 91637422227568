var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "bloc_template" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", [
            _c("div", { staticClass: "bloc_padding" }, [
              _c("h2", { staticClass: "h2importexport" }, [
                _vm._v("Import/Export"),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }