import _ from 'lodash'
import Vue from "vue";
import { mapActions, mapGetters } from 'vuex';
import { PROJECT_EXPORTS_FETCH, PROJECT_FETCH, PROJECT_UPLOAD_KML } from '@/store/aliases/actions.type';
import { PROJECT_EXPORT_TYPE, PROJECT_TYPE } from '@/store/types';
import { GET_PROJECT, GET_PROJECT_EXPORTS } from '@/store/aliases/getters.type';
import { UploadProjectKml } from '@/core/models';
import Picture from '@/components/Picture.vue'
import ProjectDetailsInformation from '../ProjectDetailsInformation.vue'
import ProjectDetailsCalculation from '../ProjectDetailsCalculation.vue'
import { comparator } from '@/utils/comparator';

export default Vue.extend({
    components: {
        Picture,
        ProjectDetailsInformation,
        ProjectDetailsCalculation
    },
    props: {
        projectId: String
    },
    data() {
        return {
            items: items as Item[],
            selectedItemIndex: 0,
            selectedExport: undefined
        }
    },
    mounted() {
        this.fetchProject({ projectId: this.projectId })
        this.fetchProjectExports({ projectId: this.projectId })
    },
    computed: {
        ...mapGetters({
            getProject: `${PROJECT_TYPE}/${GET_PROJECT}`,
            getProjectExports: `${PROJECT_EXPORT_TYPE}/${GET_PROJECT_EXPORTS}`
        }),
        orderedProjectExports() {
            let projectExportsArray = _(this.getProjectExports)
                .groupBy(projectExport => projectExport.configuration.satellite.name)
                .sortBy(projectExportsMap => _.keys(projectExportsMap))
                .value()

            projectExportsArray.forEach(projectExports => {
                comparator.sortArrayOnNestedObjectProperty(projectExports, 'configuration.name')
            })

            return projectExportsArray
        },
        selectedProjectExport() {
            if (this.selectedExport) {
                return this.selectedExport
            } else {
                if (this.orderedProjectExports[0]) {
                    return this.orderedProjectExports[0][0]
                } else {
                    return undefined
                }
            }
        }
    },
    methods: {
        ...mapActions({
            fetchProject: `${PROJECT_TYPE}/${PROJECT_FETCH}`,
            fetchProjectExports: `${PROJECT_EXPORT_TYPE}/${PROJECT_EXPORTS_FETCH}`,
            uploadKml: `${PROJECT_TYPE}/${PROJECT_UPLOAD_KML}`
        }),
        handleKmlUpload(e) {
            this.uploadKml({ projectId: this.projectId, uploadProjectKml: { customKml: e.target.files[0] } as UploadProjectKml })
        },
        selectItem(index) {
            this.selectedItemIndex = index
        },
        selectProjectExport(projectExport) {
            this.selectedExport = projectExport
        }
    }
})

export interface Item {
    name: string
}

export const items: Item[] = [
    { name: 'Project details' },
    { name: 'Calculation Management' },
    { name: 'Planning' },
    { name: 'Custom background layer' }
]
