var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "bloc_template" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { class: _vm.selectedItemIndex >= 0 ? "col-6" : "col-12" }, [
          _c("div", { staticClass: "bloc_padding" }, [
            _c("h2", { staticClass: "h2catalog" }, [_vm._v("Catalog")]),
            _c("div", { staticClass: "bloc_content" }, [
              _c(
                "ul",
                [
                  _c(
                    "transition-group",
                    _vm._l(_vm.items, function (item, i_index) {
                      return _c("SelectableLine", {
                        key: item.name,
                        class: { active: _vm.selectedItemIndex === i_index },
                        attrs: {
                          item: item,
                          isDraggable: false,
                          isDeletable: false,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.selectItem(i_index)
                          },
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _vm.selectedItemIndex >= 0
          ? _c(
              "div",
              {
                staticClass: "col-6",
                style: _vm.selectedItemIndex >= 0 ? "" : "display:none",
              },
              [
                _c("div", { staticClass: "bloc_right" }, [
                  _c(
                    "div",
                    { staticClass: "bloc_padding" },
                    [
                      _vm.selectedItemIndex === 0
                        ? _c("ManageSatellites")
                        : _vm._e(),
                      _vm.selectedItemIndex === 1
                        ? _c("ManageGateways")
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }