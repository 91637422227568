import Vue from "vue";

export default Vue.extend({
    props: {
        error: String
    },
    methods: {
        logOut() {
            this.$amarisVueMsalPlugin.logout()
        }
    }
})
