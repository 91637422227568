import Vue from 'vue'

import Aside from '@/components/Aside.vue'

export default Vue.extend({
    name: 'app',
    async created() {
        const token = await this.$amarisVueMsalPlugin.getAccessToken()
        if (token) {
            console.log(token)
        }
    },
    components: {
        Aside
    },
    computed: {
        isErrorPage() {
            return this.$route.name === 'Forbidden' || this.$route.name === 'PageNotFound'
        }
    }
})
