import {
    GET_CUSTOMERS,
    GET_CUSTOMER
} from "../aliases/getters.type"

import {
    CUSTOMERS_FETCH,
    CUSTOMER_CREATE,
    CUSTOMER_UPDATE,
    CUSTOMER_UPLOAD_LOGO
} from "../aliases/actions.type"

import {
    SET_CUSTOMERS,
    SET_CUSTOMER,
    ADD_CUSTOMER,
    EDIT_CUSTOMER
} from "../aliases/mutations.type"

import { 
    CustomerApi
} from '@/core'

import { 
    // CustomerExtended, 
    Customer, UploadCustomerPicture
} from '@/core/models'

import {
    getConfiguration
} from '@/core/utils/config.util'

import {
    log,
    LOG_TYPES
} from '@/core/middlewares/Logger'

import {
    displayMessage,
    ERROR_TYPES
} from '@/core/middlewares/error'

import _ from 'lodash'

import Vue from 'vue'
import {AxiosResponse} from "axios"

const namespaced = true

const replaceCustomer = ({ state, customer }) => {
    const index = _.findIndex(state.customers, customer => customer.id === state.customer.id)
    if (index >= 0) {
        state.customers[index] = customer || state.customer
    }
}

export default {
    namespaced,
    state: {
        customers: [],
        customer: {}
    },
    getters: {
        [GET_CUSTOMERS]: (state) => {
            return state.customers
        },
        [GET_CUSTOMER]: (state) => {
            return state.customer
        }
    },
    actions: {
        [CUSTOMERS_FETCH]({ commit }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Fetching available customers.` })
            return getConfiguration().then(config => {
                return new CustomerApi(config).getAllCustomers()
            }).then(({ data }) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Customers: ${data.length} returned.`
                })
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Customers: ${JSON.stringify(data)} returned successfully.`
                })
                commit(SET_CUSTOMERS, { customers: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while fetching customers.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
        async [CUSTOMER_CREATE]({ commit }, { customer }: { customer: Customer }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Adding next customer:  ${JSON.stringify(customer)}.` })

            return getConfiguration().then(config => {
                return new CustomerApi(config).newCustomer(customer)
            }).then(({ data }) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `CustomerAdd: ${JSON.stringify(customer)} created successfully.`
                })
                displayMessage({ message: 'Customer added successfully.', type: ERROR_TYPES.SUCCESS })
                commit(ADD_CUSTOMER, { customer })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while adding customer.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
        async [CUSTOMER_UPDATE]({ commit }, { customer }: { customer: Customer }) {
            log({VueInstance: Vue, type: LOG_TYPES.d, message: `Adding next customer:  ${JSON.stringify(customer)}.`})

            return getConfiguration().then(config => {
                return new CustomerApi(config).updateCustomerById(customer.id, customer)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `CustomerUpdate: ${JSON.stringify(customer)} updated successfully.`
                })
                displayMessage({message: 'Customer updated successfully.', type: ERROR_TYPES.SUCCESS})
                commit(EDIT_CUSTOMER, {customer})
                return data
            }).catch(({response}) => {
                displayMessage({message: 'Error while updating customer.', type: ERROR_TYPES.ERROR})
                log({VueInstance: Vue, type: LOG_TYPES.e, response})
                return false
            })
        },
        async [CUSTOMER_UPLOAD_LOGO]({ commit }, { customerId, customerLogo }: { customerId: number, customerLogo: UploadCustomerPicture }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Uploading new customer logo:  ${JSON.stringify(customerLogo)} for customer with id ${customerId}.` })
            return getConfiguration().then(config => {
                return new CustomerApi(config).postCustomerPicture(customerId, customerLogo)
            }).then(({ data }) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `UploadCustomerLogo: ${JSON.stringify(customerLogo)} uploaded successfully.`
                })
                displayMessage({ message: 'Customer logo uploaded successfully.', type: ERROR_TYPES.SUCCESS })
                commit(SET_CUSTOMER, {customerId})
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while uploading customer logo.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
    },

    mutations: {
        [SET_CUSTOMER](state, { customer }: { customer: Customer }) {
            state.customer = customer
        },
        [SET_CUSTOMERS](state, { customers }: { customers: Array<Customer> }) {
            state.customers = customers
        },
        [ADD_CUSTOMER](state, { customer }: { customer: Customer }) {
            state.customers.push(customer)
            state.customer = customer
        },
        [EDIT_CUSTOMER](state, { customer }: { customer: Customer }) {
            replaceCustomer({ state, customer })
            state.customer = customer
        }
        // [REMOVE_CUSTOMER](state, { id }: { id: number }) {
        //     _.remove(state.customers, customer => customer.id == id)
        //     replaceCustomer({ state, customer: null })
        // }
    }
}
