// tslint:disable
/**
 * SkyT
 * SkyT contract
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: email@email.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiError } from '../models';
// @ts-ignore
import { CreateCustomer } from '../models';
// @ts-ignore
import { Customer } from '../models';
/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a specific customer
         * @summary Delete a customer and cacade to all sub entities
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerById: async (customerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling deleteCustomerById.');
            }
            const localVarPath = `/api/v1/customers/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all customers
         * @summary Get all the customers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/customers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new customer
         * @summary create a customer
         * @param {CreateCustomer} createCustomer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newCustomer: async (createCustomer: CreateCustomer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCustomer' is not null or undefined
            if (createCustomer === null || createCustomer === undefined) {
                throw new RequiredError('createCustomer','Required parameter createCustomer was null or undefined when calling newCustomer.');
            }
            const localVarPath = `/api/v1/customers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createCustomer !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createCustomer !== undefined ? createCustomer : {}) : (createCustomer || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload a picture for a customer
         * @summary Upload a picture for a customer
         * @param {number} customerId 
         * @param {any} customerPicture 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomerPicture: async (customerId: number, customerPicture: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling postCustomerPicture.');
            }
            // verify required parameter 'customerPicture' is not null or undefined
            if (customerPicture === null || customerPicture === undefined) {
                throw new RequiredError('customerPicture','Required parameter customerPicture was null or undefined when calling postCustomerPicture.');
            }
            const localVarPath = `/api/v1/customers/{customerId}/uploadPicture`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();


            if (customerPicture !== undefined) { 
                localVarFormParams.append('customerPicture', customerPicture as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a specific customer
         * @summary Update a customer
         * @param {number} customerId 
         * @param {CreateCustomer} createCustomer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerById: async (customerId: number, createCustomer: CreateCustomer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling updateCustomerById.');
            }
            // verify required parameter 'createCustomer' is not null or undefined
            if (createCustomer === null || createCustomer === undefined) {
                throw new RequiredError('createCustomer','Required parameter createCustomer was null or undefined when calling updateCustomerById.');
            }
            const localVarPath = `/api/v1/customers/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createCustomer !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createCustomer !== undefined ? createCustomer : {}) : (createCustomer || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Delete a specific customer
         * @summary Delete a customer and cacade to all sub entities
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomerById(customerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).deleteCustomerById(customerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get all customers
         * @summary Get all the customers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCustomers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Customer>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).getAllCustomers(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create a new customer
         * @summary create a customer
         * @param {CreateCustomer} createCustomer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newCustomer(createCustomer: CreateCustomer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).newCustomer(createCustomer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Upload a picture for a customer
         * @summary Upload a picture for a customer
         * @param {number} customerId 
         * @param {any} customerPicture 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCustomerPicture(customerId: number, customerPicture: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).postCustomerPicture(customerId, customerPicture, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update a specific customer
         * @summary Update a customer
         * @param {number} customerId 
         * @param {CreateCustomer} createCustomer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerById(customerId: number, createCustomer: CreateCustomer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).updateCustomerById(customerId, createCustomer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Delete a specific customer
         * @summary Delete a customer and cacade to all sub entities
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerById(customerId: number, options?: any): AxiosPromise<void> {
            return CustomerApiFp(configuration).deleteCustomerById(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all customers
         * @summary Get all the customers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomers(options?: any): AxiosPromise<Array<Customer>> {
            return CustomerApiFp(configuration).getAllCustomers(options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new customer
         * @summary create a customer
         * @param {CreateCustomer} createCustomer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newCustomer(createCustomer: CreateCustomer, options?: any): AxiosPromise<Customer> {
            return CustomerApiFp(configuration).newCustomer(createCustomer, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload a picture for a customer
         * @summary Upload a picture for a customer
         * @param {number} customerId 
         * @param {any} customerPicture 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomerPicture(customerId: number, customerPicture: any, options?: any): AxiosPromise<Customer> {
            return CustomerApiFp(configuration).postCustomerPicture(customerId, customerPicture, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a specific customer
         * @summary Update a customer
         * @param {number} customerId 
         * @param {CreateCustomer} createCustomer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerById(customerId: number, createCustomer: CreateCustomer, options?: any): AxiosPromise<Customer> {
            return CustomerApiFp(configuration).updateCustomerById(customerId, createCustomer, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * Delete a specific customer
     * @summary Delete a customer and cacade to all sub entities
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public deleteCustomerById(customerId: number, options?: any) {
        return CustomerApiFp(this.configuration).deleteCustomerById(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all customers
     * @summary Get all the customers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getAllCustomers(options?: any) {
        return CustomerApiFp(this.configuration).getAllCustomers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new customer
     * @summary create a customer
     * @param {CreateCustomer} createCustomer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public newCustomer(createCustomer: CreateCustomer, options?: any) {
        return CustomerApiFp(this.configuration).newCustomer(createCustomer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload a picture for a customer
     * @summary Upload a picture for a customer
     * @param {number} customerId 
     * @param {any} customerPicture 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public postCustomerPicture(customerId: number, customerPicture: any, options?: any) {
        return CustomerApiFp(this.configuration).postCustomerPicture(customerId, customerPicture, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a specific customer
     * @summary Update a customer
     * @param {number} customerId 
     * @param {CreateCustomer} createCustomer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public updateCustomerById(customerId: number, createCustomer: CreateCustomer, options?: any) {
        return CustomerApiFp(this.configuration).updateCustomerById(customerId, createCustomer, options).then((request) => request(this.axios, this.basePath));
    }

}
