export const comparator = {
    compareObjectsOnStrings,
    sortArrayOnNestedObjectProperty
}

function compareObjectsOnStrings(a: string, b: string, property: string) {
    if (a[property] < b[property]) { return -1 }
    if (a[property] > b[property]) { return 1 }
    return 0
}

function sortArrayOnNestedObjectProperty(array: Array<Object>, property: string) {
    const prop = property.split('.');
    const len = prop.length;

    array.sort((a, b) => {
        var i = 0;
        while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
        if (a < b) return -1
        if (a > b) return 1
        return 0
    })

    return array
}