var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", { staticClass: "h2configurationfiles" }, [
      _vm._v("Manage droplists"),
    ]),
    _c("div", { staticClass: "bloc_content" }, [
      _c(
        "ul",
        _vm._l(_vm.items, function (item) {
          return _c(
            "li",
            {
              key: item.id,
              attrs: { item: item, isDraggable: false, isDeletable: false },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "align_left col-6" }, [
                  _c("span", { staticClass: "titleconfig" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                ]),
                _c("div", { staticClass: "col-6 align_right" }, [
                  _c("input", {
                    ref: "fileUploadInput",
                    refInFor: true,
                    staticClass: "inputfile inputfile-2",
                    attrs: { id: item.id, type: "file", accept: ".csv" },
                    on: {
                      change: function ($event) {
                        return _vm.handleFile($event, item.id)
                      },
                    },
                  }),
                  _c(
                    "label",
                    { ref: item.id, refInFor: true, attrs: { for: item.id } },
                    [
                      _c(
                        "svg",
                        {
                          staticStyle: {
                            "enable-background": "new 0 0 392.103 362.787",
                          },
                          attrs: {
                            version: "1.1",
                            xmlns: "http://www.w3.org/2000/svg",
                            "xmlns:xlink": "http://www.w3.org/1999/xlink",
                            x: "0px",
                            y: "0px",
                            viewBox: "0 0 392.103 362.787",
                            "xml:space": "preserve",
                          },
                        },
                        [
                          _c("g", [
                            _c("g", [
                              _c("g", [
                                _c("path", {
                                  attrs: {
                                    d: "M373.195,176.99c-6,0-10.801,4.801-10.801,10.801v98.238c0,26.719-21.757,48.403-48.398,48.403H77.035 c-26.719,0-48.398-21.762-48.398-48.403V186.19c0-6-4.801-10.801-10.801-10.801S7.035,180.19,7.035,186.19v99.839 c0,38.641,31.441,70,70,70h236.961c38.641,0,70-31.437,70-70v-98.238C383.996,181.869,379.195,176.99,373.195,176.99 L373.195,176.99z M373.195,176.99",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d: "M313.996,361.029H77.035c-41.355,0-75-33.645-75-75V186.19c0-8.713,7.088-15.801,15.801-15.801 c8.713,0,15.801,7.088,15.801,15.801v99.839c0,23.933,19.468,43.403,43.398,43.403h236.961c23.93,0,43.398-19.471,43.398-43.403 v-98.238c0-8.712,7.088-15.801,15.801-15.801s15.801,7.088,15.801,15.801v98.238 C388.996,327.385,355.352,361.029,313.996,361.029z M17.836,180.389c-3.253,0-5.801,2.548-5.801,5.801v99.839 c0,35.841,29.159,65,65,65h236.961c35.841,0,65-29.159,65-65v-98.238c0-3.198-2.603-5.801-5.801-5.801 c-3.253,0-5.801,2.548-5.801,5.801v98.238c0,29.446-23.954,53.403-53.398,53.403H77.035c-29.444,0-53.398-23.957-53.398-53.403 V186.19C23.637,182.937,21.089,180.389,17.836,180.389z",
                                  },
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("g", [
                              _c("path", {
                                attrs: {
                                  d: "M134.476,93.791l50.239-50.242V262.67c0,6,4.8,10.801,10.8,10.801s10.801-4.801,10.801-10.801V43.549l50.239,50.242 c2.082,2.078,4.878,3.199,7.601,3.199c2.801,0,5.52-1.039,7.598-3.199c4.242-4.242,4.242-11.043,0-15.281L203.113,9.869 c-2-2-4.797-3.199-7.598-3.199c-2.878,0-5.601,1.121-7.601,3.199L119.273,78.51c-4.238,4.238-4.238,11.039,0,15.281 C123.433,97.951,130.316,97.951,134.476,93.791L134.476,93.791z M134.476,93.791",
                                },
                              }),
                              _vm._v(" "),
                              _c("path", {
                                attrs: {
                                  d: "M195.515,278.472c-8.712,0-15.8-7.089-15.8-15.802V55.621l-41.704,41.706c-6.141,6.141-16.133,6.141-22.273,0 c-6.16-6.167-6.16-16.193,0-22.352l68.641-68.642c2.87-2.983,6.849-4.663,11.137-4.663c4.112,0,8.17,1.7,11.133,4.663 l68.641,68.641c2.996,2.993,4.646,6.962,4.646,11.175c0,4.212-1.65,8.182-4.646,11.178c-2.868,2.982-6.847,4.663-11.133,4.663 c-4.108,0-8.166-1.698-11.134-4.66l-41.706-41.709V262.67C211.317,271.383,204.228,278.472,195.515,278.472z M189.716,31.477 V262.67c0,3.253,2.547,5.802,5.8,5.802c3.253,0,5.801-2.549,5.801-5.802V31.477l58.774,58.779 c1.086,1.084,2.607,1.734,4.065,1.734c1.091,0,2.67-0.289,3.995-1.666c1.175-1.176,1.784-2.634,1.784-4.175 c0-1.54-0.609-2.997-1.716-4.102l-68.642-68.643c-1.086-1.086-2.605-1.734-4.063-1.734c-1.544,0-2.964,0.592-3.999,1.667 l-68.708,68.709c-2.301,2.301-2.3,5.908,0.002,8.212c2.202,2.202,5.926,2.201,8.13-0.002L189.716,31.477z",
                                },
                              }),
                            ]),
                          ]),
                        ]
                      ),
                      _c("span", [_vm._v("Upload .csv file")]),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
      _c("div", { staticClass: "bloc_cta_save" }, [
        _c(
          "button",
          {
            staticClass: "btn_primary",
            on: {
              click: function ($event) {
                return _vm.uploadCsv()
              },
            },
          },
          [_c("span", { staticClass: "libelle" }, [_vm._v("Upload files")])]
        ),
      ]),
      _vm.uploadResponseType
        ? _c("div", { staticClass: "upload_response" }, [
            _vm.uploadResponseType === "SUCCESS"
              ? _c("span", { staticClass: "message_ok" }, [
                  _vm._v("Files uploaded succesfully."),
                ])
              : _vm.uploadResponseType === "ERROR_FORMAT"
              ? _c("span", { staticClass: "message_ko" }, [
                  _vm._v("Wrong format: one or more files are not csv files."),
                ])
              : _vm.uploadResponseType === "ERROR_CSV"
              ? _c("span", { staticClass: "message_ko" }, [
                  _vm._v(
                    "Wrong CSV uploaded: one or more files do not correspond to the correct referential."
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }