var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.project.description
      ? _c("div", [
          _c("h6", [_vm._v("Project description")]),
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.project.description) },
          }),
          _c("hr"),
        ])
      : _vm._e(),
    _vm.project.projectOrganizations && _vm.project.projectOrganizations.length
      ? _c("div", [
          _c("h6", [_vm._v("Project organizations")]),
          _c(
            "div",
            { staticClass: "bloc_project_organizations" },
            _vm._l(_vm.project.projectOrganizations, function (organization) {
              return _c("span", [_vm._v(_vm._s(organization.name))])
            }),
            0
          ),
          _c("hr"),
        ])
      : _vm._e(),
    _c("div", { staticClass: "bloc_team_member" }, [
      _c("h6", [_vm._v("Team members")]),
      _c(
        "ul",
        { staticClass: "details_team_member" },
        _vm._l(_vm.sortedTeamMembers, function (member) {
          return _c("li", [
            _c("div", { staticClass: "bloc_details_team_member" }, [
              _c("span", { staticClass: "name" }, [
                _vm._v(
                  _vm._s(member.firstName) + " " + _vm._s(member.lastName)
                ),
              ]),
              _c("span", { staticClass: "mail" }, [
                _vm._v(_vm._s(member.mail)),
              ]),
              member.phone
                ? _c("span", { staticClass: "phone" }, [
                    _vm._v(_vm._s(_vm.formatPhone(member.phone))),
                  ])
                : _vm._e(),
              _c("span", { staticClass: "job" }, [_vm._v(_vm._s(member.role))]),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }