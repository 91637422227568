var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DeleteModal", {
        attrs: { id: "delete-component", source: "gateway", updating: false },
      }),
      _c("h2", { staticClass: "h2gateway" }, [_vm._v("Manage Gateways")]),
      _c("div", { staticClass: "bloc_content" }, [
        _c(
          "ul",
          [
            _c(
              "draggable",
              {
                attrs: { list: _vm.getGateways },
                on: { change: _vm.updateIndex },
              },
              [
                _c(
                  "transition-group",
                  _vm._l(_vm.activeGateways, function (gateway, g_index) {
                    return _c("SelectableLine", {
                      key: g_index,
                      attrs: {
                        item: gateway,
                        isDraggable: true,
                        isDeletable: true,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.selectGateway(gateway)
                        },
                      },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "ul",
          [
            _c(
              "transition-group",
              _vm._l(_vm.inactiveGateways, function (gateway, g_index) {
                return _c("SelectableLine", {
                  key: g_index,
                  attrs: {
                    item: gateway,
                    isDraggable: false,
                    isDeletable: true,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.selectGateway(gateway)
                    },
                  },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "cta-form-group-02",
            on: {
              click: function ($event) {
                return _vm.addGateway()
              },
            },
          },
          [_vm._m(0)]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cta_add" }, [
      _c("span", { staticClass: "libelle" }, [_vm._v("Add gateway")]),
      _c("span", { staticClass: "icon" }, [
        _c("span", { staticClass: "icon_plus" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }