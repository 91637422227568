export const GET_ITEM_TO_DELETE = 'getItemToDelete'

export const GET_OFFERS = 'getOffers'
export const GET_OFFER = 'getOffer'

export const GET_SEGMENTS = 'getSegments'
export const GET_ITEMS = 'getItems'
export const GET_ITEM = 'getItem'
export const GET_TITLES = 'getTitles'

export const GET_PERSONAS = 'getPersonas'
export const GET_PERSONA = 'gettPersona'

export const GET_CUSTOMERS = 'getCustomers'
export const GET_CUSTOMER = 'getCustomer'

export const GET_LEGAL_NOTICE = 'getLegalNotice'
export const GET_COOKIES = 'getCookies'
export const GET_CONDITIONS = 'getConditions'
export const GET_ABOUT = 'getAbout'

export const GET_BEAM_CUSTOMISATION = 'getBeamCustomisation'

export const GET_PROJECTS = 'getProjects'
export const GET_PROJECT = 'getProject'

export const GET_PROJECT_EXPORTS = 'getProjectExports'

export const GET_SATELLITES = 'getSatellites'
export const GET_SATELLITE = 'getSatellite'

export const GET_GATEWAYS = 'getGateways'
export const GET_GATEWAY = 'getGateway'

export const GET_MISSION_COLORS = 'getMissionColors'
