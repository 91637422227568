import { Configuration } from "@/core/configuration"
import { EnvConfiguration } from '@/config/config'
import {AmarisVueMsalPlugin} from '@/core/plugins/amarisVueMsalPlugin'

export async function getConfiguration(): Promise<Configuration> {
    const token = await AmarisVueMsalPlugin.getInstance(null).getAccessToken()
    return constructConfig({configFile: EnvConfiguration, token: token})
}

function constructConfig({ configFile, token }) {
    return {
        apiKey: configFile.apiKey,
        username: configFile.username,
        password: configFile.password,
        accessToken: token,
        basePath: configFile.basePath,
        baseOptions: configFile.baseOptions
    } as Configuration
}
