var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("DeleteModal", {
        attrs: { id: "delete-component", source: "project", updating: false },
      }),
      _c("div", { staticClass: "bloc_template" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { class: _vm.selectedCustomerId ? "col-6" : "col-12" }, [
            _c("div", { staticClass: "bloc_padding" }, [
              _c("h2", { staticClass: "h2customers" }, [_vm._v("Customers")]),
              _c("div", { staticClass: "bloc_content" }, [
                _c(
                  "ul",
                  [
                    _c(
                      "transition-group",
                      _vm._l(_vm.sortedCustomers, function (customer) {
                        return _c("SelectableLine", {
                          key: customer.id,
                          class: {
                            active: _vm.selectedCustomerId === customer.id,
                          },
                          attrs: {
                            item: customer,
                            isDraggable: false,
                            isDeletable: false,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.selectCustomer(customer)
                            },
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm.selectedCustomerId
            ? _c(
                "div",
                {
                  staticClass: "col-6",
                  style: _vm.selectedCustomerId ? "" : "display:none",
                },
                [
                  _c("div", { staticClass: "bloc_right" }, [
                    _c("div", { staticClass: "bloc_padding" }, [
                      _c("h2", { staticClass: "h2customers" }, [
                        _vm._v("Customer's Logo"),
                      ]),
                      _c("div", { staticClass: "bloc_content_padding" }, [
                        _c("ul", { staticClass: "bloc_cta_style_02" }),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("form", { ref: "logoUploaderForm" }, [
                              _c("div", { staticClass: "bloc_form_style_02" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c("label", [_vm._v("Logo")]),
                                  _c("label", { staticClass: "file" }, [
                                    _c("input", {
                                      ref: "logoUploader",
                                      attrs: {
                                        id: "file",
                                        name: "logoUploader",
                                        accept:
                                          "image/png, image/jpeg, image/gif",
                                        type: "file",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handleFile($event)
                                        },
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        ref: "logoUploader",
                                        staticClass: "file-custom",
                                      },
                                      [_vm._v(_vm._s(_vm.uploadFieldText))]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c("li"),
                          _c("div", { staticClass: "bloc_cta_save" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn_primary",
                                on: {
                                  click: function ($event) {
                                    return _vm.uploadLogo(
                                      _vm.selectedCustomerId
                                    )
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "libelle" }, [
                                  _vm._v("Upload logo"),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "bloc_padding" }, [
                      _c("h2", { staticClass: "h2projects" }, [
                        _vm._v("Projects"),
                      ]),
                      _c("div", { staticClass: "bloc_content" }, [
                        _c(
                          "ul",
                          [
                            _c(
                              "transition-group",
                              _vm._l(_vm.sortedProjects, function (project) {
                                return _c("SelectableLine", {
                                  key: project.id,
                                  class: {
                                    project_forbidden: _vm.isForbidden(project),
                                    confidential: project.isConfidential,
                                  },
                                  attrs: {
                                    item: project,
                                    isDraggable: false,
                                    isDeletable: _vm.isWriteAccess(project),
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.selectProject(project)
                                    },
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }