import Vue from 'vue'
import { skytRoles } from '@/config/config'

export default Vue.extend({
    name: 'Menu',
    data: function() {
      return {
          currentMenu: []
      }
    },
    async mounted() {
        const roles = await this.$amarisVueMsalPlugin.getRoles()
        this.currentMenu.push(...menu.filter(menuItem => menuItem.requiredRole === null || roles.includes(menuItem.requiredRole)))
    }
})

export interface MenuItem {
    name: string,
    route: string,
    requiredRole: string,
    class: string
}

export const menu: MenuItem[] = [
    { name: 'Customers and projects', route: '/', requiredRole: null, class: 'customers'},
    { name: 'Administration', route: '/administration', requiredRole: skytRoles.admin, class: 'administration'},
    { name: 'Catalog', route: '/catalog', requiredRole: skytRoles.admin, class: 'catalog'},
    { name: 'Import/Export', route: '/transfer', requiredRole: skytRoles.dynamiteManager, class: 'transfer'},
]
