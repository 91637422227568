// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/icon_team_email.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/img/icon_team_phone.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "h6 {\n  color: #242a75;\n  font-weight: 100;\n}\n.bloc_team_member ul {\n  -webkit-padding-start: 0px;\n          padding-inline-start: 0px;\n}\n.bloc_team_member ul li:first-child {\n  margin-top: 0px;\n}\n.bloc_team_member ul li .bloc_details_team_member span {\n  display: inline-block;\n  width: 100%;\n  padding-top: 2px;\n  padding-bottom: 2px;\n}\n.bloc_team_member ul li .bloc_details_team_member span.name {\n  font-size: 1.2em;\n}\n.bloc_team_member ul li .bloc_details_team_member span.mail {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: center left;\n  background-size: 18px 12px;\n  padding-left: 30px;\n}\n.bloc_team_member ul li .bloc_details_team_member span.phone {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n  background-position: center left;\n  background-size: 19px 19px;\n  padding-left: 30px;\n}\n.bloc_team_member ul li .bloc_details_team_member span.job {\n  font-style: italic;\n}\n.bloc_project_organizations span {\n  display: inline-block;\n  width: 100%;\n}", ""]);
// Exports
module.exports = exports;
