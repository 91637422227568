// tslint:disable
/**
 * SkyT
 * SkyT contract
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: email@email.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { Persona } from '../models';
import { PersonaExtended } from '../models';
/**
 * PersonaApi - axios parameter creator
 * @export
 */
export const PersonaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create an persona
         * @param {PersonaExtended} personaExtended Persona object that needs to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPersona(personaExtended: PersonaExtended, options: any = {}): RequestArgs {
            // verify required parameter 'personaExtended' is not null or undefined
            if (personaExtended === null || personaExtended === undefined) {
                throw new RequiredError('personaExtended','Required parameter personaExtended was null or undefined when calling createPersona.');
            }
            const localVarPath = `/persona`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof personaExtended !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(personaExtended !== undefined ? personaExtended : {}) : (personaExtended || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Detele a persona
         * @param {number} id Persona ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePersona(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePersona.');
            }
            const localVarPath = `/persona/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all personas
         * @summary Find all personas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAllPersonas(options: any = {}): RequestArgs {
            const localVarPath = `/personas`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an persona
         * @param {number} id Persona ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPersona(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling fetchPersona.');
            }
            const localVarPath = `/persona/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a persona
         * @param {number} id Persona ID
         * @param {PersonaExtended} personaExtended Persona object that needs to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersona(id: number, personaExtended: PersonaExtended, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updatePersona.');
            }
            // verify required parameter 'personaExtended' is not null or undefined
            if (personaExtended === null || personaExtended === undefined) {
                throw new RequiredError('personaExtended','Required parameter personaExtended was null or undefined when calling updatePersona.');
            }
            const localVarPath = `/persona/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof personaExtended !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(personaExtended !== undefined ? personaExtended : {}) : (personaExtended || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonaApi - functional programming interface
 * @export
 */
export const PersonaApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create an persona
         * @param {PersonaExtended} personaExtended Persona object that needs to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPersona(personaExtended: PersonaExtended, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PersonaApiAxiosParamCreator(configuration).createPersona(personaExtended, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Detele a persona
         * @param {number} id Persona ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePersona(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PersonaApiAxiosParamCreator(configuration).deletePersona(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get all personas
         * @summary Find all personas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAllPersonas(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Persona>> {
            const localVarAxiosArgs = PersonaApiAxiosParamCreator(configuration).fetchAllPersonas(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get an persona
         * @param {number} id Persona ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPersona(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonaExtended> {
            const localVarAxiosArgs = PersonaApiAxiosParamCreator(configuration).fetchPersona(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a persona
         * @param {number} id Persona ID
         * @param {PersonaExtended} personaExtended Persona object that needs to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersona(id: number, personaExtended: PersonaExtended, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = PersonaApiAxiosParamCreator(configuration).updatePersona(id, personaExtended, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PersonaApi - factory interface
 * @export
 */
export const PersonaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create an persona
         * @param {PersonaExtended} personaExtended Persona object that needs to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPersona(personaExtended: PersonaExtended, options?: any): AxiosPromise<void> {
            return PersonaApiFp(configuration).createPersona(personaExtended, options)(axios, basePath);
        },
        /**
         * 
         * @summary Detele a persona
         * @param {number} id Persona ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePersona(id: number, options?: any): AxiosPromise<void> {
            return PersonaApiFp(configuration).deletePersona(id, options)(axios, basePath);
        },
        /**
         * Get all personas
         * @summary Find all personas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAllPersonas(options?: any): AxiosPromise<Array<Persona>> {
            return PersonaApiFp(configuration).fetchAllPersonas(options)(axios, basePath);
        },
        /**
         * 
         * @summary Get an persona
         * @param {number} id Persona ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPersona(id: number, options?: any): AxiosPromise<PersonaExtended> {
            return PersonaApiFp(configuration).fetchPersona(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update a persona
         * @param {number} id Persona ID
         * @param {PersonaExtended} personaExtended Persona object that needs to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersona(id: number, personaExtended: PersonaExtended, options?: any): AxiosPromise<void> {
            return PersonaApiFp(configuration).updatePersona(id, personaExtended, options)(axios, basePath);
        },
    };
};

/**
 * PersonaApi - object-oriented interface
 * @export
 * @class PersonaApi
 * @extends {BaseAPI}
 */
export class PersonaApi extends BaseAPI {
    /**
     * 
     * @summary Create an persona
     * @param {PersonaExtended} personaExtended Persona object that needs to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonaApi
     */
    public createPersona(personaExtended: PersonaExtended, options?: any) {
        return PersonaApiFp(this.configuration).createPersona(personaExtended, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Detele a persona
     * @param {number} id Persona ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonaApi
     */
    public deletePersona(id: number, options?: any) {
        return PersonaApiFp(this.configuration).deletePersona(id, options)(this.axios, this.basePath);
    }

    /**
     * Get all personas
     * @summary Find all personas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonaApi
     */
    public fetchAllPersonas(options?: any) {
        return PersonaApiFp(this.configuration).fetchAllPersonas(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get an persona
     * @param {number} id Persona ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonaApi
     */
    public fetchPersona(id: number, options?: any) {
        return PersonaApiFp(this.configuration).fetchPersona(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update a persona
     * @param {number} id Persona ID
     * @param {PersonaExtended} personaExtended Persona object that needs to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonaApi
     */
    public updatePersona(id: number, personaExtended: PersonaExtended, options?: any) {
        return PersonaApiFp(this.configuration).updatePersona(id, personaExtended, options)(this.axios, this.basePath);
    }

}
