// tslint:disable
/**
 * SkyT
 * SkyT contract
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: email@email.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiError } from '../model';
// @ts-ignore
import { CreateOrUpdateMeeting } from '../model';
// @ts-ignore
import { CreateOrUpdateUser } from '../model';
// @ts-ignore
import { CreateProject } from '../model';
// @ts-ignore
import { CreateUpdateProjectOrganization } from '../model';
// @ts-ignore
import { MeetingDetails } from '../model';
// @ts-ignore
import { Project } from '../model';
// @ts-ignore
import { ProjectDetails } from '../model';
// @ts-ignore
import { ProjectOrganization } from '../model';
// @ts-ignore
import { UpdateProject } from '../model';
/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete an organization for the project
         * @summary delete an organization for a project
         * @param {number} projectId 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationForProject: async (projectId: number, organizationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling deleteOrganizationForProject.');
            }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling deleteOrganizationForProject.');
            }
            const localVarPath = `/api/v1/projects/{projectId}/organizations/{organizationId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a project with cascade on all the elements
         * @summary Delete a project
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteProjectById.');
            }
            const localVarPath = `/api/v1/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a teamMember for the project
         * @summary delete a teamMember for a project
         * @param {number} projectId 
         * @param {number} teamMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamMemberForProject: async (projectId: number, teamMemberId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling deleteTeamMemberForProject.');
            }
            // verify required parameter 'teamMemberId' is not null or undefined
            if (teamMemberId === null || teamMemberId === undefined) {
                throw new RequiredError('teamMemberId','Required parameter teamMemberId was null or undefined when calling deleteTeamMemberForProject.');
            }
            const localVarPath = `/api/v1/projects/{projectId}/team-members/{teamMemberId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"teamMemberId"}}`, encodeURIComponent(String(teamMemberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the details of a meeting
         * @summary Get meeting details
         * @param {number} projectId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingById: async (projectId: number, meetingId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getMeetingById.');
            }
            // verify required parameter 'meetingId' is not null or undefined
            if (meetingId === null || meetingId === undefined) {
                throw new RequiredError('meetingId','Required parameter meetingId was null or undefined when calling getMeetingById.');
            }
            const localVarPath = `/api/v1/projects/{projectId}/meetings/{meetingId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the details of a project
         * @summary Get project details
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProjectById.');
            }
            const localVarPath = `/api/v1/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get customer project list
         * @summary Get customer project list
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectListByCustomerId: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProjectListByCustomerId.');
            }
            const localVarPath = `/api/v1/customers/{id}/projects`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new meeting for the project
         * @summary create a meeting
         * @param {number} id 
         * @param {CreateOrUpdateMeeting} createOrUpdateMeeting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newMeeting: async (id: number, createOrUpdateMeeting: CreateOrUpdateMeeting, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling newMeeting.');
            }
            // verify required parameter 'createOrUpdateMeeting' is not null or undefined
            if (createOrUpdateMeeting === null || createOrUpdateMeeting === undefined) {
                throw new RequiredError('createOrUpdateMeeting','Required parameter createOrUpdateMeeting was null or undefined when calling newMeeting.');
            }
            const localVarPath = `/api/v1/projects/{id}/meetings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createOrUpdateMeeting !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createOrUpdateMeeting !== undefined ? createOrUpdateMeeting : {}) : (createOrUpdateMeeting || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an organization for the project
         * @summary create an organization for a project
         * @param {number} id 
         * @param {CreateUpdateProjectOrganization} createUpdateProjectOrganization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newOrganizationForProject: async (id: number, createUpdateProjectOrganization: CreateUpdateProjectOrganization, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling newOrganizationForProject.');
            }
            // verify required parameter 'createUpdateProjectOrganization' is not null or undefined
            if (createUpdateProjectOrganization === null || createUpdateProjectOrganization === undefined) {
                throw new RequiredError('createUpdateProjectOrganization','Required parameter createUpdateProjectOrganization was null or undefined when calling newOrganizationForProject.');
            }
            const localVarPath = `/api/v1/projects/{id}/organizations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createUpdateProjectOrganization !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createUpdateProjectOrganization !== undefined ? createUpdateProjectOrganization : {}) : (createUpdateProjectOrganization || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload a new planning
         * @summary Create a new planning
         * @param {number} id 
         * @param {string} name 
         * @param {any} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newPlanningForProject: async (id: number, name: string, fileName?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling newPlanningForProject.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling newPlanningForProject.');
            }
            const localVarPath = `/api/v1/projects/{id}/plannings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('fileName', fileName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new project
         * @summary create a project
         * @param {number} id 
         * @param {CreateProject} createProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newProject: async (id: number, createProject: CreateProject, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling newProject.');
            }
            // verify required parameter 'createProject' is not null or undefined
            if (createProject === null || createProject === undefined) {
                throw new RequiredError('createProject','Required parameter createProject was null or undefined when calling newProject.');
            }
            const localVarPath = `/api/v1/customers/{id}/projects`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createProject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createProject !== undefined ? createProject : {}) : (createProject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new teamMember for the project
         * @summary create a teamMember for a project
         * @param {number} id 
         * @param {CreateOrUpdateUser} createOrUpdateUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newTeamUser: async (id: number, createOrUpdateUser: CreateOrUpdateUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling newTeamUser.');
            }
            // verify required parameter 'createOrUpdateUser' is not null or undefined
            if (createOrUpdateUser === null || createOrUpdateUser === undefined) {
                throw new RequiredError('createOrUpdateUser','Required parameter createOrUpdateUser was null or undefined when calling newTeamUser.');
            }
            const localVarPath = `/api/v1/projects/{id}/team-members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createOrUpdateUser !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createOrUpdateUser !== undefined ? createOrUpdateUser : {}) : (createOrUpdateUser || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request access to the project
         * @summary Request access to project
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAccessToProjectById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling requestAccessToProjectById.');
            }
            const localVarPath = `/api/v1/projects/{id}/request-access`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ureate a new teamMember for the project
         * @summary update a teamMember for a project
         * @param {number} projectId 
         * @param {number} teamMemberId 
         * @param {CreateOrUpdateUser} createOrUpdateUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateATeamUser: async (projectId: number, teamMemberId: number, createOrUpdateUser: CreateOrUpdateUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling updateATeamUser.');
            }
            // verify required parameter 'teamMemberId' is not null or undefined
            if (teamMemberId === null || teamMemberId === undefined) {
                throw new RequiredError('teamMemberId','Required parameter teamMemberId was null or undefined when calling updateATeamUser.');
            }
            // verify required parameter 'createOrUpdateUser' is not null or undefined
            if (createOrUpdateUser === null || createOrUpdateUser === undefined) {
                throw new RequiredError('createOrUpdateUser','Required parameter createOrUpdateUser was null or undefined when calling updateATeamUser.');
            }
            const localVarPath = `/api/v1/projects/{projectId}/team-members/{teamMemberId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"teamMemberId"}}`, encodeURIComponent(String(teamMemberId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createOrUpdateUser !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createOrUpdateUser !== undefined ? createOrUpdateUser : {}) : (createOrUpdateUser || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a meeting of a project by id
         * @summary Update a meeting by id
         * @param {number} projectId 
         * @param {number} meetingId 
         * @param {CreateOrUpdateMeeting} createOrUpdateMeeting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeetingById: async (projectId: number, meetingId: number, createOrUpdateMeeting: CreateOrUpdateMeeting, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling updateMeetingById.');
            }
            // verify required parameter 'meetingId' is not null or undefined
            if (meetingId === null || meetingId === undefined) {
                throw new RequiredError('meetingId','Required parameter meetingId was null or undefined when calling updateMeetingById.');
            }
            // verify required parameter 'createOrUpdateMeeting' is not null or undefined
            if (createOrUpdateMeeting === null || createOrUpdateMeeting === undefined) {
                throw new RequiredError('createOrUpdateMeeting','Required parameter createOrUpdateMeeting was null or undefined when calling updateMeetingById.');
            }
            const localVarPath = `/api/v1/projects/{projectId}/meetings/{meetingId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createOrUpdateMeeting !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createOrUpdateMeeting !== undefined ? createOrUpdateMeeting : {}) : (createOrUpdateMeeting || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an organization for the project
         * @summary update an organization for a project
         * @param {number} projectId 
         * @param {number} organizationId 
         * @param {CreateUpdateProjectOrganization} createUpdateProjectOrganization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationForProject: async (projectId: number, organizationId: number, createUpdateProjectOrganization: CreateUpdateProjectOrganization, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling updateOrganizationForProject.');
            }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling updateOrganizationForProject.');
            }
            // verify required parameter 'createUpdateProjectOrganization' is not null or undefined
            if (createUpdateProjectOrganization === null || createUpdateProjectOrganization === undefined) {
                throw new RequiredError('createUpdateProjectOrganization','Required parameter createUpdateProjectOrganization was null or undefined when calling updateOrganizationForProject.');
            }
            const localVarPath = `/api/v1/projects/{projectId}/organizations/{organizationId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createUpdateProjectOrganization !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createUpdateProjectOrganization !== undefined ? createUpdateProjectOrganization : {}) : (createUpdateProjectOrganization || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a project by id
         * @summary Update a project
         * @param {number} id 
         * @param {UpdateProject} updateProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectById: async (id: number, updateProject: UpdateProject, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateProjectById.');
            }
            // verify required parameter 'updateProject' is not null or undefined
            if (updateProject === null || updateProject === undefined) {
                throw new RequiredError('updateProject','Required parameter updateProject was null or undefined when calling updateProjectById.');
            }
            const localVarPath = `/api/v1/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateProject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateProject !== undefined ? updateProject : {}) : (updateProject || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload a new Kml for a project this will replace the old one
         * @summary Upload a new KML for a project
         * @param {number} id 
         * @param {any} customKml 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNewKmlForProject: async (id: number, customKml: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling uploadNewKmlForProject.');
            }
            // verify required parameter 'customKml' is not null or undefined
            if (customKml === null || customKml === undefined) {
                throw new RequiredError('customKml','Required parameter customKml was null or undefined when calling uploadNewKmlForProject.');
            }
            const localVarPath = `/api/v1/projects/{id}/custom-kml`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();


            if (customKml !== undefined) { 
                localVarFormParams.append('customKml', customKml as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Delete an organization for the project
         * @summary delete an organization for a project
         * @param {number} projectId 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrganizationForProject(projectId: number, organizationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProjectApiAxiosParamCreator(configuration).deleteOrganizationForProject(projectId, organizationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete a project with cascade on all the elements
         * @summary Delete a project
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProjectApiAxiosParamCreator(configuration).deleteProjectById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete a teamMember for the project
         * @summary delete a teamMember for a project
         * @param {number} projectId 
         * @param {number} teamMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTeamMemberForProject(projectId: number, teamMemberId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProjectApiAxiosParamCreator(configuration).deleteTeamMemberForProject(projectId, teamMemberId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the details of a meeting
         * @summary Get meeting details
         * @param {number} projectId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeetingById(projectId: number, meetingId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingDetails>> {
            const localVarAxiosArgs = await ProjectApiAxiosParamCreator(configuration).getMeetingById(projectId, meetingId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the details of a project
         * @summary Get project details
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDetails>> {
            const localVarAxiosArgs = await ProjectApiAxiosParamCreator(configuration).getProjectById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get customer project list
         * @summary Get customer project list
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectListByCustomerId(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>>> {
            const localVarAxiosArgs = await ProjectApiAxiosParamCreator(configuration).getProjectListByCustomerId(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create a new meeting for the project
         * @summary create a meeting
         * @param {number} id 
         * @param {CreateOrUpdateMeeting} createOrUpdateMeeting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newMeeting(id: number, createOrUpdateMeeting: CreateOrUpdateMeeting, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingDetails>> {
            const localVarAxiosArgs = await ProjectApiAxiosParamCreator(configuration).newMeeting(id, createOrUpdateMeeting, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create an organization for the project
         * @summary create an organization for a project
         * @param {number} id 
         * @param {CreateUpdateProjectOrganization} createUpdateProjectOrganization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newOrganizationForProject(id: number, createUpdateProjectOrganization: CreateUpdateProjectOrganization, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingDetails>> {
            const localVarAxiosArgs = await ProjectApiAxiosParamCreator(configuration).newOrganizationForProject(id, createUpdateProjectOrganization, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Upload a new planning
         * @summary Create a new planning
         * @param {number} id 
         * @param {string} name 
         * @param {any} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newPlanningForProject(id: number, name: string, fileName?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDetails>> {
            const localVarAxiosArgs = await ProjectApiAxiosParamCreator(configuration).newPlanningForProject(id, name, fileName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create a new project
         * @summary create a project
         * @param {number} id 
         * @param {CreateProject} createProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newProject(id: number, createProject: CreateProject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await ProjectApiAxiosParamCreator(configuration).newProject(id, createProject, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create a new teamMember for the project
         * @summary create a teamMember for a project
         * @param {number} id 
         * @param {CreateOrUpdateUser} createOrUpdateUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newTeamUser(id: number, createOrUpdateUser: CreateOrUpdateUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingDetails>> {
            const localVarAxiosArgs = await ProjectApiAxiosParamCreator(configuration).newTeamUser(id, createOrUpdateUser, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Request access to the project
         * @summary Request access to project
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestAccessToProjectById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProjectApiAxiosParamCreator(configuration).requestAccessToProjectById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Ureate a new teamMember for the project
         * @summary update a teamMember for a project
         * @param {number} projectId 
         * @param {number} teamMemberId 
         * @param {CreateOrUpdateUser} createOrUpdateUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateATeamUser(projectId: number, teamMemberId: number, createOrUpdateUser: CreateOrUpdateUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingDetails>> {
            const localVarAxiosArgs = await ProjectApiAxiosParamCreator(configuration).updateATeamUser(projectId, teamMemberId, createOrUpdateUser, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update a meeting of a project by id
         * @summary Update a meeting by id
         * @param {number} projectId 
         * @param {number} meetingId 
         * @param {CreateOrUpdateMeeting} createOrUpdateMeeting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMeetingById(projectId: number, meetingId: number, createOrUpdateMeeting: CreateOrUpdateMeeting, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeetingDetails>> {
            const localVarAxiosArgs = await ProjectApiAxiosParamCreator(configuration).updateMeetingById(projectId, meetingId, createOrUpdateMeeting, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update an organization for the project
         * @summary update an organization for a project
         * @param {number} projectId 
         * @param {number} organizationId 
         * @param {CreateUpdateProjectOrganization} createUpdateProjectOrganization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganizationForProject(projectId: number, organizationId: number, createUpdateProjectOrganization: CreateUpdateProjectOrganization, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectOrganization>> {
            const localVarAxiosArgs = await ProjectApiAxiosParamCreator(configuration).updateOrganizationForProject(projectId, organizationId, createUpdateProjectOrganization, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update a project by id
         * @summary Update a project
         * @param {number} id 
         * @param {UpdateProject} updateProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProjectById(id: number, updateProject: UpdateProject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDetails>> {
            const localVarAxiosArgs = await ProjectApiAxiosParamCreator(configuration).updateProjectById(id, updateProject, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Upload a new Kml for a project this will replace the old one
         * @summary Upload a new KML for a project
         * @param {number} id 
         * @param {any} customKml 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadNewKmlForProject(id: number, customKml: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDetails>> {
            const localVarAxiosArgs = await ProjectApiAxiosParamCreator(configuration).uploadNewKmlForProject(id, customKml, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Delete an organization for the project
         * @summary delete an organization for a project
         * @param {number} projectId 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationForProject(projectId: number, organizationId: number, options?: any): AxiosPromise<void> {
            return ProjectApiFp(configuration).deleteOrganizationForProject(projectId, organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a project with cascade on all the elements
         * @summary Delete a project
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectById(id: number, options?: any): AxiosPromise<void> {
            return ProjectApiFp(configuration).deleteProjectById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a teamMember for the project
         * @summary delete a teamMember for a project
         * @param {number} projectId 
         * @param {number} teamMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeamMemberForProject(projectId: number, teamMemberId: number, options?: any): AxiosPromise<void> {
            return ProjectApiFp(configuration).deleteTeamMemberForProject(projectId, teamMemberId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the details of a meeting
         * @summary Get meeting details
         * @param {number} projectId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingById(projectId: number, meetingId: number, options?: any): AxiosPromise<MeetingDetails> {
            return ProjectApiFp(configuration).getMeetingById(projectId, meetingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the details of a project
         * @summary Get project details
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById(id: number, options?: any): AxiosPromise<ProjectDetails> {
            return ProjectApiFp(configuration).getProjectById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get customer project list
         * @summary Get customer project list
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectListByCustomerId(id: number, options?: any): AxiosPromise<Array<Project>> {
            return ProjectApiFp(configuration).getProjectListByCustomerId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new meeting for the project
         * @summary create a meeting
         * @param {number} id 
         * @param {CreateOrUpdateMeeting} createOrUpdateMeeting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newMeeting(id: number, createOrUpdateMeeting: CreateOrUpdateMeeting, options?: any): AxiosPromise<MeetingDetails> {
            return ProjectApiFp(configuration).newMeeting(id, createOrUpdateMeeting, options).then((request) => request(axios, basePath));
        },
        /**
         * Create an organization for the project
         * @summary create an organization for a project
         * @param {number} id 
         * @param {CreateUpdateProjectOrganization} createUpdateProjectOrganization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newOrganizationForProject(id: number, createUpdateProjectOrganization: CreateUpdateProjectOrganization, options?: any): AxiosPromise<MeetingDetails> {
            return ProjectApiFp(configuration).newOrganizationForProject(id, createUpdateProjectOrganization, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload a new planning
         * @summary Create a new planning
         * @param {number} id 
         * @param {string} name 
         * @param {any} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newPlanningForProject(id: number, name: string, fileName?: any, options?: any): AxiosPromise<ProjectDetails> {
            return ProjectApiFp(configuration).newPlanningForProject(id, name, fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new project
         * @summary create a project
         * @param {number} id 
         * @param {CreateProject} createProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newProject(id: number, createProject: CreateProject, options?: any): AxiosPromise<Project> {
            return ProjectApiFp(configuration).newProject(id, createProject, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new teamMember for the project
         * @summary create a teamMember for a project
         * @param {number} id 
         * @param {CreateOrUpdateUser} createOrUpdateUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newTeamUser(id: number, createOrUpdateUser: CreateOrUpdateUser, options?: any): AxiosPromise<MeetingDetails> {
            return ProjectApiFp(configuration).newTeamUser(id, createOrUpdateUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Request access to the project
         * @summary Request access to project
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAccessToProjectById(id: number, options?: any): AxiosPromise<void> {
            return ProjectApiFp(configuration).requestAccessToProjectById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Ureate a new teamMember for the project
         * @summary update a teamMember for a project
         * @param {number} projectId 
         * @param {number} teamMemberId 
         * @param {CreateOrUpdateUser} createOrUpdateUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateATeamUser(projectId: number, teamMemberId: number, createOrUpdateUser: CreateOrUpdateUser, options?: any): AxiosPromise<MeetingDetails> {
            return ProjectApiFp(configuration).updateATeamUser(projectId, teamMemberId, createOrUpdateUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a meeting of a project by id
         * @summary Update a meeting by id
         * @param {number} projectId 
         * @param {number} meetingId 
         * @param {CreateOrUpdateMeeting} createOrUpdateMeeting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeetingById(projectId: number, meetingId: number, createOrUpdateMeeting: CreateOrUpdateMeeting, options?: any): AxiosPromise<MeetingDetails> {
            return ProjectApiFp(configuration).updateMeetingById(projectId, meetingId, createOrUpdateMeeting, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an organization for the project
         * @summary update an organization for a project
         * @param {number} projectId 
         * @param {number} organizationId 
         * @param {CreateUpdateProjectOrganization} createUpdateProjectOrganization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationForProject(projectId: number, organizationId: number, createUpdateProjectOrganization: CreateUpdateProjectOrganization, options?: any): AxiosPromise<ProjectOrganization> {
            return ProjectApiFp(configuration).updateOrganizationForProject(projectId, organizationId, createUpdateProjectOrganization, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a project by id
         * @summary Update a project
         * @param {number} id 
         * @param {UpdateProject} updateProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectById(id: number, updateProject: UpdateProject, options?: any): AxiosPromise<ProjectDetails> {
            return ProjectApiFp(configuration).updateProjectById(id, updateProject, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload a new Kml for a project this will replace the old one
         * @summary Upload a new KML for a project
         * @param {number} id 
         * @param {any} customKml 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNewKmlForProject(id: number, customKml: any, options?: any): AxiosPromise<ProjectDetails> {
            return ProjectApiFp(configuration).uploadNewKmlForProject(id, customKml, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * Delete an organization for the project
     * @summary delete an organization for a project
     * @param {number} projectId 
     * @param {number} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public deleteOrganizationForProject(projectId: number, organizationId: number, options?: any) {
        return ProjectApiFp(this.configuration).deleteOrganizationForProject(projectId, organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a project with cascade on all the elements
     * @summary Delete a project
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public deleteProjectById(id: number, options?: any) {
        return ProjectApiFp(this.configuration).deleteProjectById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a teamMember for the project
     * @summary delete a teamMember for a project
     * @param {number} projectId 
     * @param {number} teamMemberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public deleteTeamMemberForProject(projectId: number, teamMemberId: number, options?: any) {
        return ProjectApiFp(this.configuration).deleteTeamMemberForProject(projectId, teamMemberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the details of a meeting
     * @summary Get meeting details
     * @param {number} projectId 
     * @param {number} meetingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getMeetingById(projectId: number, meetingId: number, options?: any) {
        return ProjectApiFp(this.configuration).getMeetingById(projectId, meetingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the details of a project
     * @summary Get project details
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjectById(id: number, options?: any) {
        return ProjectApiFp(this.configuration).getProjectById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get customer project list
     * @summary Get customer project list
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjectListByCustomerId(id: number, options?: any) {
        return ProjectApiFp(this.configuration).getProjectListByCustomerId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new meeting for the project
     * @summary create a meeting
     * @param {number} id 
     * @param {CreateOrUpdateMeeting} createOrUpdateMeeting 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public newMeeting(id: number, createOrUpdateMeeting: CreateOrUpdateMeeting, options?: any) {
        return ProjectApiFp(this.configuration).newMeeting(id, createOrUpdateMeeting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an organization for the project
     * @summary create an organization for a project
     * @param {number} id 
     * @param {CreateUpdateProjectOrganization} createUpdateProjectOrganization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public newOrganizationForProject(id: number, createUpdateProjectOrganization: CreateUpdateProjectOrganization, options?: any) {
        return ProjectApiFp(this.configuration).newOrganizationForProject(id, createUpdateProjectOrganization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload a new planning
     * @summary Create a new planning
     * @param {number} id 
     * @param {string} name 
     * @param {any} [fileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public newPlanningForProject(id: number, name: string, fileName?: any, options?: any) {
        return ProjectApiFp(this.configuration).newPlanningForProject(id, name, fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new project
     * @summary create a project
     * @param {number} id 
     * @param {CreateProject} createProject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public newProject(id: number, createProject: CreateProject, options?: any) {
        return ProjectApiFp(this.configuration).newProject(id, createProject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new teamMember for the project
     * @summary create a teamMember for a project
     * @param {number} id 
     * @param {CreateOrUpdateUser} createOrUpdateUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public newTeamUser(id: number, createOrUpdateUser: CreateOrUpdateUser, options?: any) {
        return ProjectApiFp(this.configuration).newTeamUser(id, createOrUpdateUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request access to the project
     * @summary Request access to project
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public requestAccessToProjectById(id: number, options?: any) {
        return ProjectApiFp(this.configuration).requestAccessToProjectById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ureate a new teamMember for the project
     * @summary update a teamMember for a project
     * @param {number} projectId 
     * @param {number} teamMemberId 
     * @param {CreateOrUpdateUser} createOrUpdateUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public updateATeamUser(projectId: number, teamMemberId: number, createOrUpdateUser: CreateOrUpdateUser, options?: any) {
        return ProjectApiFp(this.configuration).updateATeamUser(projectId, teamMemberId, createOrUpdateUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a meeting of a project by id
     * @summary Update a meeting by id
     * @param {number} projectId 
     * @param {number} meetingId 
     * @param {CreateOrUpdateMeeting} createOrUpdateMeeting 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public updateMeetingById(projectId: number, meetingId: number, createOrUpdateMeeting: CreateOrUpdateMeeting, options?: any) {
        return ProjectApiFp(this.configuration).updateMeetingById(projectId, meetingId, createOrUpdateMeeting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an organization for the project
     * @summary update an organization for a project
     * @param {number} projectId 
     * @param {number} organizationId 
     * @param {CreateUpdateProjectOrganization} createUpdateProjectOrganization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public updateOrganizationForProject(projectId: number, organizationId: number, createUpdateProjectOrganization: CreateUpdateProjectOrganization, options?: any) {
        return ProjectApiFp(this.configuration).updateOrganizationForProject(projectId, organizationId, createUpdateProjectOrganization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a project by id
     * @summary Update a project
     * @param {number} id 
     * @param {UpdateProject} updateProject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public updateProjectById(id: number, updateProject: UpdateProject, options?: any) {
        return ProjectApiFp(this.configuration).updateProjectById(id, updateProject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload a new Kml for a project this will replace the old one
     * @summary Upload a new KML for a project
     * @param {number} id 
     * @param {any} customKml 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public uploadNewKmlForProject(id: number, customKml: any, options?: any) {
        return ProjectApiFp(this.configuration).uploadNewKmlForProject(id, customKml, options).then((request) => request(this.axios, this.basePath));
    }

}
