import Vue from "vue";
import SelectableLine from '@/components/SelectableLine.vue'
import ManageSatellites from '@/views/ManageSatellites.vue'
import ManageGateways from '@/views/ManageGateways.vue'

export default Vue.extend({
  components: {
    SelectableLine,
    ManageSatellites,
    ManageGateways
  },
  props: {
    selectedIndexProp: Number
  },
  data() {
    return {
      items: items as Item[],
      selectedItemIndex: this.selectedIndexProp as number,
    }
  },
  methods: {
    selectItem(index) {
      this.selectedItemIndex = index
    }
  }
})

export interface Item {
  name: string
}

export const items: Item[] = [
  { name: 'Manage satellites' },
  { name: 'Manage gateways' }
]