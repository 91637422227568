import Vue from 'vue'

import Menu from '@/components/Menu.vue'
import UserInfo from '@/components/UserInfo.vue'

export default Vue.extend({
  name: 'Aside',
  data() {
    return {
    }
  },
  components: {
    Menu,
    UserInfo
  }
})