var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app_class" }, [
    !_vm.isErrorPage
      ? _c(
          "div",
          { staticClass: "main_container" },
          [
            _c("Aside"),
            _c("div", { staticClass: "main_col" }, [
              _c(
                "div",
                { staticClass: "col_right" },
                [_c("router-view", { staticClass: "router-view" })],
                1
              ),
            ]),
            _c("vue-snotify"),
          ],
          1
        )
      : _c("div", { staticClass: "main_container" }, [_c("router-view")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }