import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Snotify from "vue-snotify"
import Vuelidate from 'vuelidate'
import commons from '@/core/plugins/commons'
import * as msal from "@azure/msal-browser"
import amarisVueMsalPlugin from "./core/plugins/amarisVueMsalPlugin";

var conf = require('./config/config').EnvConfiguration

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { logger, log, LOG_TYPES } from '@/core/middlewares/Logger'

import 'vue-snotify/styles/material.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Title
document.title = `SkyT ${process.env.NODE_ENV}`

// Init Logger
logger({ VueInstance: Vue })

// Install BootstrapVue
Vue.use(BootstrapVue)

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

//Snotify module
Vue.use(Snotify)

Vue.use(Vuelidate)

Vue.use(commons)

// Log Environnement And Keycloak Informations
log({ VueInstance: Vue, type: LOG_TYPES.d, message: 'ENVIRONNEMENT: ' + process.env.NODE_ENV })

Vue.config.productionTip = false

Vue.use(amarisVueMsalPlugin, {
  auth: {
    authority: "https://login.microsoftonline.com/" + conf.TENANT,
    clientId: conf.CLIENT_ID,
    redirectUri: conf.REDIRECT_URI
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
          case msal.LogLevel.Info:
            console.info(message);
            return;
          case msal.LogLevel.Verbose:
            console.debug(message);
            return;
          case msal.LogLevel.Warning:
            console.warn(message);
            return;
        }
      }
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
