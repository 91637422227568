import {GET_ABOUT, GET_BEAM_CUSTOMISATION, GET_CONDITIONS, GET_COOKIES, GET_LEGAL_NOTICE} from "../aliases/getters.type"

import {
    ABOUT_FETCH,
    ABOUT_UPDATE,
    BEAM_CUSTOMISATION_FETCH,
    BEAM_CUSTOMISATION_UPDATE,
    CONDITIONS_FETCH,
    CONDITIONS_UPDATE,
    COOKIES_FETCH,
    COOKIES_UPDATE,
    LEGAL_NOTICE_FETCH,
    LEGAL_NOTICE_UPDATE
} from "../aliases/actions.type"

import {
    SET_ABOUT,
    SET_BEAM_CUSTOMISATION,
    SET_CONDITIONS,
    SET_COOKIES,
    SET_LEGAL_NOTICE
} from "../aliases/mutations.type"

import {getConfiguration} from '@/core/utils/config.util'

import {log, LOG_TYPES} from '@/core/middlewares/Logger'

import {displayMessage, ERROR_TYPES} from '@/core/middlewares/error'

import Vue from 'vue'
import {HtmlContent} from "@/core/models/html-content"
import {BeamCustomisation} from "@/core/models/beam-customisation"
import {SettingsApi} from "@/core/services/settings-api"

const namespaced = true

const defaultHtmlContent: HtmlContent = {
    html: ""
}

const defaultBeamCustomisation: BeamCustomisation = {
    isEnabled: false
}

export default {
    namespaced,
    state: {
        legalNotice: defaultHtmlContent,
        cookiesAndGDPR: defaultHtmlContent,
        conditionsOfUses: defaultHtmlContent,
        about: defaultHtmlContent,
        beamCustomisation: defaultBeamCustomisation
    },
    getters: {
        [GET_LEGAL_NOTICE]: (state) => {
            return state.legalNotice
        },
        [GET_COOKIES]: (state) => {
            return state.cookiesAndGDPR
        },
        [GET_CONDITIONS]: (state) => {
            return state.conditionsOfUses
        },
        [GET_ABOUT]: (state) => {
            return state.about
        },
        [GET_BEAM_CUSTOMISATION]: (state) => {
            return state.beamCustomisation
        }
    },
    actions: {
        [LEGAL_NOTICE_FETCH]({ commit }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Fetching current app legal notice.` })
            return getConfiguration().then(config => {
                return new SettingsApi(config).getLegalNotice()
            }).then(({ data }) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Legal notice returned.`
                })
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Legal notice : ${JSON.stringify(data)} returned successfully.`
                })
                commit(SET_LEGAL_NOTICE, { legalNotice: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while fetching legal notice.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
        [COOKIES_FETCH]({ commit }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Fetching current app cookies and gdpr.` })
            return getConfiguration().then(config => {
                return new SettingsApi(config).getCookiesPage()
            }).then(({ data }) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Cookies And GDPR returned.`
                })
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Cookies and GDPR : ${JSON.stringify(data)} returned successfully.`
                })
                commit(SET_COOKIES, { cookiesAndGDPR: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while fetching cookies.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
        [CONDITIONS_FETCH]({ commit }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Fetching current app conditions of uses.` })
            return getConfiguration().then(config => {
                return new SettingsApi(config).getConditionsOfUsesPage()
            }).then(({ data }) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Conditions of uses returned.`
                })
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Conditions of uses : ${JSON.stringify(data)} returned successfully.`
                })
                commit(SET_CONDITIONS, { conditionsOfUses: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while fetching conditions of uses.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
        [ABOUT_FETCH]({ commit }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Fetching current app about page.` })
            return getConfiguration().then(config => {
                return new SettingsApi(config).getAboutPage()
            }).then(({ data }) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `about page returned.`
                })
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `About : ${JSON.stringify(data)} returned successfully.`
                })
                commit(SET_ABOUT, { about: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while fetching about.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
        async [LEGAL_NOTICE_UPDATE]({ commit }, { html }: { html: string}) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Update legalNotice ${html}` })
            return getConfiguration().then(config => {
                return new SettingsApi(config).updateLegalNotice({
                    html: html
                })
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Update Legal notice updated successfully.`
                })
                displayMessage({ message: 'Legal notice updated successfully.', type: ERROR_TYPES.SUCCESS })
                commit(SET_LEGAL_NOTICE, { legalNotice: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while updating legal notice.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
        async [COOKIES_UPDATE]({ commit }, { html }: { html: string}) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Update cookies ${html}` })
            return getConfiguration().then(config => {
                return new SettingsApi(config).updateCookiesPage({
                    html: html
                })
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Update cookies successfully.`
                })
                displayMessage({ message: 'cookies updated successfully.', type: ERROR_TYPES.SUCCESS })
                commit(SET_COOKIES, { cookiesAndGDPR: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while updating cookies.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
        async [CONDITIONS_UPDATE]({ commit }, { html }: { html: string}) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Update conditions of uses ${html}` })
            return getConfiguration().then(config => {
                return new SettingsApi(config).updateConditionsOfUsesPage({
                    html: html
                })
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Conditions of uses successfully updated.`
                })
                displayMessage({ message: 'Conditions of uses updated successfully.', type: ERROR_TYPES.SUCCESS })
                commit(SET_CONDITIONS, { conditionsOfUses: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while updating conditions of uses.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
        async [ABOUT_UPDATE]({ commit }, { html }: { html: string}) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Update about page ${html}` })
            return getConfiguration().then(config => {
                return new SettingsApi(config).updateAboutPage({
                    html: html
                })
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `about page updated successfully.`
                })
                displayMessage({ message: 'about page updated successfully.', type: ERROR_TYPES.SUCCESS })
                commit(SET_ABOUT, { about: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while updating about page.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
        [BEAM_CUSTOMISATION_FETCH]({ commit }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Fetching current app beam customisation status.` })
            return getConfiguration().then(config => {
                return new SettingsApi(config).getBeamCustomisation()
            }).then(({ data }) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Beam customisation returned.`
                })
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Beam customisation : ${JSON.stringify(data)} returned successfully.`
                })
                commit(SET_BEAM_CUSTOMISATION, { beamCustomisation: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while fetching beam customisationn.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
        async [BEAM_CUSTOMISATION_UPDATE]({ commit }, { isEnabled }: { isEnabled: boolean}) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Update Beam customisation ${isEnabled}` })
            return getConfiguration().then(config => {
                return new SettingsApi(config).updateBeamCustomisation({
                    isEnabled: isEnabled
                })
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Update Beam customisation updated successfully.`
                })
                displayMessage({ message: 'Beam customisation updated successfully.', type: ERROR_TYPES.SUCCESS })
                commit(SET_LEGAL_NOTICE, { data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while updating legal notice.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
    },

    mutations: {
        [SET_LEGAL_NOTICE](state, { legalNotice }: { legalNotice: HtmlContent }) {
            state.legalNotice = legalNotice
        },
        [SET_COOKIES](state, { cookiesAndGDPR }: { cookiesAndGDPR: HtmlContent }) {
            state.cookiesAndGDPR = cookiesAndGDPR
        },
        [SET_CONDITIONS](state, { conditionsOfUses }: { conditionsOfUses: HtmlContent }) {
            state.conditionsOfUses = conditionsOfUses
        },
        [SET_ABOUT](state, { about }: { about: HtmlContent }) {
            state.about = about
        },
        [SET_BEAM_CUSTOMISATION](state, { beamCustomisation }: { beamCustomisation: BeamCustomisation }) {
            state.beamCustomisation = beamCustomisation
        }
    }
}
