import { SET_ITEM_TO_DELETE } from '@/store/aliases/mutations.type';
import { UTIL_TYPE } from '@/store/types';
import Vue from "vue";
import { mapMutations } from 'vuex';

export default Vue.extend({
  props: ["item", "isDeletable", "isDraggable"],
  data() {
    return {
    }
  },
  methods: {
    ...mapMutations({
      setItemToDelete: `${UTIL_TYPE}/${SET_ITEM_TO_DELETE}`
    }),
    handleDeletion() {
      this.setItemToDelete({ itemToDelete: this.item })
      this.showDeletePopup()
    }
  }
});