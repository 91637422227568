var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid persona_question_modal_component" },
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "delete-component",
            title: "Delete " + _vm.source,
            "hide-footer": "",
          },
        },
        [
          _c("p", [
            _vm._v(
              "Are you sure you want to delete the " +
                _vm._s(_vm.source) +
                ' "' +
                _vm._s(_vm.getNameToDisplay) +
                '"?'
            ),
          ]),
          _c("SaveCancelButtons", {
            attrs: { updating: _vm.updating, saveLabel: "Delete" },
            on: { cancel: _vm.onCancel, save: _vm.onSubmit },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }