import Vue from "vue";
import DeleteModal from '@/components/DeleteModal.vue'
import draggable from 'vuedraggable'
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { REFERENTIAL_TYPE, UTIL_TYPE } from '@/store/types';
import { SET_ITEM_TO_DELETE } from '@/store/aliases/mutations.type';
import { GET_MISSION_COLORS } from '@/store/aliases/getters.type';
import { MISSION_COLORS_FETCH, MISSION_COLOR_CREATE, MISSION_COLOR_DELETE, MISSION_COLOR_UPDATE, MISSION_COLOR_UPDATE_INDEX } from '@/store/aliases/actions.type';

const isHexColor = (missionColor) => /^#[0-9A-F]{6}$/i.test(missionColor.color)

export default Vue.extend({
    components: {
        DeleteModal,
        draggable
    },
    data() {
        return {
            newColor: { color: undefined }
        }
    },
    validations: {
        newColor: {
            isHexColor
        },
        getMissionsColors: {
            $each: {
                isHexColor
            }
        }
    },
    mounted() {
        this.fetchMissionsColors()
    },
    computed: {
        ...mapGetters({
            getMissionsColors: `${REFERENTIAL_TYPE}/${GET_MISSION_COLORS}`
        })
    },
    methods: {
        ...mapActions({
            fetchMissionsColors: `${REFERENTIAL_TYPE}/${MISSION_COLORS_FETCH}`,
            createMissionColor: `${REFERENTIAL_TYPE}/${MISSION_COLOR_CREATE}`,
            updateMissionColor: `${REFERENTIAL_TYPE}/${MISSION_COLOR_UPDATE}`,
            updateMissionColorIndex: `${REFERENTIAL_TYPE}/${MISSION_COLOR_UPDATE_INDEX}`,
            deleteMissionColor: `${REFERENTIAL_TYPE}/${MISSION_COLOR_DELETE}`
        }),
        ...mapMutations({
            setItemToDelete: `${UTIL_TYPE}/${SET_ITEM_TO_DELETE}`
        }),
        handleDeletion(missionColor) {
            this.setItemToDelete({ itemToDelete: missionColor })
            this.showDeletePopup()
        },
        deleteComponent(missionColor) {
           this.deleteMissionColor({ missionColorId: missionColor.id })
        },
        updateIndex(event) {
            this.updateMissionColorIndex({ missionColorId: event.moved.element.id, index: event.moved.newIndex })
        },
        async addColor(missionColor) {
            this.$v.newColor.$touch()
            if (!this.$v.newColor.$invalid) {
                await this.createMissionColor({ missionColor: missionColor })
                this.$v.newColor.$reset()
                this.newColor = { color: undefined }
            }
        },
        updateColor(missionColor, index) {
            this.$v.getMissionsColors.$each[index].$touch()
            if (!this.$v.getMissionsColors.$each[index].$invalid) {
                this.updateMissionColor({ missionColor: missionColor })
            }
        }
    }
})
