import Vue from 'vue'
import router from '@/router/index'

export enum ERROR_TYPES {
    ERROR = 'error',
    WARN = 'warning',
    SUCCESS = 'success',
    INFO = 'info'
}

export const displayMessage = ({ message, type }) => {
    Vue.prototype.$snotify[type](message, {
        showProgressBar: false,
        timeout: 3000,
    }) 
}

export function handleApiFetchError(error) {
    switch (error.status) {
        case 403:
            router.push({ name: 'Forbidden', params: { error : 'true' } } )
            break
        case 404:
            router.push({ name: 'PageNotFound' })
            break
        default:
            displayMessage({ message: 'An error occured.', type: ERROR_TYPES.ERROR })
    }
}