
import Vue from "vue";


export default Vue.extend({
    props: {
        saveLabel: {
            default: "Save",
            type: String
        },
        cancelLabel: {
            default: "Cancel",
            type: String
        },
        updating: {
            default: false,
            type: Boolean
        },
        disabled: {
            default: false,
            type: Boolean
        }
    },
    methods: {
        onCancel(evt) {
            evt.preventDefault();
            this.$emit("cancel", evt);
        },
        onSubmit(evt) {
            evt.preventDefault();
            this.$emit("save", evt);
        }
    }
});