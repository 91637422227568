import {
    GET_PERSONAS,
    GET_PERSONA
} from "../aliases/getters.type"

import {
    PERSONAS_FETCH,
    PERSONA_FETCH,
    PERSONA_CREATE,
    PERSONA_DELETE,
    PERSONA_UPDATE,
    PERSONA_INTERVIEW_FETCH,
    PERSONA_INTERVIEW_QUESTION_DELETE,
    PERSONA_INTERVIEW_QUESTION_FETCH,
    PERSONA_INTERVIEW_QUESTION_UPDATE,
} from "../aliases/actions.type"

import {
    SET_PERSONAS,
    SET_PERSONA,
    ADD_PERSONA,
    EDIT_PERSONA,
    REMOVE_PERSONA, REMOVE_OFFER
} from "../aliases/mutations.type"

import { 
    PersonaApi
} from '@/core/services/persona-api'

import { 
    PersonaExtended, 
    Persona 
} from '@/core/models'

import {
    getConfiguration
} from '@/core/utils/config.util'

import {
    log,
    LOG_TYPES
} from '@/core/middlewares/Logger'

import {
    displayMessage,
    ERROR_TYPES
} from '@/core/middlewares/error'

import _ from 'lodash'

import Vue from 'vue'
import {OfferApi} from "@/core"

const namespaced = true

const replacePersona = ({ state, persona }) => {
    const index = _.findIndex(state.personas, persona => persona.id === state.persona.id)
    if (index >= 0) {
        state.personas[index] = persona || state.persona
    }
}

export default {
    namespaced,
    state: {
        personas: [],
        persona: {},
        interview: {}
    },

    getters: {
        [GET_PERSONAS]: (state) => {
            return state.personas
        },
        [GET_PERSONA]: (state) => {
            return state.persona
        }
    },

    actions: {
        [PERSONAS_FETCH]({ commit }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Fetching available personas.` })
            return getConfiguration().then(config => {
                return new PersonaApi(config).fetchAllPersonas()
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Personas: ${data['personas'].length} returned.`
                })
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Personas: ${JSON.stringify(data['personas'])} returned successfully.`
                })
                commit(SET_PERSONAS, { personas: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while fetching personas.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
        [PERSONA_FETCH]({ commit }, { id }: { id: number }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Fetching persona by id: ${id}.` })
            return getConfiguration().then(config => {
                return new PersonaApi(config).fetchPersona(id)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `PersonaFetch: ${JSON.stringify(data)} returned successfully.`
                })
                commit(SET_PERSONA, { persona: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Cannot fetch persona.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        },
        async [PERSONA_CREATE]({ commit }, { persona }: { persona: PersonaExtended }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Adding next persona:  ${JSON.stringify(persona)}.` })
            return getConfiguration().then(config => {
                return new PersonaApi(config).createPersona(persona)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `PersonaAdd: ${JSON.stringify(persona)} created successfully.`
                })
                displayMessage({ message: 'Persona added successfully.', type: ERROR_TYPES.SUCCESS })
                commit(ADD_PERSONA, { persona })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while adding persona.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
        async [PERSONA_UPDATE]({ commit }, { persona }: { persona: PersonaExtended }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Adding next persona:  ${JSON.stringify(persona)}.` })
            return getConfiguration().then(config => {
                return new PersonaApi(config).updatePersona(persona.id, persona)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `PersonaUpdate: ${JSON.stringify(persona)} updated successfully.`
                })
                displayMessage({ message: 'Persona updated successfully.', type: ERROR_TYPES.SUCCESS })
                commit(EDIT_PERSONA, { persona })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while updating persona.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
                return false
            })
        },
        [PERSONA_DELETE]({ commit }, { id }: { id: number }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Deleting persona by id: ${id}.` })
            return getConfiguration().then(config => {
                return new PersonaApi(config).deletePersona(id)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `PersonaDelete: ${id} deleted successfully.`
                })
                displayMessage({ message: 'Persona deleted successfully.', type: ERROR_TYPES.SUCCESS })
                commit(REMOVE_PERSONA, { id })
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while deleting persona.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        }
    },

    mutations: {
        [SET_PERSONA](state, { persona }: { persona: PersonaExtended }) {
            state.persona = persona
        },
        [SET_PERSONAS](state, { personas }: { personas: Array<Persona> }) {
            state.personas = personas
        },
        [ADD_PERSONA](state, { persona }: { persona: PersonaExtended }) {
            state.personas.push(persona)
            state.persona = persona
        },
        [EDIT_PERSONA](state, { persona }: { persona: PersonaExtended }) {
            replacePersona({ state, persona })
            state.persona = persona
        },
        [REMOVE_PERSONA](state, { id }: { id: number }) {
            _.remove(state.personas, persona => persona.id == id)
            replacePersona({ state, persona: null })
        }
    }
}
