import Vue from "vue";
import { mapActions } from 'vuex';
import { REFERENTIAL_TYPE } from '@/store/types';
import { REFERENTIAL_UPLOAD } from '@/store/aliases/actions.type';
import { UploadReferentialData } from '@/core/models/upload-referential-data';
import { displayMessage, ERROR_TYPES } from '@/core/middlewares/error';

export default Vue.extend({
    data() {
        return {
            items: droplistsItems,
            referential: {
                userTerminal: null,
                userFrequency: null,
                service: null,
                gatewayFrequency: null,
                zooming: null,
                modCods: null,
                waveforms: null
            } as UploadReferentialData,
            uploadResponseType: null
        }
    },
    methods: {
        ...mapActions({
            uploadReferential: `${REFERENTIAL_TYPE}/${REFERENTIAL_UPLOAD}`,
        }),
        handleFile(e, itemKey) {
            let file = e.target.files[0]
            this.referential[itemKey] = file
            this.$refs[itemKey][0].innerText = file.name;
        },
        async uploadCsv() {
            if (isNoFileUploaded(this.$refs.fileUploadInput)) {
                displayMessage({ message: 'Please select at least one file.', type: ERROR_TYPES.INFO })
                return
            }

            let response = await this.uploadReferential({ referential: this.referential })

            this.uploadResponseType = handleUploadResponse(response)
        }
    }
})

function isNoFileUploaded(fileUploadInputs): boolean {
    let noFileUploaded = true
    fileUploadInputs.forEach(input => {
        if (input.value != "") {
            noFileUploaded = false
        }
    });
    return noFileUploaded
}

function handleUploadResponse(response) : string {
    if (Array.isArray(response)) {
        return 'SUCCESS'
    }
    else if (response.code === 'API-INTERNAL-SERVER-ERROR') {
        return "ERROR_CSV"
    }
    else if (response.code === 'API-BAD-PARAMETER-NUMBER') {
        return "ERROR_FORMAT"
    }
}

export interface SettingItem {
    name: string,
    id: string
}

export const droplistsItems: SettingItem[] = [
    { name: 'Gateways Frequency Band', id: 'gatewayFrequency' },
    { name: 'Services List', id: 'service' },
    { name: 'User Frequency Band', id: 'userFrequency' },
    { name: 'User Terminal', id: 'userTerminal' },
    { name: 'Zooming', id: 'zooming' },
    { name: 'Modcods', id: 'modCods' },
    { name: 'Waveforms', id: 'waveforms' },
]

