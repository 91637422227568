var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12 align_right" }, [
      _c("ul", { staticClass: "bloc_cta_style_02 flex" }, [
        _c("li", [
          _c(
            "a",
            {
              staticClass: "btn_secondary click_pointer",
              on: { click: _vm.onCancel },
            },
            [
              _vm._m(0),
              _c("span", { staticClass: "libelle" }, [
                _vm._v(_vm._s(_vm.cancelLabel)),
              ]),
            ]
          ),
        ]),
        _vm.disabled
          ? _c("li", [
              _c("button", { class: "btn_secondary" }, [
                _vm._m(1),
                _c("span", { staticClass: "libelle" }, [_vm._v("Save")]),
              ]),
            ])
          : _vm._e(),
        !_vm.disabled
          ? _c("li", [
              _c(
                "button",
                {
                  class: !_vm.updating ? "btn_primary" : "btn_secondary",
                  on: { click: _vm.onSubmit },
                },
                [
                  _vm.updating
                    ? _c("b-spinner", { attrs: { small: "" } })
                    : _vm._e(),
                  _vm.saveLabel === "Delete"
                    ? _c("span", { staticClass: "icon" }, [
                        _c("span", { staticClass: "icon_bin_white" }),
                      ])
                    : _c("span", { staticClass: "icon" }, [
                        _c("span", { staticClass: "icon_save" }),
                      ]),
                  _c("span", { staticClass: "libelle" }, [
                    _vm._v(_vm._s(_vm.saveLabel)),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("span", { staticClass: "icon_cross" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("span", { staticClass: "icon_cross" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }