var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bloc_data_management" }, [
    _c("h6", [_vm._v(_vm._s(_vm.projectExport.configuration.name))]),
    _vm.projectExport.exportedDataUrl
      ? _c("div", { staticClass: "blocdownload" }, [
          _c("span", [_vm._v("Download data from app export")]),
          _c(
            "a",
            {
              staticClass: "btn_primary",
              on: { click: _vm.downloadProjectExport },
            },
            [
              _c("span", { staticClass: "icon" }, [
                _c(
                  "svg",
                  {
                    staticStyle: {
                      "enable-background": "new 0 0 392.1 362.79",
                    },
                    attrs: {
                      version: "1.1",
                      xmlns: "http://www.w3.org/2000/svg",
                      "xmlns:xlink": "http://www.w3.org/1999/xlink",
                      x: "0px",
                      y: "0px",
                      viewBox: "0 0 392.1 362.79",
                      "xml:space": "preserve",
                    },
                  },
                  [
                    _c("g", [
                      _c("g", [
                        _c("g", [
                          _c("path", {
                            attrs: {
                              d: "M373.2,176.99c-6,0-10.8,4.8-10.8,10.8v98.24c0,26.72-21.76,48.4-48.4,48.4H77.04c-26.72,0-48.4-21.76-48.4-48.4v-99.84 c0-6-4.8-10.8-10.8-10.8s-10.8,4.8-10.8,10.8v99.84c0,38.64,31.44,70,70,70H314c38.64,0,70-31.44,70-70v-98.24 C384,181.87,379.2,176.99,373.2,176.99L373.2,176.99z",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d: "M314,361.03H77.04c-41.35,0-75-33.64-75-75v-99.84c0-8.71,7.09-15.8,15.8-15.8s15.8,7.09,15.8,15.8v99.84 c0,23.93,19.47,43.4,43.4,43.4H314c23.93,0,43.4-19.47,43.4-43.4v-98.24c0-8.71,7.09-15.8,15.8-15.8s15.8,7.09,15.8,15.8v98.24 C389,327.39,355.35,361.03,314,361.03z M17.84,180.39c-3.25,0-5.8,2.55-5.8,5.8v99.84c0,35.84,29.16,65,65,65H314 c35.84,0,65-29.16,65-65v-98.24c0-3.2-2.6-5.8-5.8-5.8c-3.25,0-5.8,2.55-5.8,5.8v98.24c0,29.45-23.95,53.4-53.4,53.4H77.04 c-29.44,0-53.4-23.96-53.4-53.4v-99.84C23.64,182.94,21.09,180.39,17.84,180.39z",
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("g", [
                        _c("path", {
                          attrs: {
                            d: "M256.58,186.35l-50.24,50.24V17.47c0-6-4.8-10.8-10.8-10.8s-10.8,4.8-10.8,10.8v219.12l-50.24-50.24 c-2.08-2.08-4.88-3.2-7.6-3.2c-2.8,0-5.52,1.04-7.6,3.2c-4.24,4.24-4.24,11.04,0,15.28l68.64,68.64c2,2,4.8,3.2,7.6,3.2 c2.88,0,5.6-1.12,7.6-3.2l68.64-68.64c4.24-4.24,4.24-11.04,0-15.28C267.62,182.19,260.74,182.19,256.58,186.35L256.58,186.35z",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M195.54,1.67c8.71,0,15.8,7.09,15.8,15.8v207.05l41.7-41.71c6.14-6.14,16.13-6.14,22.27,0c6.16,6.17,6.16,16.19,0,22.35 l-68.64,68.64c-2.87,2.98-6.85,4.66-11.14,4.66c-4.11,0-8.17-1.7-11.13-4.66l-68.64-68.64c-3-2.99-4.65-6.96-4.65-11.18 c0-4.21,1.65-8.18,4.65-11.18c2.87-2.98,6.85-4.66,11.13-4.66c4.11,0,8.17,1.7,11.13,4.66l41.71,41.71V17.47 C179.74,8.76,186.83,1.67,195.54,1.67z M201.34,248.66V17.47c0-3.25-2.55-5.8-5.8-5.8s-5.8,2.55-5.8,5.8v231.19l-58.77-58.78 c-1.09-1.08-2.61-1.73-4.06-1.73c-1.09,0-2.67,0.29-3.99,1.67c-1.17,1.18-1.78,2.63-1.78,4.18c0,1.54,0.61,3,1.72,4.1l68.64,68.64 c1.09,1.09,2.6,1.73,4.06,1.73c1.54,0,2.96-0.59,4-1.67l68.71-68.71c2.3-2.3,2.3-5.91,0-8.21c-2.2-2.2-5.93-2.2-8.13,0 L201.34,248.66z",
                          },
                        }),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _c("span", { staticClass: "libelle" }, [_vm._v("Download")]),
            ]
          ),
          _c("hr"),
        ])
      : _vm._e(),
    _c("div", { staticClass: "blocupload" }, [
      _c("span", { staticClass: "titleupload" }, [
        _vm._v("Upload data results"),
      ]),
      _c("ul", [
        _c("li", [
          _c("input", {
            staticClass: "inputfile inputfile-2",
            attrs: { id: "uploadXml", type: "file", accept: ".xml" },
            on: {
              change: function ($event) {
                return _vm.handleXmlUpload($event)
              },
            },
          }),
          _c("label", { attrs: { for: "uploadXml" } }, [
            _c(
              "svg",
              {
                staticStyle: { "enable-background": "new 0 0 392.103 362.787" },
                attrs: {
                  version: "1.1",
                  xmlns: "http://www.w3.org/2000/svg",
                  "xmlns:xlink": "http://www.w3.org/1999/xlink",
                  x: "0px",
                  y: "0px",
                  viewBox: "0 0 392.103 362.787",
                  "xml:space": "preserve",
                },
              },
              [
                _c("g", [
                  _c("g", [
                    _c("g", [
                      _c("path", {
                        attrs: {
                          d: "M373.195,176.99c-6,0-10.801,4.801-10.801,10.801v98.238c0,26.719-21.757,48.403-48.398,48.403H77.035 c-26.719,0-48.398-21.762-48.398-48.403V186.19c0-6-4.801-10.801-10.801-10.801S7.035,180.19,7.035,186.19v99.839 c0,38.641,31.441,70,70,70h236.961c38.641,0,70-31.437,70-70v-98.238C383.996,181.869,379.195,176.99,373.195,176.99 L373.195,176.99z M373.195,176.99",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M313.996,361.029H77.035c-41.355,0-75-33.645-75-75V186.19c0-8.713,7.088-15.801,15.801-15.801 c8.713,0,15.801,7.088,15.801,15.801v99.839c0,23.933,19.468,43.403,43.398,43.403h236.961c23.93,0,43.398-19.471,43.398-43.403 v-98.238c0-8.712,7.088-15.801,15.801-15.801s15.801,7.088,15.801,15.801v98.238 C388.996,327.385,355.352,361.029,313.996,361.029z M17.836,180.389c-3.253,0-5.801,2.548-5.801,5.801v99.839 c0,35.841,29.159,65,65,65h236.961c35.841,0,65-29.159,65-65v-98.238c0-3.198-2.603-5.801-5.801-5.801 c-3.253,0-5.801,2.548-5.801,5.801v98.238c0,29.446-23.954,53.403-53.398,53.403H77.035c-29.444,0-53.398-23.957-53.398-53.403 V186.19C23.637,182.937,21.089,180.389,17.836,180.389z",
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("g", [
                    _c("path", {
                      attrs: {
                        d: "M134.476,93.791l50.239-50.242V262.67c0,6,4.8,10.801,10.8,10.801s10.801-4.801,10.801-10.801V43.549l50.239,50.242 c2.082,2.078,4.878,3.199,7.601,3.199c2.801,0,5.52-1.039,7.598-3.199c4.242-4.242,4.242-11.043,0-15.281L203.113,9.869 c-2-2-4.797-3.199-7.598-3.199c-2.878,0-5.601,1.121-7.601,3.199L119.273,78.51c-4.238,4.238-4.238,11.039,0,15.281 C123.433,97.951,130.316,97.951,134.476,93.791L134.476,93.791z M134.476,93.791",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M195.515,278.472c-8.712,0-15.8-7.089-15.8-15.802V55.621l-41.704,41.706c-6.141,6.141-16.133,6.141-22.273,0 c-6.16-6.167-6.16-16.193,0-22.352l68.641-68.642c2.87-2.983,6.849-4.663,11.137-4.663c4.112,0,8.17,1.7,11.133,4.663 l68.641,68.641c2.996,2.993,4.646,6.962,4.646,11.175c0,4.212-1.65,8.182-4.646,11.178c-2.868,2.982-6.847,4.663-11.133,4.663 c-4.108,0-8.166-1.698-11.134-4.66l-41.706-41.709V262.67C211.317,271.383,204.228,278.472,195.515,278.472z M189.716,31.477 V262.67c0,3.253,2.547,5.802,5.8,5.802c3.253,0,5.801-2.549,5.801-5.802V31.477l58.774,58.779 c1.086,1.084,2.607,1.734,4.065,1.734c1.091,0,2.67-0.289,3.995-1.666c1.175-1.176,1.784-2.634,1.784-4.175 c0-1.54-0.609-2.997-1.716-4.102l-68.642-68.643c-1.086-1.086-2.605-1.734-4.063-1.734c-1.544,0-2.964,0.592-3.999,1.667 l-68.708,68.709c-2.301,2.301-2.3,5.908,0.002,8.212c2.202,2.202,5.926,2.201,8.13-0.002L189.716,31.477z",
                      },
                    }),
                  ]),
                ]),
              ]
            ),
            _c("span", { staticClass: "hackfontsize" }, [
              _vm._v("Choose your xml import"),
            ]),
          ]),
        ]),
        _c("li", [
          _c("input", {
            staticClass: "inputfile inputfile-2",
            attrs: { id: "uploadLkb", type: "file", accept: ".xml" },
            on: {
              change: function ($event) {
                return _vm.handleLkbUpload($event)
              },
            },
          }),
          _c("label", { attrs: { for: "uploadLkb" } }, [
            _c(
              "svg",
              {
                staticStyle: { "enable-background": "new 0 0 392.103 362.787" },
                attrs: {
                  version: "1.1",
                  xmlns: "http://www.w3.org/2000/svg",
                  "xmlns:xlink": "http://www.w3.org/1999/xlink",
                  x: "0px",
                  y: "0px",
                  viewBox: "0 0 392.103 362.787",
                  "xml:space": "preserve",
                },
              },
              [
                _c("g", [
                  _c("g", [
                    _c("g", [
                      _c("path", {
                        attrs: {
                          d: "M373.195,176.99c-6,0-10.801,4.801-10.801,10.801v98.238c0,26.719-21.757,48.403-48.398,48.403H77.035 c-26.719,0-48.398-21.762-48.398-48.403V186.19c0-6-4.801-10.801-10.801-10.801S7.035,180.19,7.035,186.19v99.839 c0,38.641,31.441,70,70,70h236.961c38.641,0,70-31.437,70-70v-98.238C383.996,181.869,379.195,176.99,373.195,176.99 L373.195,176.99z M373.195,176.99",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M313.996,361.029H77.035c-41.355,0-75-33.645-75-75V186.19c0-8.713,7.088-15.801,15.801-15.801 c8.713,0,15.801,7.088,15.801,15.801v99.839c0,23.933,19.468,43.403,43.398,43.403h236.961c23.93,0,43.398-19.471,43.398-43.403 v-98.238c0-8.712,7.088-15.801,15.801-15.801s15.801,7.088,15.801,15.801v98.238 C388.996,327.385,355.352,361.029,313.996,361.029z M17.836,180.389c-3.253,0-5.801,2.548-5.801,5.801v99.839 c0,35.841,29.159,65,65,65h236.961c35.841,0,65-29.159,65-65v-98.238c0-3.198-2.603-5.801-5.801-5.801 c-3.253,0-5.801,2.548-5.801,5.801v98.238c0,29.446-23.954,53.403-53.398,53.403H77.035c-29.444,0-53.398-23.957-53.398-53.403 V186.19C23.637,182.937,21.089,180.389,17.836,180.389z",
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("g", [
                    _c("path", {
                      attrs: {
                        d: "M134.476,93.791l50.239-50.242V262.67c0,6,4.8,10.801,10.8,10.801s10.801-4.801,10.801-10.801V43.549l50.239,50.242 c2.082,2.078,4.878,3.199,7.601,3.199c2.801,0,5.52-1.039,7.598-3.199c4.242-4.242,4.242-11.043,0-15.281L203.113,9.869 c-2-2-4.797-3.199-7.598-3.199c-2.878,0-5.601,1.121-7.601,3.199L119.273,78.51c-4.238,4.238-4.238,11.039,0,15.281 C123.433,97.951,130.316,97.951,134.476,93.791L134.476,93.791z M134.476,93.791",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M195.515,278.472c-8.712,0-15.8-7.089-15.8-15.802V55.621l-41.704,41.706c-6.141,6.141-16.133,6.141-22.273,0 c-6.16-6.167-6.16-16.193,0-22.352l68.641-68.642c2.87-2.983,6.849-4.663,11.137-4.663c4.112,0,8.17,1.7,11.133,4.663 l68.641,68.641c2.996,2.993,4.646,6.962,4.646,11.175c0,4.212-1.65,8.182-4.646,11.178c-2.868,2.982-6.847,4.663-11.133,4.663 c-4.108,0-8.166-1.698-11.134-4.66l-41.706-41.709V262.67C211.317,271.383,204.228,278.472,195.515,278.472z M189.716,31.477 V262.67c0,3.253,2.547,5.802,5.8,5.802c3.253,0,5.801-2.549,5.801-5.802V31.477l58.774,58.779 c1.086,1.084,2.607,1.734,4.065,1.734c1.091,0,2.67-0.289,3.995-1.666c1.175-1.176,1.784-2.634,1.784-4.175 c0-1.54-0.609-2.997-1.716-4.102l-68.642-68.643c-1.086-1.086-2.605-1.734-4.063-1.734c-1.544,0-2.964,0.592-3.999,1.667 l-68.708,68.709c-2.301,2.301-2.3,5.908,0.002,8.212c2.202,2.202,5.926,2.201,8.13-0.002L189.716,31.477z",
                      },
                    }),
                  ]),
                ]),
              ]
            ),
            _c("span", { staticClass: "hackfontsize" }, [
              _vm._v("Choose your lkb files (.csv)"),
            ]),
          ]),
        ]),
        _c("li", [
          _c("input", {
            staticClass: "inputfile inputfile-2",
            attrs: { id: "uploadConso", type: "file", accept: ".xml" },
            on: {
              change: function ($event) {
                return _vm.handleConsoUpload($event)
              },
            },
          }),
          _c("label", { attrs: { for: "uploadConso" } }, [
            _c(
              "svg",
              {
                staticStyle: { "enable-background": "new 0 0 392.103 362.787" },
                attrs: {
                  version: "1.1",
                  xmlns: "http://www.w3.org/2000/svg",
                  "xmlns:xlink": "http://www.w3.org/1999/xlink",
                  x: "0px",
                  y: "0px",
                  viewBox: "0 0 392.103 362.787",
                  "xml:space": "preserve",
                },
              },
              [
                _c("g", [
                  _c("g", [
                    _c("g", [
                      _c("path", {
                        attrs: {
                          d: "M373.195,176.99c-6,0-10.801,4.801-10.801,10.801v98.238c0,26.719-21.757,48.403-48.398,48.403H77.035 c-26.719,0-48.398-21.762-48.398-48.403V186.19c0-6-4.801-10.801-10.801-10.801S7.035,180.19,7.035,186.19v99.839 c0,38.641,31.441,70,70,70h236.961c38.641,0,70-31.437,70-70v-98.238C383.996,181.869,379.195,176.99,373.195,176.99 L373.195,176.99z M373.195,176.99",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M313.996,361.029H77.035c-41.355,0-75-33.645-75-75V186.19c0-8.713,7.088-15.801,15.801-15.801 c8.713,0,15.801,7.088,15.801,15.801v99.839c0,23.933,19.468,43.403,43.398,43.403h236.961c23.93,0,43.398-19.471,43.398-43.403 v-98.238c0-8.712,7.088-15.801,15.801-15.801s15.801,7.088,15.801,15.801v98.238 C388.996,327.385,355.352,361.029,313.996,361.029z M17.836,180.389c-3.253,0-5.801,2.548-5.801,5.801v99.839 c0,35.841,29.159,65,65,65h236.961c35.841,0,65-29.159,65-65v-98.238c0-3.198-2.603-5.801-5.801-5.801 c-3.253,0-5.801,2.548-5.801,5.801v98.238c0,29.446-23.954,53.403-53.398,53.403H77.035c-29.444,0-53.398-23.957-53.398-53.403 V186.19C23.637,182.937,21.089,180.389,17.836,180.389z",
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("g", [
                    _c("path", {
                      attrs: {
                        d: "M134.476,93.791l50.239-50.242V262.67c0,6,4.8,10.801,10.8,10.801s10.801-4.801,10.801-10.801V43.549l50.239,50.242 c2.082,2.078,4.878,3.199,7.601,3.199c2.801,0,5.52-1.039,7.598-3.199c4.242-4.242,4.242-11.043,0-15.281L203.113,9.869 c-2-2-4.797-3.199-7.598-3.199c-2.878,0-5.601,1.121-7.601,3.199L119.273,78.51c-4.238,4.238-4.238,11.039,0,15.281 C123.433,97.951,130.316,97.951,134.476,93.791L134.476,93.791z M134.476,93.791",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M195.515,278.472c-8.712,0-15.8-7.089-15.8-15.802V55.621l-41.704,41.706c-6.141,6.141-16.133,6.141-22.273,0 c-6.16-6.167-6.16-16.193,0-22.352l68.641-68.642c2.87-2.983,6.849-4.663,11.137-4.663c4.112,0,8.17,1.7,11.133,4.663 l68.641,68.641c2.996,2.993,4.646,6.962,4.646,11.175c0,4.212-1.65,8.182-4.646,11.178c-2.868,2.982-6.847,4.663-11.133,4.663 c-4.108,0-8.166-1.698-11.134-4.66l-41.706-41.709V262.67C211.317,271.383,204.228,278.472,195.515,278.472z M189.716,31.477 V262.67c0,3.253,2.547,5.802,5.8,5.802c3.253,0,5.801-2.549,5.801-5.802V31.477l58.774,58.779 c1.086,1.084,2.607,1.734,4.065,1.734c1.091,0,2.67-0.289,3.995-1.666c1.175-1.176,1.784-2.634,1.784-4.175 c0-1.54-0.609-2.997-1.716-4.102l-68.642-68.643c-1.086-1.086-2.605-1.734-4.063-1.734c-1.544,0-2.964,0.592-3.999,1.667 l-68.708,68.709c-2.301,2.301-2.3,5.908,0.002,8.212c2.202,2.202,5.926,2.201,8.13-0.002L189.716,31.477z",
                      },
                    }),
                  ]),
                ]),
              ]
            ),
            _c("span", { staticClass: "hackfontsize" }, [
              _vm._v("Choose your conso files (.csv)"),
            ]),
          ]),
        ]),
        _c("li", [
          _c("a", { staticClass: "btn_primary" }, [
            _c("span", { staticClass: "icon" }, [
              _c(
                "svg",
                {
                  attrs: {
                    version: "1.1",
                    xmlns: "http://www.w3.org/2000/svg",
                    "xmlns:xlink": "http://www.w3.org/1999/xlink",
                    x: "0px",
                    y: "0px",
                    viewBox: "0 0 392.103 362.787",
                    "xml:space": "preserve",
                  },
                },
                [
                  _c("g", [
                    _c("g", [
                      _c("g", [
                        _c("path", {
                          attrs: {
                            d: "M373.195,176.99c-6,0-10.801,4.801-10.801,10.801v98.238c0,26.719-21.757,48.403-48.398,48.403H77.035 c-26.719,0-48.398-21.762-48.398-48.403V186.19c0-6-4.801-10.801-10.801-10.801S7.035,180.19,7.035,186.19v99.839 c0,38.641,31.441,70,70,70h236.961c38.641,0,70-31.437,70-70v-98.238C383.996,181.869,379.195,176.99,373.195,176.99 L373.195,176.99z M373.195,176.99",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M313.996,361.029H77.035c-41.355,0-75-33.645-75-75V186.19c0-8.713,7.088-15.801,15.801-15.801 c8.713,0,15.801,7.088,15.801,15.801v99.839c0,23.933,19.468,43.403,43.398,43.403h236.961c23.93,0,43.398-19.471,43.398-43.403 v-98.238c0-8.712,7.088-15.801,15.801-15.801s15.801,7.088,15.801,15.801v98.238 C388.996,327.385,355.352,361.029,313.996,361.029z M17.836,180.389c-3.253,0-5.801,2.548-5.801,5.801v99.839 c0,35.841,29.159,65,65,65h236.961c35.841,0,65-29.159,65-65v-98.238c0-3.198-2.603-5.801-5.801-5.801 c-3.253,0-5.801,2.548-5.801,5.801v98.238c0,29.446-23.954,53.403-53.398,53.403H77.035c-29.444,0-53.398-23.957-53.398-53.403 V186.19C23.637,182.937,21.089,180.389,17.836,180.389z",
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("g", [
                      _c("path", {
                        attrs: {
                          d: "M134.476,93.791l50.239-50.242V262.67c0,6,4.8,10.801,10.8,10.801s10.801-4.801,10.801-10.801V43.549l50.239,50.242 c2.082,2.078,4.878,3.199,7.601,3.199c2.801,0,5.52-1.039,7.598-3.199c4.242-4.242,4.242-11.043,0-15.281L203.113,9.869 c-2-2-4.797-3.199-7.598-3.199c-2.878,0-5.601,1.121-7.601,3.199L119.273,78.51c-4.238,4.238-4.238,11.039,0,15.281 C123.433,97.951,130.316,97.951,134.476,93.791L134.476,93.791z M134.476,93.791",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M195.515,278.472c-8.712,0-15.8-7.089-15.8-15.802V55.621l-41.704,41.706c-6.141,6.141-16.133,6.141-22.273,0 c-6.16-6.167-6.16-16.193,0-22.352l68.641-68.642c2.87-2.983,6.849-4.663,11.137-4.663c4.112,0,8.17,1.7,11.133,4.663 l68.641,68.641c2.996,2.993,4.646,6.962,4.646,11.175c0,4.212-1.65,8.182-4.646,11.178c-2.868,2.982-6.847,4.663-11.133,4.663 c-4.108,0-8.166-1.698-11.134-4.66l-41.706-41.709V262.67C211.317,271.383,204.228,278.472,195.515,278.472z M189.716,31.477 V262.67c0,3.253,2.547,5.802,5.8,5.802c3.253,0,5.801-2.549,5.801-5.802V31.477l58.774,58.779 c1.086,1.084,2.607,1.734,4.065,1.734c1.091,0,2.67-0.289,3.995-1.666c1.175-1.176,1.784-2.634,1.784-4.175 c0-1.54-0.609-2.997-1.716-4.102l-68.642-68.643c-1.086-1.086-2.605-1.734-4.063-1.734c-1.544,0-2.964,0.592-3.999,1.667 l-68.708,68.709c-2.301,2.301-2.3,5.908,0.002,8.212c2.202,2.202,5.926,2.201,8.13-0.002L189.716,31.477z",
                        },
                      }),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c("span", { staticClass: "libelle" }, [_vm._v("Upload")]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }