// tslint:disable
/**
 * SkyT
 * SkyT contract
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: email@email.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiError } from '../model';
// @ts-ignore
import { CreateOrUpdateMissionColor } from '../model';
// @ts-ignore
import { MissionColor } from '../model';
/**
 * ReferentialMissionColorApi - axios parameter creator
 * @export
 */
export const ReferentialMissionColorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new mission color
         * @summary Create a new mission color
         * @param {CreateOrUpdateMissionColor} [createOrUpdateMissionColor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMissionColor: async (createOrUpdateMissionColor?: CreateOrUpdateMissionColor, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/referential/mission-colors`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createOrUpdateMissionColor !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createOrUpdateMissionColor !== undefined ? createOrUpdateMissionColor : {}) : (createOrUpdateMissionColor || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a mission color and returns the mission colors with their new indexes
         * @summary Delete a mission color
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMissionColorById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteMissionColorById.');
            }
            const localVarPath = `/api/v1/referential/mission-colors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the mission colors sorted by index
         * @summary Get mission colors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMissionColors: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/referential/mission-colors`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a mission color
         * @summary Update a mission color
         * @param {number} id 
         * @param {CreateOrUpdateMissionColor} [createOrUpdateMissionColor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMissionColorById: async (id: number, createOrUpdateMissionColor?: CreateOrUpdateMissionColor, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateMissionColorById.');
            }
            const localVarPath = `/api/v1/referential/mission-colors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createOrUpdateMissionColor !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createOrUpdateMissionColor !== undefined ? createOrUpdateMissionColor : {}) : (createOrUpdateMissionColor || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates index of a mission color and returns the mission colors with their new indexes
         * @summary Update index of a mission color
         * @param {number} id 
         * @param {number} index 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMissionColorIndex: async (id: number, index: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateMissionColorIndex.');
            }
            // verify required parameter 'index' is not null or undefined
            if (index === null || index === undefined) {
                throw new RequiredError('index','Required parameter index was null or undefined when calling updateMissionColorIndex.');
            }
            const localVarPath = `/api/v1/referential/mission-colors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (index !== undefined) {
                localVarQueryParameter['index'] = index;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferentialMissionColorApi - functional programming interface
 * @export
 */
export const ReferentialMissionColorApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Create a new mission color
         * @summary Create a new mission color
         * @param {CreateOrUpdateMissionColor} [createOrUpdateMissionColor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMissionColor(createOrUpdateMissionColor?: CreateOrUpdateMissionColor, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionColor>> {
            const localVarAxiosArgs = await ReferentialMissionColorApiAxiosParamCreator(configuration).createMissionColor(createOrUpdateMissionColor, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Deletes a mission color and returns the mission colors with their new indexes
         * @summary Delete a mission color
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMissionColorById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MissionColor>>> {
            const localVarAxiosArgs = await ReferentialMissionColorApiAxiosParamCreator(configuration).deleteMissionColorById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the mission colors sorted by index
         * @summary Get mission colors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMissionColors(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MissionColor>>> {
            const localVarAxiosArgs = await ReferentialMissionColorApiAxiosParamCreator(configuration).getAllMissionColors(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update a mission color
         * @summary Update a mission color
         * @param {number} id 
         * @param {CreateOrUpdateMissionColor} [createOrUpdateMissionColor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMissionColorById(id: number, createOrUpdateMissionColor?: CreateOrUpdateMissionColor, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionColor>> {
            const localVarAxiosArgs = await ReferentialMissionColorApiAxiosParamCreator(configuration).updateMissionColorById(id, createOrUpdateMissionColor, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Updates index of a mission color and returns the mission colors with their new indexes
         * @summary Update index of a mission color
         * @param {number} id 
         * @param {number} index 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMissionColorIndex(id: number, index: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MissionColor>>> {
            const localVarAxiosArgs = await ReferentialMissionColorApiAxiosParamCreator(configuration).updateMissionColorIndex(id, index, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ReferentialMissionColorApi - factory interface
 * @export
 */
export const ReferentialMissionColorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Create a new mission color
         * @summary Create a new mission color
         * @param {CreateOrUpdateMissionColor} [createOrUpdateMissionColor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMissionColor(createOrUpdateMissionColor?: CreateOrUpdateMissionColor, options?: any): AxiosPromise<MissionColor> {
            return ReferentialMissionColorApiFp(configuration).createMissionColor(createOrUpdateMissionColor, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a mission color and returns the mission colors with their new indexes
         * @summary Delete a mission color
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMissionColorById(id: number, options?: any): AxiosPromise<Array<MissionColor>> {
            return ReferentialMissionColorApiFp(configuration).deleteMissionColorById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the mission colors sorted by index
         * @summary Get mission colors
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMissionColors(options?: any): AxiosPromise<Array<MissionColor>> {
            return ReferentialMissionColorApiFp(configuration).getAllMissionColors(options).then((request) => request(axios, basePath));
        },
        /**
         * Update a mission color
         * @summary Update a mission color
         * @param {number} id 
         * @param {CreateOrUpdateMissionColor} [createOrUpdateMissionColor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMissionColorById(id: number, createOrUpdateMissionColor?: CreateOrUpdateMissionColor, options?: any): AxiosPromise<MissionColor> {
            return ReferentialMissionColorApiFp(configuration).updateMissionColorById(id, createOrUpdateMissionColor, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates index of a mission color and returns the mission colors with their new indexes
         * @summary Update index of a mission color
         * @param {number} id 
         * @param {number} index 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMissionColorIndex(id: number, index: number, options?: any): AxiosPromise<Array<MissionColor>> {
            return ReferentialMissionColorApiFp(configuration).updateMissionColorIndex(id, index, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReferentialMissionColorApi - object-oriented interface
 * @export
 * @class ReferentialMissionColorApi
 * @extends {BaseAPI}
 */
export class ReferentialMissionColorApi extends BaseAPI {
    /**
     * Create a new mission color
     * @summary Create a new mission color
     * @param {CreateOrUpdateMissionColor} [createOrUpdateMissionColor] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferentialMissionColorApi
     */
    public createMissionColor(createOrUpdateMissionColor?: CreateOrUpdateMissionColor, options?: any) {
        return ReferentialMissionColorApiFp(this.configuration).createMissionColor(createOrUpdateMissionColor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a mission color and returns the mission colors with their new indexes
     * @summary Delete a mission color
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferentialMissionColorApi
     */
    public deleteMissionColorById(id: number, options?: any) {
        return ReferentialMissionColorApiFp(this.configuration).deleteMissionColorById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the mission colors sorted by index
     * @summary Get mission colors
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferentialMissionColorApi
     */
    public getAllMissionColors(options?: any) {
        return ReferentialMissionColorApiFp(this.configuration).getAllMissionColors(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a mission color
     * @summary Update a mission color
     * @param {number} id 
     * @param {CreateOrUpdateMissionColor} [createOrUpdateMissionColor] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferentialMissionColorApi
     */
    public updateMissionColorById(id: number, createOrUpdateMissionColor?: CreateOrUpdateMissionColor, options?: any) {
        return ReferentialMissionColorApiFp(this.configuration).updateMissionColorById(id, createOrUpdateMissionColor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates index of a mission color and returns the mission colors with their new indexes
     * @summary Update index of a mission color
     * @param {number} id 
     * @param {number} index 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferentialMissionColorApi
     */
    public updateMissionColorIndex(id: number, index: number, options?: any) {
        return ReferentialMissionColorApiFp(this.configuration).updateMissionColorIndex(id, index, options).then((request) => request(this.axios, this.basePath));
    }

}
