var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container menu_component" }, [
    _c("div", { staticClass: "nav" }, [
      _c(
        "ul",
        _vm._l(_vm.currentMenu, function (menuItem) {
          return _c(
            "li",
            { staticClass: "menu_li", class: menuItem.class },
            [
              _c("router-link", { attrs: { to: menuItem.route } }, [
                _c("span", { staticClass: "icon_menu" }),
                _c("span", { staticClass: "txt" }, [
                  _vm._v(_vm._s(menuItem.name)),
                ]),
              ]),
            ],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }