// tslint:disable
/**
 * SkyT
 * SkyT contract
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: email@email.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiError } from '../model';
// @ts-ignore
import { BeamCustomisation } from '../model';
// @ts-ignore
import { Customer } from '../model';
// @ts-ignore
import { HtmlContent } from '../model';
/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the about page of the app
         * @summary Get the about page of the app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAboutPage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/about`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the current beam customisation
         * @summary get the current beam customisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBeamCustomisation: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/beamCustomisations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the conditions of uses page of the app
         * @summary Get the conditions of uses page of the app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionsOfUsesPage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/conditionsOfUses`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the cookies and GDPR page of the app
         * @summary Get the cookies and GDPR page of the app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCookiesPage: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/cookiesAndGDPR`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the current customer lock
         * @summary Get the current customer lock
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentCustomerLock: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/lock`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the legal page of the app
         * @summary Get the legal page of the app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalNotice: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/legal`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lock the application to a specific customer
         * @summary Lock the application to a specific customer
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockToCustomer: async (customerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new RequiredError('customerId','Required parameter customerId was null or undefined when calling lockToCustomer.');
            }
            const localVarPath = `/api/v1/settings/lock/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unlock the application to any customer
         * @summary Unlock the application to any customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockCustomer: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/unlock`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unlock the application to any customer
         * @summary Unlock the application to any customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockCustomerDelete: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/unlock`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the page of the app
         * @summary Update the about page of the app
         * @param {HtmlContent} [htmlContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAboutPage: async (htmlContent?: HtmlContent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/about`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof htmlContent !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(htmlContent !== undefined ? htmlContent : {}) : (htmlContent || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable or disable the beam customisation
         * @summary enable or disable the beam customisation
         * @param {BeamCustomisation} [beamCustomisation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBeamCustomisation: async (beamCustomisation?: BeamCustomisation, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/beamCustomisations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof beamCustomisation !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(beamCustomisation !== undefined ? beamCustomisation : {}) : (beamCustomisation || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the conditions of uses page of the app
         * @summary Update the conditions of uses page of the app
         * @param {HtmlContent} [htmlContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConditionsOfUsesPage: async (htmlContent?: HtmlContent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/conditionsOfUses`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof htmlContent !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(htmlContent !== undefined ? htmlContent : {}) : (htmlContent || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the cookies and GDPR page of the app
         * @summary Update the cookies and GDPR page of the app
         * @param {HtmlContent} [htmlContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCookiesPage: async (htmlContent?: HtmlContent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/cookiesAndGDPR`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof htmlContent !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(htmlContent !== undefined ? htmlContent : {}) : (htmlContent || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the legal page of the app
         * @summary Update the legal page of the app
         * @param {HtmlContent} [htmlContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLegalNotice: async (htmlContent?: HtmlContent, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings/legal`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof htmlContent !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(htmlContent !== undefined ? htmlContent : {}) : (htmlContent || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get the about page of the app
         * @summary Get the about page of the app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAboutPage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HtmlContent>> {
            const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).getAboutPage(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get the current beam customisation
         * @summary get the current beam customisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBeamCustomisation(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeamCustomisation>> {
            const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).getBeamCustomisation(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the conditions of uses page of the app
         * @summary Get the conditions of uses page of the app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConditionsOfUsesPage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HtmlContent>> {
            const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).getConditionsOfUsesPage(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the cookies and GDPR page of the app
         * @summary Get the cookies and GDPR page of the app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCookiesPage(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HtmlContent>> {
            const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).getCookiesPage(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the current customer lock
         * @summary Get the current customer lock
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentCustomerLock(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).getCurrentCustomerLock(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get the legal page of the app
         * @summary Get the legal page of the app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLegalNotice(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HtmlContent>> {
            const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).getLegalNotice(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Lock the application to a specific customer
         * @summary Lock the application to a specific customer
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lockToCustomer(customerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).lockToCustomer(customerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Unlock the application to any customer
         * @summary Unlock the application to any customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlockCustomer(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).unlockCustomer(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Unlock the application to any customer
         * @summary Unlock the application to any customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlockCustomerDelete(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).unlockCustomerDelete(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update the page of the app
         * @summary Update the about page of the app
         * @param {HtmlContent} [htmlContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAboutPage(htmlContent?: HtmlContent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HtmlContent>> {
            const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).updateAboutPage(htmlContent, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Enable or disable the beam customisation
         * @summary enable or disable the beam customisation
         * @param {BeamCustomisation} [beamCustomisation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBeamCustomisation(beamCustomisation?: BeamCustomisation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeamCustomisation>> {
            const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).updateBeamCustomisation(beamCustomisation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update the conditions of uses page of the app
         * @summary Update the conditions of uses page of the app
         * @param {HtmlContent} [htmlContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConditionsOfUsesPage(htmlContent?: HtmlContent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HtmlContent>> {
            const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).updateConditionsOfUsesPage(htmlContent, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update the cookies and GDPR page of the app
         * @summary Update the cookies and GDPR page of the app
         * @param {HtmlContent} [htmlContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCookiesPage(htmlContent?: HtmlContent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HtmlContent>> {
            const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).updateCookiesPage(htmlContent, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update the legal page of the app
         * @summary Update the legal page of the app
         * @param {HtmlContent} [htmlContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLegalNotice(htmlContent?: HtmlContent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HtmlContent>> {
            const localVarAxiosArgs = await SettingsApiAxiosParamCreator(configuration).updateLegalNotice(htmlContent, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get the about page of the app
         * @summary Get the about page of the app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAboutPage(options?: any): AxiosPromise<HtmlContent> {
            return SettingsApiFp(configuration).getAboutPage(options).then((request) => request(axios, basePath));
        },
        /**
         * get the current beam customisation
         * @summary get the current beam customisation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBeamCustomisation(options?: any): AxiosPromise<BeamCustomisation> {
            return SettingsApiFp(configuration).getBeamCustomisation(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the conditions of uses page of the app
         * @summary Get the conditions of uses page of the app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionsOfUsesPage(options?: any): AxiosPromise<HtmlContent> {
            return SettingsApiFp(configuration).getConditionsOfUsesPage(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the cookies and GDPR page of the app
         * @summary Get the cookies and GDPR page of the app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCookiesPage(options?: any): AxiosPromise<HtmlContent> {
            return SettingsApiFp(configuration).getCookiesPage(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the current customer lock
         * @summary Get the current customer lock
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentCustomerLock(options?: any): AxiosPromise<Customer> {
            return SettingsApiFp(configuration).getCurrentCustomerLock(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the legal page of the app
         * @summary Get the legal page of the app
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalNotice(options?: any): AxiosPromise<HtmlContent> {
            return SettingsApiFp(configuration).getLegalNotice(options).then((request) => request(axios, basePath));
        },
        /**
         * Lock the application to a specific customer
         * @summary Lock the application to a specific customer
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockToCustomer(customerId: number, options?: any): AxiosPromise<Customer> {
            return SettingsApiFp(configuration).lockToCustomer(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Unlock the application to any customer
         * @summary Unlock the application to any customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockCustomer(options?: any): AxiosPromise<void> {
            return SettingsApiFp(configuration).unlockCustomer(options).then((request) => request(axios, basePath));
        },
        /**
         * Unlock the application to any customer
         * @summary Unlock the application to any customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockCustomerDelete(options?: any): AxiosPromise<void> {
            return SettingsApiFp(configuration).unlockCustomerDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * Update the page of the app
         * @summary Update the about page of the app
         * @param {HtmlContent} [htmlContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAboutPage(htmlContent?: HtmlContent, options?: any): AxiosPromise<HtmlContent> {
            return SettingsApiFp(configuration).updateAboutPage(htmlContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable or disable the beam customisation
         * @summary enable or disable the beam customisation
         * @param {BeamCustomisation} [beamCustomisation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBeamCustomisation(beamCustomisation?: BeamCustomisation, options?: any): AxiosPromise<BeamCustomisation> {
            return SettingsApiFp(configuration).updateBeamCustomisation(beamCustomisation, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the conditions of uses page of the app
         * @summary Update the conditions of uses page of the app
         * @param {HtmlContent} [htmlContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConditionsOfUsesPage(htmlContent?: HtmlContent, options?: any): AxiosPromise<HtmlContent> {
            return SettingsApiFp(configuration).updateConditionsOfUsesPage(htmlContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the cookies and GDPR page of the app
         * @summary Update the cookies and GDPR page of the app
         * @param {HtmlContent} [htmlContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCookiesPage(htmlContent?: HtmlContent, options?: any): AxiosPromise<HtmlContent> {
            return SettingsApiFp(configuration).updateCookiesPage(htmlContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the legal page of the app
         * @summary Update the legal page of the app
         * @param {HtmlContent} [htmlContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLegalNotice(htmlContent?: HtmlContent, options?: any): AxiosPromise<HtmlContent> {
            return SettingsApiFp(configuration).updateLegalNotice(htmlContent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * Get the about page of the app
     * @summary Get the about page of the app
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getAboutPage(options?: any) {
        return SettingsApiFp(this.configuration).getAboutPage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get the current beam customisation
     * @summary get the current beam customisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getBeamCustomisation(options?: any) {
        return SettingsApiFp(this.configuration).getBeamCustomisation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the conditions of uses page of the app
     * @summary Get the conditions of uses page of the app
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getConditionsOfUsesPage(options?: any) {
        return SettingsApiFp(this.configuration).getConditionsOfUsesPage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the cookies and GDPR page of the app
     * @summary Get the cookies and GDPR page of the app
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getCookiesPage(options?: any) {
        return SettingsApiFp(this.configuration).getCookiesPage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the current customer lock
     * @summary Get the current customer lock
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getCurrentCustomerLock(options?: any) {
        return SettingsApiFp(this.configuration).getCurrentCustomerLock(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the legal page of the app
     * @summary Get the legal page of the app
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getLegalNotice(options?: any) {
        return SettingsApiFp(this.configuration).getLegalNotice(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lock the application to a specific customer
     * @summary Lock the application to a specific customer
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public lockToCustomer(customerId: number, options?: any) {
        return SettingsApiFp(this.configuration).lockToCustomer(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unlock the application to any customer
     * @summary Unlock the application to any customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public unlockCustomer(options?: any) {
        return SettingsApiFp(this.configuration).unlockCustomer(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unlock the application to any customer
     * @summary Unlock the application to any customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public unlockCustomerDelete(options?: any) {
        return SettingsApiFp(this.configuration).unlockCustomerDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the page of the app
     * @summary Update the about page of the app
     * @param {HtmlContent} [htmlContent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public updateAboutPage(htmlContent?: HtmlContent, options?: any) {
        return SettingsApiFp(this.configuration).updateAboutPage(htmlContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable or disable the beam customisation
     * @summary enable or disable the beam customisation
     * @param {BeamCustomisation} [beamCustomisation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public updateBeamCustomisation(beamCustomisation?: BeamCustomisation, options?: any) {
        return SettingsApiFp(this.configuration).updateBeamCustomisation(beamCustomisation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the conditions of uses page of the app
     * @summary Update the conditions of uses page of the app
     * @param {HtmlContent} [htmlContent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public updateConditionsOfUsesPage(htmlContent?: HtmlContent, options?: any) {
        return SettingsApiFp(this.configuration).updateConditionsOfUsesPage(htmlContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the cookies and GDPR page of the app
     * @summary Update the cookies and GDPR page of the app
     * @param {HtmlContent} [htmlContent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public updateCookiesPage(htmlContent?: HtmlContent, options?: any) {
        return SettingsApiFp(this.configuration).updateCookiesPage(htmlContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the legal page of the app
     * @summary Update the legal page of the app
     * @param {HtmlContent} [htmlContent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public updateLegalNotice(htmlContent?: HtmlContent, options?: any) {
        return SettingsApiFp(this.configuration).updateLegalNotice(htmlContent, options).then((request) => request(this.axios, this.basePath));
    }

}
