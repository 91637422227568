import Vue from "vue";
import { comparator } from '@/utils/comparator';

export default Vue.extend({
    props: ['project'],
    computed: {
        sortedTeamMembers() {
            return this.project.teamMembers != null
                ? this.project.teamMembers.slice().sort((a, b) => comparator.compareObjectsOnStrings(a, b, 'lastName'))
                : null
        }
    },
    methods: {
        formatPhone(phone) {
            // TODO: consider rewriting this method to handle all phone formats
            // This works for +33612345678 but will not work for 0612345678.
            return phone.substring(0, 3) + ' ' + phone.substring(3, 4) + ' ' + phone.substring(4, 6)
                + ' ' + phone.substring(6, 8) + ' ' + phone.substring(8, 10) + ' ' + phone.substring(10, 12)
        }
    }
})