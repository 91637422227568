import Vue from 'vue'
import { PROJECT_EXPORTS_FETCH } from '../aliases/actions.type'
import { SET_PROJECT_EXPORTS } from '../aliases/mutations.type'
import { GET_PROJECT_EXPORTS } from '../aliases/getters.type'
import {ProjectApi, ProjectExportApi} from '@/core'
import { getConfiguration } from '@/core/utils/config.util'
import { displayMessage, ERROR_TYPES } from '@/core/middlewares/error'
import { log, LOG_TYPES } from '@/core/middlewares/Logger'
import { Export } from '@/core/models'

const namespaced = true

export default {
    namespaced,

    state: {
        projectExports: []
    },

    getters: {
        [GET_PROJECT_EXPORTS]: (state) => {
            return state.projectExports
        },
    },

    actions: {
        [PROJECT_EXPORTS_FETCH]({ commit }, { projectId }: { projectId: number }) {
            log({ VueInstance: Vue, type: LOG_TYPES.d, message: `Fetching all project exports.` })
            return getConfiguration().then(config => {
                return new ProjectExportApi(config).getAllProjectExport(projectId)
            }).then(({data}) => {
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Project exports: ${data.length} returned.`
                })
                log({
                    VueInstance: Vue,
                    type: LOG_TYPES.d,
                    message: `Project exports: ${JSON.stringify(data)} returned successfully.`
                })
                commit(SET_PROJECT_EXPORTS, { projectExports: data })
                return data
            }).catch(({ response }) => {
                displayMessage({ message: 'Error while fetching project exports.', type: ERROR_TYPES.ERROR })
                log({ VueInstance: Vue, type: LOG_TYPES.e, response })
            })
        }
    },

    mutations: {
        [SET_PROJECT_EXPORTS](state, { projectExports }: { projectExports: Array<Export> }) {
            state.projectExports = projectExports
        }
    }
}
