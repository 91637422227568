// tslint:disable
/**
 * SkyT
 * SkyT contract
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: email@email.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { Offer } from '../models';
import { OfferExtended } from '../models';
/**
 * OfferApi - axios parameter creator
 * @export
 */
export const OfferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create an offer
         * @param {OfferExtended} offerExtended Offer object that needs to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffer(offerExtended: OfferExtended, options: any = {}): RequestArgs {
            // verify required parameter 'offerExtended' is not null or undefined
            if (offerExtended === null || offerExtended === undefined) {
                throw new RequiredError('offerExtended','Required parameter offerExtended was null or undefined when calling createOffer.');
            }
            const localVarPath = `/offer`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof offerExtended !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(offerExtended !== undefined ? offerExtended : {}) : (offerExtended || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Detele an offer
         * @param {number} id Offer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOffer(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOffer.');
            }
            const localVarPath = `/offer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all offers
         * @summary Find all offers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAllOffers(options: any = {}): RequestArgs {
            const localVarPath = `/offers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an offer
         * @param {number} id Offer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOffer(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling fetchOffer.');
            }
            const localVarPath = `/offer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an offer
         * @param {number} id Offer ID
         * @param {OfferExtended} offerExtended Offer object that needs to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOffer(id: number, offerExtended: OfferExtended, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOffer.');
            }
            // verify required parameter 'offerExtended' is not null or undefined
            if (offerExtended === null || offerExtended === undefined) {
                throw new RequiredError('offerExtended','Required parameter offerExtended was null or undefined when calling updateOffer.');
            }
            const localVarPath = `/offer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof offerExtended !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(offerExtended !== undefined ? offerExtended : {}) : (offerExtended || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferApi - functional programming interface
 * @export
 */
export const OfferApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create an offer
         * @param {OfferExtended} offerExtended Offer object that needs to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffer(offerExtended: OfferExtended, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = OfferApiAxiosParamCreator(configuration).createOffer(offerExtended, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Detele an offer
         * @param {number} id Offer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOffer(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = OfferApiAxiosParamCreator(configuration).deleteOffer(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get all offers
         * @summary Find all offers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAllOffers(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Offer>> {
            const localVarAxiosArgs = OfferApiAxiosParamCreator(configuration).fetchAllOffers(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get an offer
         * @param {number} id Offer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOffer(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferExtended> {
            const localVarAxiosArgs = OfferApiAxiosParamCreator(configuration).fetchOffer(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update an offer
         * @param {number} id Offer ID
         * @param {OfferExtended} offerExtended Offer object that needs to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOffer(id: number, offerExtended: OfferExtended, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = OfferApiAxiosParamCreator(configuration).updateOffer(id, offerExtended, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OfferApi - factory interface
 * @export
 */
export const OfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create an offer
         * @param {OfferExtended} offerExtended Offer object that needs to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffer(offerExtended: OfferExtended, options?: any): AxiosPromise<void> {
            return OfferApiFp(configuration).createOffer(offerExtended, options)(axios, basePath);
        },
        /**
         * 
         * @summary Detele an offer
         * @param {number} id Offer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOffer(id: number, options?: any): AxiosPromise<void> {
            return OfferApiFp(configuration).deleteOffer(id, options)(axios, basePath);
        },
        /**
         * Get all offers
         * @summary Find all offers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAllOffers(options?: any): AxiosPromise<Array<Offer>> {
            return OfferApiFp(configuration).fetchAllOffers(options)(axios, basePath);
        },
        /**
         * 
         * @summary Get an offer
         * @param {number} id Offer ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchOffer(id: number, options?: any): AxiosPromise<OfferExtended> {
            return OfferApiFp(configuration).fetchOffer(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update an offer
         * @param {number} id Offer ID
         * @param {OfferExtended} offerExtended Offer object that needs to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOffer(id: number, offerExtended: OfferExtended, options?: any): AxiosPromise<void> {
            return OfferApiFp(configuration).updateOffer(id, offerExtended, options)(axios, basePath);
        },
    };
};

/**
 * OfferApi - object-oriented interface
 * @export
 * @class OfferApi
 * @extends {BaseAPI}
 */
export class OfferApi extends BaseAPI {
    /**
     * 
     * @summary Create an offer
     * @param {OfferExtended} offerExtended Offer object that needs to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public createOffer(offerExtended: OfferExtended, options?: any) {
        return OfferApiFp(this.configuration).createOffer(offerExtended, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Detele an offer
     * @param {number} id Offer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public deleteOffer(id: number, options?: any) {
        return OfferApiFp(this.configuration).deleteOffer(id, options)(this.axios, this.basePath);
    }

    /**
     * Get all offers
     * @summary Find all offers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public fetchAllOffers(options?: any) {
        return OfferApiFp(this.configuration).fetchAllOffers(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get an offer
     * @param {number} id Offer ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public fetchOffer(id: number, options?: any) {
        return OfferApiFp(this.configuration).fetchOffer(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update an offer
     * @param {number} id Offer ID
     * @param {OfferExtended} offerExtended Offer object that needs to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public updateOffer(id: number, offerExtended: OfferExtended, options?: any) {
        return OfferApiFp(this.configuration).updateOffer(id, offerExtended, options)(this.axios, this.basePath);
    }

}
