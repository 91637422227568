import Vue from 'vue'
import Vuex from 'vuex'

import util from './modules/util'
import catalog from './modules/catalog'
import offer from './modules/offer'
import persona from './modules/persona'
import customer from './modules/customer'
import project from './modules/project'
import projectExport from './modules/project-export'
import referential from './modules/referential'
import settings from './modules/settings'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    catalog,
    offer,
    persona,
    customer, 
    project,
    projectExport,
    referential,
    util,
    settings
  }
})
