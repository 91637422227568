var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "bloc_template" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { class: _vm.selectedSettingIndex >= 0 ? "col-6" : "col-12" },
          [
            _c("div", { staticClass: "bloc_padding" }, [
              _c("h2", { staticClass: "h2settings" }, [_vm._v("Settings")]),
              _c("div", { staticClass: "bloc_content" }, [
                _c(
                  "ul",
                  [
                    _c(
                      "transition-group",
                      _vm._l(_vm.settings, function (setting, s_index) {
                        return _c("SelectableLine", {
                          key: setting.name,
                          class: {
                            active: _vm.selectedSettingIndex === s_index,
                          },
                          attrs: {
                            item: setting,
                            isDraggable: false,
                            isDeletable: false,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.selectSetting(s_index)
                            },
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
        _vm.selectedSettingIndex >= 0
          ? _c(
              "div",
              {
                staticClass: "col-6",
                style: _vm.selectedSettingIndex >= 0 ? "" : "display:none",
              },
              [
                _c("div", { staticClass: "bloc_right" }, [
                  _c(
                    "div",
                    { staticClass: "bloc_padding" },
                    [
                      _vm.selectedSettingIndex === 0
                        ? _c("ManageConfigurationFiles")
                        : _vm._e(),
                      _vm.selectedSettingIndex === 1
                        ? _c("ManageAreaOfInterestColor")
                        : _vm._e(),
                      _vm.selectedSettingIndex === 2
                        ? _c("AppSettings")
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }