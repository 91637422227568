const config = require('../config/config.json')
const skytRoles = require('../config/roles.json')

const env = process.env.NODE_ENV
const EnvConfiguration = config[env]

export {
  EnvConfiguration,
  skytRoles
}
