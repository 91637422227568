var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-1", style: _vm.isDraggable ? "" : "display:none" },
        [_c("span", { staticClass: "icon_drag" })]
      ),
      _c(
        "div",
        {
          staticClass: "align_left",
          class: _vm.isDraggable ? "col-5" : "col-6",
        },
        [
          _c("div", { staticClass: "bloc_content_li" }, [
            _c("span", { staticClass: "titre_li" }, [
              _vm._v(_vm._s(_vm.item.name)),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "col-6 align_right" }, [
        _c("ul", { staticClass: "ul_cta flex" }, [
          _c("li", [
            _c("a", { staticClass: "cta_02" }, [
              _c("span", {
                staticClass: "icon_bin",
                style: _vm.isDeletable ? "" : "display:none",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleDeletion.apply(null, arguments)
                  },
                },
              }),
            ]),
          ]),
          _vm._m(0),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { staticClass: "cta_02" }, [
        _c("span", { staticClass: "icon_arrow" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }