const commons = {
    install(Vue, options) {
        Vue.mixin({
            methods: {
                showDeletePopup() {
                    this.$bvModal.show("delete-component");
                },
                hideDeletePopoup() {
                    this.$bvModal.hide("delete-component");
                }
            }
        })
    }
}

export default commons