import Vue from "vue"

export default Vue.extend({
  props: [],
  data: function() {
    return {
      userInfo: {
        name: ""
      }
    }
  },
  methods: {
    logOut() {
      this.$amarisVueMsalPlugin.logout()
    }
  },
  async mounted() {
    this.userInfo = await this.$amarisVueMsalPlugin.getUserInfo();
  }
});
